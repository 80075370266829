import favicon from '../assets/images/LIHAM/logo.png';
import logo192 from '../assets/images/LIHAM/logo.png';
import logoCBHBTexto from '../assets/images/LIHAM/logo.png';
import logo from '../assets/images/LIHAM/logo.png';
import footerBG from '../assets/images/CBHb/footer_bg.png';
import logoBigmidia from '../assets/images/bigmidia_azul.png'

export const metaLIHAM = {
    descricao_estabelecimento: 'Liga de Handebol do Amazonas',
    siteTitle: 'LIHAM | Liga de Handebol do Amazonas',
    nomeFooter: 'LIHAM',
    siteDescription: 'Site Oficial da Liga de Handebol do Amazonas',
    siteImg: logo192,
    logo: logo,
    logoMobile: logo,
    logoTexto: logoCBHBTexto,
    footerBG: footerBG,
    logoBigmidia: logoBigmidia,
    favicon: favicon,
    emailContato: 'contato@liham.com.br',
    redesSociais: [
        {
            name: 'facebook',
            url: 'https://www.facebook.com/ligadehandeboldoamazonas/',
            btnClass: 'btn-social-counter--fb',
            texto:'Curta nossa página',
        },
        {
            name: 'twitter',
            url: 'https://twitter.com/cbhb1',
            btnClass: 'btn-social-counter--twitter',
            texto:'Siga-nos no Twitter',
        },
        {
            name: 'instagram',
            url: 'https://instagram.com/liham.amazonas?igshid=YmMyMTA2M2Y=',
            btnClass: 'btn-social-counter--instagram',
            texto:'Siga-nos no Instagram',
        },
        {
            name: 'youtube',
            url: 'https://youtube.com/channel/UCtzMgW_lclcSks3KNXV_Vmg',
            btnClass: 'btn-social-counter--fb',
            texto:'',
        }
    ]
}
