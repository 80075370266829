import React, { useState } from "react";
import { ClickAwayListener, Paper, Popper, Skeleton } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import {
  convertDateTime,
  convertISODateString,
} from "../../../util/convertDate";
import ShareLinks from "../../../components/ShareLinks";
import { createSlug } from "../../../util/createSlug";

export default function CardNoticia(props) {
  const { noticia } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  return (
    <div>
      <article className="card card--lg post post--single">
        {noticia.loading || noticia.error ? (
          <Skeleton variant="rect" className="skeleton-noticia-img" />
        ) : (
          <figure className="post__thumbnail">
            <img
              src={
                noticia.payload.foto_capa
                  ? `${process.env.REACT_APP_SGE_URL}_uploads/${process.env.REACT_APP_ID_ESTABELECIMENTO}/noticias/${noticia.payload.foto_capa}`
                  : ""
              }
              alt=""
            />
          </figure>
        )}
        <div
          className={`card__content posts__item--category-${noticia.payload.id_esporte}`}
        >
          <div className="post__category">
            {noticia.loading || noticia.error ? (
              <Skeleton variant="text" height={25} width={80} />
            ) : (
              <span className="label posts__cat-label">
                {noticia.payload.idEsporte
                  ? noticia.payload.idEsporte.descricao
                  : ""}
              </span>
            )}
          </div>
          <header className="post__header">
            {noticia.loading || noticia.error ? (
              <h2 className="post__title">
                <Skeleton variant="text" />
              </h2>
            ) : (
              <h2 className="post__title">{noticia.payload.titulo}</h2>
            )}

            <ul className="post__meta meta">
              <li className="meta__item meta__item--date">
                {noticia.loading || noticia.error ? (
                  <Skeleton variant="text" height={25} width={120} />
                ) : (
                  <time
                    dateTime={convertISODateString(
                      noticia.payload.created_at * 1000
                    )}
                  >
                    {convertDateTime(noticia.payload.created_at * 1000)}
                  </time>
                )}
              </li>
              {/* <li className="meta__item meta__item--views">
                                {noticia.loading || noticia.error ?
                                    <Skeleton variant="text" height={25} width={120} /> :
                                    noticia.payload.acessos}
                            </li>
                            <li className="meta__item meta__item--likes">
                                <a href="#!">
                                    <i className="meta-like icon-heart">
                                    </i>
                                    -
                                </a>
                            </li>
                            <li className="meta__item meta__item--comments">
                                <a href="#!">-</a>
                            </li> */}
              <li className="meta__item meta__item--likes">
                <a href="#!" onClick={handlePopoverOpen}>
                  <i className="meta-like fas fa-share-alt"></i>
                </a>
                {noticia.loading || noticia.error ? (
                  ""
                ) : (
                  <Popper open={openMenu} anchorEl={anchorEl}>
                    <ClickAwayListener onClickAway={handlePopoverClose}>
                      <Paper
                        sx={{
                          p: 0.5,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <ShareLinks
                          shareUrl={`https://${
                            window.location.hostname
                          }/noticias/leitura/${noticia.payload.id}/${createSlug(
                            noticia.payload.titulo
                          )}`}
                        />
                      </Paper>
                    </ClickAwayListener>
                  </Popper>
                )}
              </li>
            </ul>
          </header>
          {noticia.loading || noticia.error ? (
            <React.Fragment>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" width="40%" />
            </React.Fragment>
          ) : (
            <div className="post__content">
              {ReactHtmlParser(noticia.payload.corpo)}
            </div>
          )}
        </div>
      </article>
    </div>
  );
}
