import React from 'react'
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

export function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <CircularProgress style={{ height: 62, width: 62, position: 'absolute', left: 0, zIndex: -1 }} sx={{ color: 'rgba(255, 255, 255, 0.2)' }} variant="determinate" value={100} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
