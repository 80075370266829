import { ConfedApi } from "../services/api";

export async function fetchEventoPartidaAtleta(props) {
  const { idPartida, idEquipe } = props;

  const res = await ConfedApi.get("evento-partida-atleta", {
    params: {
      forcewhere: `id_partida:${idPartida},id_equipe:${idEquipe}`,
      forcejoin: "atleta",
      expand: "scouts,atleta,estabelecimento",
      pageSize: 30,
    },
  });

  const data = res.data.items;
  return data;
}
