import { IconButton, Skeleton } from '@mui/material'
import React from 'react'
import { convertDate } from '../../../util/convertDate';
import { DownloadOitlineSVG } from '../../../assets/svg/TemplateIcons';

export default function AnexosEvento(props) {
    const { anexos } = props;

    if (anexos.error) {
        return (<React.Fragment></React.Fragment>)
    } else {
        return (
            <aside className="widget card widget--sidebar widget-newslog">
                <div className="widget__title card__header"><h4>Anexos </h4></div>
                <div className="widget__content card__content">
                    <ul className="newslog">
                        {(anexos.loading ? Array.from(new Array(2)) : anexos.payload).map((item, key) => (
                            <li className="py-3 newslog__item" key={key}>
                                <div className="d-flex align-items-start">
                                    {anexos.loading ?
                                        <Skeleton variant='circular' style={{ height: 30, width: 30, marginRight: 10 }} />
                                        :
                                        <IconButton component={'a'} target="_blank" rel="noreferrer"
                                            href={`${process.env.REACT_APP_SGE_URL}/_uploads/relatorioEvento/${item.arquivo}`} color="primary" size="small" style={{ outline: 'none' }}>
                                            <DownloadOitlineSVG fontSize="small" />
                                        </IconButton>
                                    }
                                    {anexos.loading ?
                                        <Skeleton variant="text" width={"60%"} />
                                        :
                                        <div className="ml-2" style={{
                                            fontSize: 11, lineHeight: '17px',
                                            fontFamily: '"Montserrat", sans-serif'
                                        }} >
                                            <a target="_blank" rel="noreferrer"
                                                style={{ fontWeight: 600, textTransform: 'uppercase' }}
                                                href={`${process.env.REACT_APP_SGE_URL}/_uploads/relatorioEvento/${item.arquivo}`}>
                                                {item.descricao}
                                            </a>
                                            <time className="d-block" style={{ opacity: 0.5 }} dateTime={convertDate(item.created_at * 1000)}>{convertDate(item.created_at * 1000)}</time>
                                        </div>
                                    }
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </aside>
        )
    }

}
