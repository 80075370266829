import React from 'react';

export default function Spotify() {

    if (process.env.REACT_APP_ESTABELECIMENTO === 'CBHB') {
        return (
            <div className="widget card card--has-table">
                <div className="card__header">
                    <h4>Podcast</h4>
                </div>
                <div className="card__content bg-transparent">
                    <iframe title="handcast" style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/show/3AlnsRXayLqNf6RuL5ZRxE?utm_source=generator" width="100%" height="232" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
                </div>
            </div>
        );
    }
    return (
        <div></div>
    );
}
