import { Skeleton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { TabPanel, TemplateTab, TemplateTabs } from "../../../components/Tabs";
import { convertDate } from "../../../util/convertDate";
import { createSlug } from "../../../util/createSlug";
import moment from "moment";
import { groupBy, orderBy } from "lodash";

const isFutsal = process.env.REACT_APP_REST_API_PREFIX === "cbfutsal";
const isCBHB = process.env.REACT_APP_REST_API_PREFIX === "cbhb";

const showNumjogos = isFutsal || isCBHB;

const partidasFases = {
  Grupo: { ordem: 0 },
  "Oitavas-Final": { ordem: 1 },
  "Quartas-Final": { ordem: 2 },
  "Semi-Final": { ordem: 3 },
  Final: { ordem: 4 },
};

export default function CalendarioJogos(props) {
  const { grupos, partidas, idEvento, slugEvento } = props;

  const testWinner = (pts1, pts2) => {
    if (pts1 > pts2) {
      return "widget-results__score-winner";
    } else {
      return "widget-results__score-loser";
    }
  };

  const rowHandler = ({ partidas, groupFase }) => {
    if (groupFase) {
      const grupos = groupBy(partidas, "fase");
      const groupArr = Object.entries(grupos).map((item) => ({
        titulo: item[0],
        ordem: partidasFases[item[0]] ? partidasFases[item[0]].ordem : 1,
        partidas: item[1],
      }));
      const partidasGrupo = orderBy(groupArr, ["ordem"], ["asc"]);

      return partidasGrupo.map((item, key) => (
        <React.Fragment key={key}>
          <tr>
            <th
              colSpan="3"
              className="alc-number alc-align-center text-center"
              style={{ backgroundColor: "#dee1e4" }}
            >
              <h6 className="mb-0">{item.titulo}</h6>
            </th>
          </tr>
          {rowPartida(item.partidas)}
        </React.Fragment>
      ));
    }

    return <React.Fragment>{rowPartida(partidas)}</React.Fragment>;
  };

  const rowPartida = (_partidas) => {
    const _partidasOrder = orderBy(_partidas, ["data"], ["asc"]);

    return _partidasOrder.map((item, key) => (
      <React.Fragment key={key}>
        <tr>

          <th colSpan="3" className="bg-light">
            <time dateTime={convertDate(item.data)}>
              <b>{convertDate(item.data)}</b>{" "}
            </time>{" "}
            - {item.fase}{" "}
            {showNumjogos ? <span> - Nº partida: {item.num_jogo}</span> : null}
          </th>
        </tr>
        <tr>
          <td className="alc-position alc-align-start" style={{ width: 320 }}>
            <Link
              to={`/clube/${item.casa.id}/${createSlug(item.casa.descricao)}`}
            >
              <div className="widget-results__team widget-results__team--first">
                <div className="widget-results__team-details">
                  <h5 className="widget-results__team-name">
                    {item.casa.sigla_evento}
                  </h5>
                  <span className="widget-results__team-info">
                    {item.casa.uf}
                  </span>
                </div>
                <figure className="widget-results__team-logo">
                  <img src={item.casa.urlLogo} alt={item.casa.sigla_evento} />
                </figure>
              </div>
            </Link>
          </td>
          <td>
            {moment(item.data).diff(Date.now(), "days") <= 1 ? (
              <div className="widget-results__result">
                <div className="widget-results__score">
                  <Link
                    to={`/evento/${idEvento}/${slugEvento}/partida/${item.id}/`}
                  >
                    {Number(item.placarPenaltiCasa) > 0 ||
                      Number(item.placarPenaltiVisitante) > 0 ? (
                      <React.Fragment>
                        <span
                          className={testWinner(
                            Number(item.placarPenaltiCasa),
                            Number(item.placarPenaltiVisitante)
                          )}
                        >
                          {item.placar_final_casa}&nbsp;
                          <small>({item.placarPenaltiCasa} </small>
                        </span>
                        x
                        <span
                          className={testWinner(
                            Number(item.placarPenaltiVisitante),
                            Number(item.placarPenaltiCasa)
                          )}
                        >
                          <small> {item.placarPenaltiVisitante})</small>
                          &nbsp;{item.placar_final_visitante}
                        </span>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <span
                          className={testWinner(
                            item.placar_final_casa,
                            item.placar_final_visitante
                          )}
                        >
                          {item.placar_final_casa}
                        </span>
                        -
                        <span
                          className={testWinner(
                            item.placar_final_visitante,
                            item.placar_final_casa
                          )}
                        >
                          {item.placar_final_visitante}
                        </span>
                      </React.Fragment>
                    )}
                  </Link>

                  <div className="widget-results__status"></div>
                </div>
              </div>
            ) : (
              <Link
                to={`/evento/${idEvento}/${slugEvento}/partida/${item.id}/`}
              >
                <div className="alc-event-scheduled__divider">vs</div>
              </Link>
            )}
          </td>
          <td style={{ width: 320 }}>
            <Link
              to={`/clube/${item.visitante.id}/${createSlug(
                item.visitante.descricao
              )}`}
            >
              <div className="widget-results__team widget-results__team--second">
                <figure className="widget-results__team-logo">
                  <img
                    src={item.visitante.urlLogo}
                    alt={item.visitante.sigla_evento}
                  />
                </figure>
                <div className="widget-results__team-details">
                  <h5 className="widget-results__team-name">
                    {item.visitante.sigla_evento
                      ? item.visitante.sigla_evento
                      : item.visitante.sigla}
                  </h5>
                  <span className="widget-results__team-info">
                    {item.visitante.uf}
                  </span>
                </div>
              </div>
            </Link>
          </td>
        </tr>
        <tr>
          <td
            colSpan={3}
            className="m-0 pt-0 pb-2"
            style={{ borderTop: "none" }}
          >
            <span className="game-result__league">
              <i className="fas fa-map-marker-alt"></i> {item.local}
            </span>
          </td>
        </tr>
      </React.Fragment>
    ));
  };

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div>
      <div className="widget card card--has-table">
        <div className="card__header">
          <h4>Calendário de jogos</h4>
        </div>
        <div className="card__content">
          {grupos.error || !grupos.payload ? (
            <div className="m-3 m-sm-4 alert alert-danger ">
              <strong>
                Ainda não há partidas cadastradas para esse evento.
              </strong>
            </div>
          ) : (
            <React.Fragment>
              <TemplateTabs
                color="primary"
                variant="fullWidth"
                value={tabIndex}
                onChange={handleChange}
              >
                {(grupos.loading
                  ? Array.from(new Array(2))
                  : grupos.payload
                ).map((item, key) =>
                  grupos.loading ? (
                    <TemplateTab key={key} label={`Grupo ${key + 1}`} />
                  ) : (
                    <TemplateTab key={key} label={item.descricao} />
                  )
                )}
                {partidas.loading || partidas.error || !partidas.payload ? (
                  ""
                ) : (
                  <TemplateTab label="Eliminatórias" />
                )}
              </TemplateTabs>

              {(grupos.loading ? Array.from(new Array(2)) : grupos.payload).map(
                (item, key) =>
                  grupos.loading ? (
                    <TabPanel key={key} value={tabIndex} index={0}></TabPanel>
                  ) : (
                    <TabPanel key={key} value={tabIndex} index={key}>
                      <div className="table-responsive">
                        <table className="table table-hover alc-table-stats alc-table-stats--compact-sm">
                          <tbody>
                            {grupos.loading ? (
                              <React.Fragment>
                                <tr>
                                  <th colSpan="3">
                                    <Skeleton
                                      height="1.5rem"
                                      animation="wave"
                                    />
                                  </th>
                                </tr>
                                <tr>
                                  <th colSpan="3">
                                    <Skeleton
                                      height="1.5rem"
                                      animation="wave"
                                    />
                                  </th>
                                </tr>
                                <tr>
                                  <th colSpan="3">
                                    <Skeleton
                                      height="1.5rem"
                                      animation="wave"
                                    />
                                  </th>
                                </tr>
                              </React.Fragment>
                            ) : (
                              rowHandler({
                                partidas: item.eventoPartidas,
                                groupFase: false,
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </TabPanel>
                  )
              )}
            </React.Fragment>
          )}

          {partidas.loading || partidas.error || !partidas.payload ? (
            ""
          ) : (
            <TabPanel
              value={tabIndex}
              index={grupos.payload.length ? grupos.payload.length : 0}
            >
              <div className="table-responsive">
                <table className="table table-hover alc-table-stats alc-table-stats--compact-sm">
                  <tbody>
                    {rowHandler({
                      partidas: partidas.payload,
                      groupFase: true,
                    })}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          )}
        </div>
      </div>
    </div>
  );
}
