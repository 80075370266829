import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/analytics';

var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(firebaseConfig);
firebase.analytics();
let messaging = null;
if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
}

export const getToken = (setTokenFound) => {
    if(!messaging){
        return
    }
    return messaging.getToken({ vapidKey: process.env.REACT_APP_VAPID_KEY }).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>

    new Promise((resolve) => {
        if(!messaging){
            return
        }
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });

// const { REACT_APP_VAPID_KEY } = process.env;
// const publicKey = REACT_APP_VAPID_KEY;

// export const getToken = async (setTokenFound) => {
//     let currentToken = "";

//     try {
//         currentToken = await messaging.getToken({ vapidKey: publicKey });
//         if (currentToken) {
//             setTokenFound(true);
//         } else {
//             setTokenFound(false);
//         }
//     } catch (error) {
//         console.log("An error occurred while retrieving token. ", error);
//     }

//     return currentToken;
// };

// export const onMessageListener = () =>
//     new Promise((resolve) => {
//         messaging.onMessage((payload) => {
//             resolve(payload);
//         });
//     });