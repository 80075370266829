import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ConfedApi } from '../../../services/api';
import { convertDateYear } from '../../../util/convertDate';
import { Skeleton } from '@mui/material';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { createSlug } from '../../../util/createSlug';

export default function TabAtletas(props) {
    const [atletasFEM, setAtletasFEM] = useState({
        loading: true,
        error: false,
        payload: []
    })
    const [atletasMASC, setAtletasMASC] = useState({
        loading: true,
        error: false,
        payload: []
    })
    const { clubeId } = props;

    const [atletasDestaque, setAtleatasDestaque] = useState({
        loading: true,
        error: false,
        payload: []
    })


    useEffect(() => {
        if (clubeId) {

            setAtleatasDestaque({
                loading: true,
                error: false,
                payload: []
            })
            setAtletasFEM({
                loading: true,
                error: false,
                payload: []
            })
            setAtletasMASC({
                loading: true,
                error: false,
                payload: []
            })
            async function getAtletas() {
                await ConfedApi.get('atleta', {
                    params: {
                        expand: 'vinculoAtual,atleta',
                        forcejoin: 'vinculoAtual',
                        forcewhere: `estabelecimentoAtual.id_estabelecimento:${clubeId}`,
                        pageSize: 1000,
                        sort: 'nome_evento'
                        // sort: 'atleta.nome_completo',
                        // flag_del: 0,
                    },
                }).then((response) => {
                    // setPageCount(response.data._meta.pageCount)

                    let destaques = _.shuffle(response.data.items)

                    setAtleatasDestaque({
                        loading: false,
                        error: false,
                        payload: destaques.slice(0, 9)
                    })

                    setAtletasFEM({
                        loading: false,
                        error: false,
                        payload: _.filter(response.data.items, function (o) { return o.atleta.sexo === 'F' })
                    })
                    setAtletasMASC({
                        loading: false,
                        error: false,
                        payload: _.filter(response.data.items, function (o) { return o.atleta.sexo === 'M' })
                    })

                    if (response.data._meta.totalCount < 1) {

                        setAtleatasDestaque({
                            loading: false,
                            error: true,
                            payload: []
                        })
                        setAtletasFEM({
                            loading: false,
                            error: true,
                            payload: []
                        })
                        setAtletasMASC({
                            loading: false,
                            error: true,
                            payload: []
                        })
                    }
                });
            }
            getAtletas()
        }

    }, [clubeId])

    const settingsSlick = {
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: true,
        swipeToSlide: true,
        autoplaySpeed: 3600,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
    };

    const tabelaAtleta = (_atletas) => {
        return (
            <div className="table-responsive">
                <table className="table table--lg team-roster-table">
                    <thead>
                        <tr>
                            <th className="team-roster-table__name">Nome do Atleta</th>
                            <th className="team-roster-table__age">Idade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_atletas.loading || _atletas.error ?
                            (Array.from(new Array(6))).map((item, key) => (
                                <tr key={key}>
                                    <td className="team-roster-table__name"><Skeleton variant="text" /></td>
                                    <td className="team-roster-table__age"><Skeleton variant="text" /></td>
                                </tr>
                            ))
                            :
                            (_atletas.payload.map((item, key) => (
                                <tr key={key}>
                                    <td className="team-roster-table__name">
                                        <Link to={`/atleta/${item.atleta.id}/${createSlug(item.atleta.nome_evento ? item.atleta.nome_evento : item.atleta.nome_completo)}`}>{item.nome_evento}</Link>
                                    </td>
                                    <td className="team-roster-table__age">{convertDateYear(item.atleta.data_nascimento)}</td>
                                </tr>
                            )))
                        }
                    </tbody>
                </table>
            </div>
        )
    }


    return (
        <div className="site-content">
            <div className="container">

                <Slider className='team-roster team-roster--case team-roster--case-slider slick-initialized slick-slider' {...settingsSlick}>
                    {
                        atletasDestaque.loading || atletasDestaque.error ?
                            (Array.from(new Array(6))).map((item, key) => (
                                <div key={key}>
                                    <div className="team-roster__item card card--no-paddings slick-slide slick-current slick-active" data-slick-index="4" aria-hidden="false" style={{ width: 380 }}>
                                        <div className="card__content">
                                            <figure className="team-roster__player-img">
                                                <a href="#!" tabindex="0">
                                                    <Skeleton variant="rectangular" height={300} />
                                                </a>
                                            </figure>
                                            <div className="card__header team-roster__player-details">
                                                <div className="team-roster__player-info">
                                                    <h3 className="team-roster__player-name">
                                                        <span className="team-roster__player-first-name"><Skeleton variant="test" /></span>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) :

                            atletasDestaque.payload.map((item, key) => (
                                <div key={key}>
                                    <div className="team-roster__item card card--no-paddings slick-slide slick-current slick-active" data-slick-index="4" aria-hidden="false" style={{ width: 380 }}>
                                        <div className="card__content">
                                            <figure className="team-roster__player-img">
                                                <Link to={`/atleta/${item.atleta.id}/${createSlug(item.atleta.nome_evento ? item.atleta.nome_evento : item.atleta.nome_completo)}`} tabindex={key}>
                                                    <img src={item.atleta.urlFoto} alt="" style={{ height: 400, objectFit: 'cover' }} />
                                                </Link>
                                            </figure>
                                            <div className="card__header team-roster__player-details">
                                                <div className="team-roster__player-info">
                                                    <h3 className="team-roster__player-name">
                                                        <span className="team-roster__player-first-name">{item.nome_evento}</span>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                    }
                </Slider>

                <div className="row">
                    <div className={!atletasMASC.payload[0]? "d-none": "col-lg-6"}>
                        <div className="widget card card--has-table">
                            <div className="card__header"><h4>Equipe Masculina</h4></div>
                            <div className="card__content">
                                {tabelaAtleta(atletasMASC)}
                            </div>
                        </div>
                    </div>
                    <div className={!atletasFEM.payload[0]? "d-none": "col-lg-6"}>
                        <div className="widget card card--has-table">
                            <div className="card__header"><h4>Equipe Feminina</h4></div>
                            <div className="card__content">
                                {tabelaAtleta(atletasFEM)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
