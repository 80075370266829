import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../../components/PageHeader";
import MetaContext from "../../context/MetaContext";
import { portalApi } from "../../services/api";

export default function STJDPage() {
  const { siteMeta } = useContext(MetaContext);

  const [directors, setDirectors] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setDirectors({
      loading: true,
      error: false,
      payload: [],
    });
    async function getInstitucional() {
      await portalApi
        .get("institucional", {
          params: {
            expand: "institucionalDiretorias",
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setDirectors({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setDirectors({
              loading: false,
              error: false,
              payload: response.data.items[0].institucionalDiretorias,
            });
          }
        });
    }
    getInstitucional();
  }, []);

  const breadcrumb = [
    { label: "HOME", url: "/", active: false },
    { label: "Olé", url: "/", active: true },
  ];

  return (
    <>
      <Helmet>
        <title>TRIBUNAL ARBITRAL DESPORTIVO - {siteMeta.siteTitle}</title>
      </Helmet>
      <PageHeader
        title="TRIBUNAL ARBITRAL DESPORTIVO"
        breadcrumb={breadcrumb}
      />
      <div className="site-content">
        <div className="container">
          <div className="row">
            <div className="content col-md-12">
              <div className="row">
                {directors.loading || directors.error
                  ? ""
                  : directors.payload.map((item, key) => (
                      <div className="col-md-4" key={key}>
                        <div className="card mb-3 card-pessoa">
                          <img
                            src={`${process.env.REACT_APP_SGE_URL}_uploads/diretoria/fotos/tmp/${item.foto}`}
                            alt={item.nome}
                          />
                          <div className="card-body">
                            <h4 className="mb-1">{item.nome}</h4>
                            <h6 className="text-primary mb-0">
                              {item.diretoria}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
