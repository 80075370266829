import {
  ButtonBase,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import escopoOptions from "../../assets/data/escopoOptions";
import { temporadas } from "../../assets/data/temporadas";
import PageHeader from "../../components/PageHeader";
import { gestaoApi } from "../../services/api";
import TabelaEventos from "./sections/TabelaEventos";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import MetaContext from "../../context/MetaContext";

export default function EventosPage(params) {
  const sectionTop = useRef(null);
  const { siteMeta } = useContext(MetaContext);
  const queryParam = params.queryParam;
  const temporada = params.temporada;
  const escopo = params.escopo;
  const page = params.page;

  const [temporadaEvento, setTemporadaEvento] = useState(temporada);
  const [escopoEvento, setEscopoEvento] = useState(escopo);
  const [searchTerm, setSearchTerm] = useState(queryParam);

  let history = useHistory();

  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [eventos, setEventos] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  const [totalPages, setTotalPages] = useState(0);

  const breadcrumb = [
    { label: "HOME", url: "/", active: false },
    { label: "Eventos", url: "/eventos", active: true },
  ];

  useEffect(() => {
    let data1 = "";
    let data2 = "";
    if (temporada) {
      data1 = temporada.concat("-01-01");
      data2 = temporada.concat("-12-31");
    }
    setEventos({
      loading: true,
      error: false,
      payload: [],
    });
    async function getPartidasDestaque() {
      await gestaoApi
        .get("evento", {
          params: {
            flag_del: 0,
            id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
            expand: "estabelecimento",
            sort: "-data_inicio",
            escopo: escopo,
            descricao: queryParam,
            betweenField: "data_inicio",
            betweenValue1: data1,
            betweenValue2: data2,
            page: page,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setEventos({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setEventos({
              loading: false,
              error: false,
              payload: response.data.items,
            });
            setTotalPages(response.data._meta.pageCount);
          }
          if (page || queryParam || escopo || temporada) {
            executeScroll();
          }
        })
        .catch((err) => {
          setEventos({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getPartidasDestaque();
  }, [page, queryParam, escopo, temporada]);

  const executeScroll = () =>
    window.scrollTo({
      behavior: "smooth",
      top: sectionTop.current.offsetTop - 0,
    });

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchTerm) {
        history.push(`/eventos?q=${searchTerm}`);
      } else {
        history.push("/eventos");
      }
    }
  };

  //selects
  const handleClickListItem = (event, index) => {
    setOpenMenu({ [index]: !openMenu[index] });
    setAnchorEl(event.currentTarget);
  };

  const handleMenuTemporada = (event, index) => {
    setTemporadaEvento(temporadas[index].value);
    if (queryParam || escopo) {
      history.push(
        `/eventos${
          temporadas[index].value
            ? `?temporada=${temporadas[index].value}&`
            : "?"
        }${escopo ? `escopo=${escopo}&` : ""}${
          queryParam ? `q=${queryParam}` : ""
        }`
      );
    } else {
      history.push(
        `/eventos${
          temporadas[index].value ? `?temporada=${temporadas[index].value}` : ""
        }`
      );
    }
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleMenuEscopo = (event, index) => {
    setEscopoEvento(escopoOptions[index].value);
    if (queryParam || temporada) {
      history.push(
        `/eventos${temporada ? `?temporada=${temporada}&` : "?"}${
          escopoOptions[index].value
            ? `escopo=${escopoOptions[index].value}&`
            : ""
        }${queryParam ? `q=${queryParam}` : ""}`
      );
    } else {
      history.push(
        `/eventos${
          escopoOptions[index].value
            ? `?escopo=${escopoOptions[index].value}`
            : ""
        }`
      );
    }
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  return (
    <div>
      <Helmet>
        <title>Eventos - {siteMeta.siteTitle}</title>
      </Helmet>

      <PageHeader title="Eventos" breadcrumb={breadcrumb} />
      <div ref={sectionTop}></div>
      <div className="post-filter">
        <div className="container">
          <div className="post-filter__form clearfix">
            <div className="post-filter__select" style={{ minHeight: 80 }}>
              <label className="post-filter__label" for="input-default">
                Pesquisar
              </label>
              <div
                className="cs-select cs-skin-border"
                style={{ height: "100%", display: "flex" }}
              >
                <input
                  type="text"
                  className="form-control"
                  id="input-default"
                  placeholder="Pesquisar evento..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleSearchKeyDown}
                  style={{ height: 30, margin: "auto 1.5rem 10px 1.5rem " }}
                />
              </div>
            </div>
            <div className="post-filter__select">
              <ButtonBase
                className="post-filter__select"
                aria-expanded={openMenu[0] ? "true" : undefined}
                onClick={(e) => handleClickListItem(e, 0)}
              >
                <label className="post-filter__label">Temporada</label>
                <div className="cs-select cs-skin-border" tabindex="0">
                  <span className="cs-placeholder">
                    {temporadaEvento ? temporadaEvento : "Selecione"}
                  </span>
                </div>
              </ButtonBase>
              <Menu
                elevation={1}
                className="cs-options"
                anchorEl={anchorEl}
                open={openMenu[0]}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "lock-button",
                  role: "listbox",
                }}
              >
                {temporadas.map((option, index) => (
                  <MenuItem
                    style={{ minWidth: 200 }}
                    key={index}
                    selected={index === temporadaEvento}
                    onClick={(event) => handleMenuTemporada(event, index)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div className="post-filter__select">
              <ButtonBase
                className="post-filter__select"
                aria-expanded={openMenu[1] ? "true" : undefined}
                onClick={(e) => handleClickListItem(e, 1)}
              >
                <label className="post-filter__label">Escopo</label>
                <div className="cs-select cs-skin-border" tabindex="0">
                  <span className="cs-placeholder">
                    {escopoEvento ? escopoEvento : "Selecione"}
                  </span>
                </div>
              </ButtonBase>
              <Menu
                elevation={1}
                className="cs-options"
                anchorEl={anchorEl}
                open={openMenu[1]}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "lock-button",
                  role: "listbox",
                }}
              >
                {escopoOptions.map((option, index) => (
                  <MenuItem
                    style={{ minWidth: 200 }}
                    key={index}
                    selected={index === escopoEvento}
                    onClick={(event) => handleMenuEscopo(event, index)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="site-content">
        <div className="container">
          <div className="widget card card--has-table">
            <div className="card__header card__header--has-btn">
              <h4>Eventos</h4>
            </div>
            <div className="card__content">
              <TabelaEventos eventos={eventos} />

              <div className={totalPages > 1 ? "pb-4" : ""}>
                {totalPages > 1 ? (
                  <Route>
                    {({ location }) => {
                      const query = new URLSearchParams(location.search);
                      const page = parseInt(query.get("p") || "1", 10);

                      return (
                        <nav className="post__comments-pagination">
                          <Pagination
                            className="pagination justify-content-center"
                            variant="outlined"
                            shape="rounded"
                            count={totalPages}
                            page={page}
                            renderItem={(item) => {
                              if (queryParam || escopo || temporada) {
                                return (
                                  <PaginationItem
                                    className="page-link"
                                    component={Link}
                                    to={`/eventos?${
                                      temporada ? `temporada=${temporada}&` : ""
                                    }${escopo ? `escopo=${escopo}&` : ""}${
                                      queryParam ? `q=${queryParam}&` : ""
                                    }${
                                      item.page === 1 ? "" : `p=${item.page}`
                                    }`}
                                    {...item}
                                  />
                                );
                              } else {
                                return (
                                  <PaginationItem
                                    className="page-link"
                                    component={Link}
                                    to={`/eventos${
                                      item.page === 1 ? "" : `?p=${item.page}`
                                    }`}
                                    {...item}
                                  />
                                );
                              }
                            }}
                          />
                        </nav>
                      );
                    }}
                  </Route>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
