import { ConfedApi } from "../services/api";

export async function fetchEventoStaff(props) {
  const { idPartida, idEquipe } = props;

  const res = await ConfedApi.get("evento-staff", {
    params: {
      forcewhere: `id_partida:${idPartida},id_equipe:${idEquipe}`,
      expand: "scout,pessoa,funcao",
      flag_del: 0,
    },
  });
  const data = res.data.items;
  return data;
}
