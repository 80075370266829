import React, { useEffect, useState, useRef } from "react";
import { governancaApi } from "../../../services/api";
import { Pagination, PaginationItem } from "@mui/material";
import moment from "moment";

export default function PesquisaDocumentos(props) {
  const { searchTerm } = props;
  const [page, setPage] = useState(1);
  const [documentos, setDocumentos] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(1);

  const sectionTop = useRef(null);

  useEffect(() => {
    setDocumentos({
      loading: true,
      error: false,
      payload: [],
    });
    setTotalPages(0);
    if (searchTerm) {
      async function getOrgaoAnexos() {
        await governancaApi
          .get("orgao-anexo", {
            params: {
              flag_del: 0,
              sort: "-created_at",
              titulo: searchTerm,
              page: page,
            },
          })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setDocumentos({
                loading: false,
                error: true,
                payload: [],
              });
              setTotalPages(0);
            } else {
              setDocumentos({
                loading: false,
                error: false,
                payload: response.data.items,
              });
              setTotalPages(response.data._meta.pageCount);
              setTotalResults(response.data._meta.totalCount);
            }
          });
      }
      getOrgaoAnexos();
    }
  }, [searchTerm, page]);

  const handleChange = (event, value) => {
    setPage(value);
    if (value === 1) {
      executeScroll();
    }
  };

  useEffect(() => {
    if (page > 1) {
      executeScroll();
    }
  }, [page]);

  const executeScroll = () =>
    window.scrollTo({
      behavior: "smooth",
      top: sectionTop.current.offsetTop - 150,
    });

  return (
    <React.Fragment>
      <div ref={sectionTop} className="errorContainer">
        <div
          className={`alert ${
            documentos.error ? "alert-danger" : "alert-success"
          } `}
        >
          <strong>
            {totalResults}{" "}
            {totalResults > 1
              ? "Documentos foram encontrados"
              : " Documento foi encontrado"}{" "}
            para "<span className="text-success">{searchTerm}</span>"
          </strong>
        </div>
      </div>
      {documentos.error ? (
        ""
      ) : (
        <React.Fragment>
          <div className="widget card card--has-table">
            <header className="card__header card__header--has-btn">
              <h4>Documentos</h4>
            </header>
            <div className="card__content">
              <div className="table-responsive">
                <table className="table table-hover team-schedule">
                  <thead>
                    <tr>
                      <th>Descrição</th>
                      <th>Data</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documentos.payload.map((item, key) => (
                      <tr key={key}>
                        <td className="text-left">{item.titulo}</td>
                        <td>
                          {moment(item.created_at * 1000).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          <a
                            className="btn btn-primary btn-xs"
                            target="_blank"
                            href={`${process.env.REACT_APP_SGE_URL}_uploads/orgaoAnexo/${item.nome_arquivo}`}
                            rel="noreferrer"
                          >
                            download
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      {totalPages > 1 ? (
        <div>
          <Pagination
            className="pagination pagination--condensed pagination--lg justify-content-center"
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            page={page}
            count={totalPages}
            renderItem={(item) => {
              return <PaginationItem className="page-link" {...item} />;
            }}
          />
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
}
