import React from "react";
import { Typography, Avatar, Box } from "@mui/material";
import adalberto from "../../assets/images/CBBC/governanca/adalberto.webp";

function OuvidoriaCBBC() {
  return (
    <Box sx={{ mb: 5 }}>
      <Avatar
        sx={{ margin: "auto", height: { xs: 120, md: 200 }, width: { xs: 120, md: 200 }, mb: 2 }}
        src={adalberto}
        alt="adalberto"
      />
      <Box sx={{ textAlign: "center" }}>
        <Typography lineHeight="1" sx={{ fontWeight: 700, fontSize: "1.5rem" }}>
          ADALBERTO RODRIGUES
        </Typography>
        <Typography lineHeight="1" variant="caption" sx={{ fontSize: ".85rem" }}>
          OUVIDOR
        </Typography>
        <Typography>
          E-mail - <a href="mailto:ouvidoria@cbbc.org.br">ouvidoria@cbbc.org.br</a>
        </Typography>
      </Box>
    </Box>
  );
}

export default OuvidoriaCBBC;
