import React, { useMemo, useState } from "react";
import { filter } from "lodash";
// import Popover from '@mui/material/Popover';
import { Paper, Popper } from "@mui/material";
import moment from "moment";
import "./PartidaTimeline.css";
import { CalculaPorcentagem } from "../../../util/Porcentagem";
import { CreateFutsalTimeline } from "./Timeline/CreateFutsalTimeline";
import { CreateBasqueteTimeline } from "./Timeline/CreateBasqueteTimeline";

function PartidaTimeLine(props) {
  const { eventoPartidaScouts, eventoPartida } = props;

  const timeline = useMemo(() => {
    if (!eventoPartidaScouts.loading && !eventoPartidaScouts.error) {
      const todosScouts = eventoPartidaScouts.payload.scouts;
      const scouts = filter(todosScouts, (o) => o.scout.icon);

      if (scouts.length) {
        if (process.env.REACT_APP_TEMPLATE === "CBFutsal") {
          return CreateFutsalTimeline(scouts, todosScouts, eventoPartida);
        }

        if (process.env.REACT_APP_TEMPLATE === "CBBC") {
          return CreateBasqueteTimeline(scouts, todosScouts, eventoPartida);
        }

        const filtros = [
          "Pausar Período",
          "Pausar Tempo",
          "Iniciar Tempo",
          "Iniciar Período",
          "Fim Período",
        ];
        const scoutsRelevantes = filter(
          scouts,
          (o) =>
            o.scout.tipo_render !== "tipo_render" &&
            o.scout.icon !== "icon-whistle" &&
            !filtros.includes(o.scout.descricao)
        );

        const inicio = moment(scouts[0].data);
        const fim = moment(scouts.pop().data);
        const tempoJogo = fim.diff(inicio);

        let scoutsTimeline = scoutsRelevantes.map((item) => {
          let datetime = moment(item.data);
          let difftimescout = datetime.diff(inicio);
          let porcentagem = CalculaPorcentagem(difftimescout, tempoJogo);
          let timeCasa = Boolean(
            item.id_equipe === eventoPartida.id_equipe_casa
          );

          return {
            ...item,
            inicio,
            fim,
            scoutTime: difftimescout,
            porcentagem,
            timeCasa,
            tempoFormatado: (item.tempo / 100).toFixed(2).toString(),
            tempoScout: millisToMinutesAndSeconds(difftimescout),
          };
        });

        return scoutsTimeline;
      }
    }
    return null;
  }, [eventoPartidaScouts, eventoPartida]);

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  //
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handlePopoverOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu({ [index]: !openMenu[index] });
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const [popperContent, setPopperContent] = useState("popper");

  return (
    <React.Fragment>
      {!timeline || !eventoPartida ? (
        <React.Fragment></React.Fragment>
      ) : (
        <div className="widget card">
          <div className="card__header">
            <h4>Linha do tempo</h4>
          </div>
          <div className="card__content card__content--pattern-dotted">
            <div className="game-timeline-wrapper pt-4 pb-4">
              <div className="game-timeline game-timeline--adaptive">
                <div className="game-timeline__event">
                  <div className="game-timeline__team-1">
                    <img
                      alt={
                        eventoPartida.casa ? eventoPartida.casa.descricao : ""
                      }
                      src={eventoPartida.casa ? eventoPartida.casa.urlLogo : ""}
                    />
                  </div>
                  <div className="game-timeline__time">0’</div>

                  <div className="game-timeline__team-2">
                    <div>
                      <img
                        alt={
                          eventoPartida.visitante
                            ? eventoPartida.visitante.descricao
                            : ""
                        }
                        src={
                          eventoPartida.visitante
                            ? eventoPartida.visitante.urlLogo
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                {timeline.map((item, key) => (
                  <div
                    key={key}
                    className={`game-timeline__event ${
                      openMenu[key] ? "active" : ""
                    }`}
                    style={{
                      left: `${item.porcentagem}%`,
                      width: 10,
                      margin: 0,
                    }}
                    aria-owns={openMenu[key] ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={(e) => {
                      handlePopoverOpen(e, key);
                      setPopperContent(
                        <React.Fragment>
                          {item.atleta ? (
                            <div className="game-timeline__event-info">
                              <div className="game-timeline__event-name">
                                {item.atleta.nome_evento
                                  ? item.atleta.nome_evento
                                  : item.atleta.atleta.nome_completo}{" "}
                                - {item.scout.descricao}
                              </div>
                              <div className="game-timeline__event-desc">
                                {item.timeCasa
                                  ? eventoPartida.casa.sigla_evento
                                  : eventoPartida.visitante.sigla_evento}
                              </div>
                            </div>
                          ) : (
                            <div className="game-timeline__event-info">
                              <div className="game-timeline__event-name">
                                {item.scout.descricao}
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    }}
                    onMouseLeave={handlePopoverClose}
                  >
                    <div
                      className={`game-timeline__team-${
                        item.timeCasa ? "1" : "2"
                      }`}
                    >
                      <i
                        className={`icon-svg ${item.scout.icon} ${
                          item.convertido === 1 ? "invalid" : ""
                        }`}
                      ></i>
                    </div>
                    <div className="game-timeline__time">
                      {item.tempoFormatado}’
                    </div>
                  </div>
                ))}
                <div className="game-timeline__event game-timeline__event--ft">
                  <div className="game-timeline__time">FIM</div>
                </div>
              </div>
            </div>
          </div>
          <Popper
            id="mouse-over-popover"
            open={openMenu}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Paper elevation={1} sx={{ p: 1, textAlign: "center" }}>
              {popperContent}
            </Paper>
          </Popper>
        </div>
      )}
    </React.Fragment>
  );
}
export default PartidaTimeLine;
