import React from 'react';
import { Link } from 'react-router-dom';
import tools from '../../assets/images/icon-tools.svg'
 
export default function PageEmBreve() {
    return (
        <div className="site-content">
            <div className="container">
                <div className="error-404">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <figure className="error-404">
                                <img src={tools} alt="" style={{height:200,opacity:0.4}}/>
                            </figure>
                            <header className="error__header">
                                <h2 className="error__title">Em breve!</h2>
                                <h3 className="error__subtitle">Página em construção.</h3>
                            </header>
                            <div className="error__description">
                                A página que você está procurando ainda não está disponivel.
                            </div>
                            <footer className="error__cta">
                                <Link to="/" className="btn btn-primary">Voltar para Home</Link>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}