import React from "react";
import RowFimPartida from "./RowFimPartida";
import RowPonto from "./RowPonto";
import RowTempo from "./RowTempo";

function RowHandler({ scoutTimeline, resultado, partida }) {
  if (scoutTimeline.scout && scoutTimeline.scout.tipo_render) {
    if (scoutTimeline.scout.tipo_render === "tempo") {
      return <RowTempo scoutTimeline={scoutTimeline} partida={partida} />;
    }
    if (scoutTimeline.scout.tipo_render === "penalidade") {
      return <RowPonto scoutTimeline={scoutTimeline} />;
    }
    if (scoutTimeline.scout.tipo_render === "fim_partida") {
      return (
        <RowFimPartida resultado={resultado} scoutTimeline={scoutTimeline} />
      );
    }
  }
  return <RowPonto scoutTimeline={scoutTimeline} />;
}

export default RowHandler;
