import React from 'react'
import { Link } from 'react-router-dom'
import { convertDate } from '../../../util/convertDate'
import { createSlug } from '../../../util/createSlug'

export default function TabelaEventos({ eventos }) {
    return (
        <div className="table-responsive">
            <table className="table table-hover team-result">
                <thead>
                    <tr>
                        <th className="team-result__vs">
                            Descrição</th>
                        <th className="team-result__score">
                            Escopo</th>
                        <th className="team-result__status">
                            Local</th>
                        <th className="team-result__ball-possession">
                            Data Inicio</th>
                        <th className="team-result__shots">
                            Data Fim</th>
                        <th className="team-result__game-overview">
                        </th>
                    </tr>
                </thead>
                {eventos.loading || eventos.error || !eventos.payload ? <tbody></tbody> :
                    <tbody>
                        {eventos.payload.map((item, key) => (
                            <tr key={key}>
                                <td className="team-result__vs">
                                    {item.descricao}
                                </td>
                                <td className="team-result__score">
                                    {item.escopo}</td>
                                <td className="team-result__status">
                                    {item.local}</td>
                                <td className="team-result__ball-possession">
                                    {convertDate(item.data_inicio)}</td>
                                <td className="team-result__shots">
                                    {convertDate(item.data_fim)}</td>
                                <td className="team-result__game-overview">
                                    <Link to={`/evento/${item.id}/${createSlug(item.descricao)}`} style={{ minWidth: 87 }} className="btn btn-xs btn-primary btn-block">
                                        ver detalhes
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                }
            </table>
        </div>
    )
}
