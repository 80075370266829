import React from "react";
import { Link } from "react-router-dom";
import {
  convertDateTime,
  convertISODateString,
} from "../../../util/convertDate";
import { createSlug } from "../../../util/createSlug";
import { CardMedia, Grid } from "@mui/material";

export default function GridNoticias(props) {
  const { noticias } = props;

  return (
    <div>
      <div className="container">
        <div className="posts posts--tile posts--tile-alt posts--tile-alt-nopaddings posts--tile-hero-post-grid posts--tile-meta-position">
          <Grid container rowSpacing={1.5}>
            {noticias.loading || noticias.error || !noticias.payload ? null : (
              <React.Fragment>
                <Grid item xs={12} sm={12} md={6}>
                  <CardNoticia noticia={noticias.payload[0]} index={0} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid
                    container
                    rowSpacing={1.5}
                    sx={{ minHeight: { md: 550 } }}
                  >
                    {noticias.payload.slice(1, 5).map((item, key) => (
                      <Grid item xs={12} sm={12} md={6} key={key}>
                        <CardNoticia noticia={item} index={key + 1} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
}

function CardNoticia({ noticia, index }) {
  return (
    <div
      className={`h-100 post-grid__item posts__item posts__item--tile posts__item--category-${noticia.id_esporte}`}
      style={{ borderRadius: 2, minHeight: 220 }}
    >
      <figure className="posts__thumb h-100">
        <CardMedia
          image={
            noticia.foto_capa
              ? `${process.env.REACT_APP_SGE_URL}_uploads/${process.env.REACT_APP_ID_ESTABELECIMENTO}/noticias/${noticia.foto_capa}`
              : ""
          }
          alt={noticia.titulo}
          className="h-100"
        />
        <Link
          to={`/noticias/leitura/${noticia.id}/${createSlug(noticia.titulo)}`}
          className="posts__item-link-overlay"
          title={noticia.titulo}
        ></Link>
        <div className="posts__inner posts__inner--noactive">
          <div className="posts__cat">
            <span
              className={`label posts__cat-label posts__cat-label--category-${noticia.id_esporte}`}
            >
              {noticia.idEsporte ? noticia.idEsporte.descricao : ""}
            </span>
          </div>
          <h6
            className={`posts__title ${index === 0 ? "posts__title--lg" : ""}`}
          >
            <Link
              to={`/noticias/leitura/${noticia.id}/${createSlug(
                noticia.titulo
              )}`}
            >
              {noticia.titulo}
            </Link>
          </h6>
          <time
            dateTime={convertISODateString(noticia.created_at * 1000)}
            className="posts__date"
          >
            {convertDateTime(noticia.created_at * 1000)}
          </time>

          <div className="posts__excerpt">{noticia.resumo}</div>
        </div>
      </figure>
      {/* <Link to={`/noticias/leitura/${item.id}/${createSlug(item.titulo)}`} className="posts__cta"></Link> */}
    </div>
  );
}
