import axios from 'axios';

const apiURL = (category) => `${process.env.REACT_APP_REST}${category}/api/`;
 

const apiConnection = (category) => axios.create({
  baseURL: apiURL(category),
});

// api gestao
export const gestaoApi = apiConnection('gestao');

//api confederação
export const ConfedApi = apiConnection(process.env.REACT_APP_REST_API_PREFIX);

// api portal
export const portalApi = apiConnection('portal');

// api cbca
export const governancaApi = apiConnection('governanca');