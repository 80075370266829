import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import { Helmet } from "react-helmet";
import ScrollToTop from "./util/ScrollToTop";

//components
import Navbar from "./components/Navbar";

//Vendor CSS
import "./assets/vendor/bootstrap/css/bootstrap.css";
import "./assets/fonts/font-awesome/css/all.min.css";
import "./assets/fonts/simple-line-icons/css/simple-line-icons.css";
import "./assets/vendor/magnific-popup/dist/magnific-popup.css";
import "./assets/vendor/slick/slick.css";

// //Custom CSS
// import './assets/css/custom.css';
import ThemeSelector from "./services/ThemeSelector";
import HomePage from "./pages/home";
import PageNotFound from "./pages/page-not-found";
import Footer from "./components/Footer";
import NoticiasPage from "./pages/noticias";
import NoticiaLeituraPage from "./pages/noticias-leitura";
import EventoPage from "./pages/evento";
import ClubePage from "./pages/clube";
import EventosPage from "./pages/eventos";
import PartidaPage from "./pages/partida";
import PageEmBreve from "./pages/page-em-breve";
import OrgaoPage from "./pages/orgao-page";
import ContatoPage from "./pages/contato";
import PesquisaPage from "./pages/pesquisa";
import { ThemeProvider } from "@emotion/react";
import {
  themeCBBC,
  themeCBHB,
  themeFPHAND,
  themeFutsal,
  themeLigaole,
} from "./services/MUItheme";
import AtletaPage from "./pages/atleta";
import EventosOrgao from "./pages/eventosOrgao";
import { getToken, onMessageListener } from "./firebase";
import { IconButton, Snackbar } from "@mui/material";
import { CloseIconSVG } from "./assets/svg/TemplateIcons";
import { menuLPFutsal } from "./assets/data/menuLPFutsal";
import { menuLigaOle } from "./assets/data/menuLigaOle";
import { menu } from "./assets/data/menu";
import { metaLPF } from "./config/metaCBFutsal";
import { metaCBHb } from "./config/metaCBHB";
import { menuFEDCBHb } from "./assets/data/menuFEDCBHb";

import MetaContext from "./context/MetaContext";
import { metaFAHd } from "./config/metaFAHd";
import { metaFHEPA } from "./config/metaFHEPA";
import { metaLIHAM } from "./config/metaLIHAM";
import { metaLigaole } from "./config/metaLigaole";
import { metaCBBC } from "./config/metaCBBC";
import { metaFABR } from "./config/metaFABR";
import "./assets/css/custom-common.css";
import STJDPage from "./pages/stjd";
import GovernancaPage from "./pages/governanca";
import PageGaleria from "./pages/galeria";
import GaleriaItem from "./pages/galeria-item";
import OrgaoContentPage from "./pages/orgao-content-page";
import { menuFABR } from "./assets/data/menuFABR";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { metaFPHAND } from "./config/metaFPHand";
import { menuFPHAND } from "./assets/data/menuFPHAND";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
        retry: false,
      },
    },
  });

  const [openDrawer, setOpenDrawer] = useState(false);
  const [siteMeta, setSiteMeta] = useState({
    siteTitle: process.env.REACT_APP_DOMAIN,
    siteDescription: "",
    siteEndereco: "",
    siteFuncionamento: "",
    siteImg: "",
    favicon: "",
    logo: "",
    redesSociais: [],
  });
  const [muiTheme, setMuiTheme] = useState(themeFutsal);
  const [siteMenu, setSiteMenu] = useState([]);

  useEffect(() => {
    switch (process.env.REACT_APP_ESTABELECIMENTO) {
      case "CBHB":
        setSiteMeta(metaCBHb);
        setSiteMenu(menu);
        setMuiTheme(themeCBHB);
        break;
      case "CBFutsal":
        setSiteMeta(metaLPF);
        setSiteMenu(menuLPFutsal);
        setMuiTheme(themeFutsal);
        break;
      case "FHEPA":
        setSiteMeta(metaFHEPA);
        setSiteMenu(menuFEDCBHb);
        setMuiTheme(themeCBHB);
        break;
      case "FAHd":
        setSiteMeta(metaFAHd);
        setSiteMenu(menuFEDCBHb);
        setMuiTheme(themeCBHB);
        break;
      case "LIHAM":
        setSiteMeta(metaLIHAM);
        setSiteMenu(menuFEDCBHb);
        setMuiTheme(themeCBHB);
        break;
      case "Ligaole":
        setSiteMeta(metaLigaole);
        setSiteMenu(menuLigaOle);
        setMuiTheme(themeLigaole);
        break;
      case "CBBC":
        setSiteMeta(metaCBBC);
        setSiteMenu([]);
        setMuiTheme(themeCBBC);
        break;
      case "FABR":
        setSiteMeta(metaFABR);
        setSiteMenu(menuFABR);
        setMuiTheme(themeFutsal);
        break;
      case "FPHAND":
        setSiteMeta(metaFPHAND);
        setSiteMenu(menuFPHAND);
        setMuiTheme(themeFPHAND);
        break;
      default:
        break;
    }
  }, []);

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  getToken(setTokenFound);

  onMessageListener()
    .then((payload) => {
      if (isTokenFound) {
        setShow(true);
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
      }
    })
    .catch((err) => console.log("failed: ", err));

  const handleClose = () => {
    setShow(false);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeSelector>
        <ThemeProvider theme={muiTheme}>
          <MetaContext.Provider value={{ siteMeta }}>
            <Helmet>
              <title>{siteMeta.siteTitle}</title>
              <meta name="description" content={siteMeta.siteDescription} />
              <meta property="" content={siteMeta.siteDescription} />
              <link rel="shortcut icon" href={siteMeta.favicon} />
              <link rel="icon" href={siteMeta.favicon} />
              <meta
                property="og:description"
                content={siteMeta.siteDescription}
              />
              <meta property="og:image" content={siteMeta.siteImg} />
              <meta property="og:type" content="website" />
              <meta property="og:url" content={window.location.href} />
              <link rel="canonical" href={window.location.href} />
              {process.env.REACT_APP_ESTABELECIMENTO === "CBHB" ?
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4416035360451877"
                  crossorigin="anonymous"></script>
                : null}
            </Helmet>

            <div
              className={
                openDrawer
                  ? "site-wrapper clearfix site-wrapper--has-overlay"
                  : "site-wrapper clearfix"
              }
              style={{ minHeight: "100vh" }}
            >
              <div className="site-overlay"></div>

              <Router>
                <ScrollToTop />
                <Navbar
                  openDrawer={openDrawer}
                  siteMenu={siteMenu}
                  setOpenDrawer={setOpenDrawer}
                  siteMeta={siteMeta}
                />

                <Switch>
                  <Route path="/" exact>
                    <HomePage />
                  </Route>

                  <Route path="/noticias" exact>
                    <NoticiasParams siteMenu={siteMenu} />
                  </Route>
                  <Route path="/noticias/leitura/:id/:slug">
                    <NoticiaLeituraPage siteMenu={siteMenu} />
                  </Route>

                  <Route path="/evento/:idEvento/:slug" exact>
                    <EventoPage />
                  </Route>

                  <Route path="/eventos" exact>
                    <EventosParams />
                  </Route>

                  <Route path="/eventos/:id_menu/:slug" exact>
                    <EventosOrgao />
                  </Route>

                  <Route
                    path="/evento/:idEvento/:slug/partida/:idPartida"
                    exact
                  >
                    <PartidaPage />
                  </Route>
                  <Route path="/clube/:id/:slug" exact>
                    <ClubePage />
                  </Route>
                  <Route path="/atleta/:id/:slug" exact>
                    <AtletaPage />
                  </Route>

                  <Route path="/governanca/:idMenu/:url" exact>
                    <OrgaoPage menuGov={true} />
                  </Route>

                  <Route path="/ole/:idMenu/:url">
                    <OrgaoPage menuGov={false} />
                  </Route>
                  <Route path="/fphb/:idMenu/:url">
                    <OrgaoPage />
                  </Route>
                  <Route path="/ole/stjd">
                    <STJDPage />
                  </Route>
                  {/* cbbc */}
                  <Route path="/cbbc/:idMenu/:url" exact>
                    <OrgaoPage menuGov={false} />
                  </Route>
                  <Route path="/cbbc/pessoa/:idOrgao/:url" exact>
                    <OrgaoContentPage />
                  </Route>
                  <Route path="/governanca-cbbc/:idMenu/:tipoGov" exact>
                    <GovernancaPage />
                  </Route>

                  <Route path="/contato">
                    <ContatoPage />
                  </Route>

                  <Route path="/ouvidoria">
                    <ContatoPage tipoContato={"ouvidoria"} />
                  </Route>
                  <Route path="/fale-conosco">
                    <ContatoPage tipoContato={"fale-conosco"} />
                  </Route>

                  <Route path="/pesquisa">
                    <PesquisaParams />
                  </Route>

                  <Route path="/em-breve">
                    <PageEmBreve />
                  </Route>

                  <Route path="/galeria" exact>
                    <PageGaleria />
                  </Route>
                  <Route path="/album-de-fotos" exact>
                    <PageGaleria />
                  </Route>

                  <Route path="/galeria/:id/:slug">
                    <GaleriaItem />
                  </Route>
                  <Route path="/album-de-fotos/:id/:slug">
                    <GaleriaItem />
                  </Route>

                  <Route>
                    {process.env.REACT_APP_ESTABELECIMENTO === "CBBC" ? (
                      <PageEmBreve />
                    ) : (
                      <PageNotFound />
                    )}
                  </Route>
                </Switch>

                <Footer siteMeta={siteMeta} siteMenu={siteMenu} />
                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  open={show}
                  onClose={handleClose}
                  message={`${notification.title} -  ${notification.body}`}
                  action={
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={handleClose}
                      style={{ outline: "none" }}
                    >
                      <CloseIconSVG fontSize="small" />
                    </IconButton>
                  }
                />
              </Router>
            </div>
          </MetaContext.Provider>
        </ThemeProvider>
      </ThemeSelector>
    </QueryClientProvider>
  );
}

export default App;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PesquisaParams() {
  let query = useQuery();
  return <PesquisaPage searchTerm={query.get("q")} />;
}

function NoticiasParams() {
  let query = useQuery();
  return <NoticiasPage queryParam={query.get("q")} page={query.get("p")} />;
}

function EventosParams() {
  let query = useQuery();
  return (
    <EventosPage
      queryParam={query.get("q")}
      page={query.get("p")}
      temporada={query.get("temporada")}
      modalidade={query.get("modalidade")}
      escopo={query.get("escopo")}
    />
  );
}
