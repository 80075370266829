import React from "react";
import { CircularProgressWithLabel } from "../../../components/CircularProgressWithLabel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "@mui/material";
import { convertDateYear } from "../../../util/convertDate";

export default function Header(props) {
  const { atleta, atletaPartida } = props;

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    autoplay: false,
    swipeToSlide: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const matchesLG = useMediaQuery("(min-width:1024px)");

  const renderHeader1 = () => {
    return (
      <div className="player-info__item player-info__item--photo">
        <figure className="player-info__photo">
          <img
            style={{
              maxWidth: 270,
              height: 400,
              objectFit: "cover",
              objectPosition: "center",
            }}
            src={
              atleta.loading || atleta.error
                ? ""
                : atleta.payload.atleta.urlFoto
            }
            alt=""
          />
        </figure>
      </div>
    );
  };
  const renderHeader2 = () => {
    return (
      <div className="player-info__item player-info__item--details">
        <div className="player-info__title player-info__title--desktop">
          <div className="player-info__number">
            {atletaPartida.loading || atletaPartida.error
              ? ""
              : atletaPartida.payload.items[0].num_camisa}
          </div>
          <h1 className="player-info__name">
            <span className="player-info__first-name">
              {atleta.loading || atleta.error ? "" : atleta.payload.firstName}
            </span>
            <span className="player-info__last-name">
              {atleta.loading || atleta.error ? "" : atleta.payload.lastName}
            </span>
          </h1>
        </div>

        <div className="player-info-details">
          <div className="player-info-details__item player-info-details__item--height">
            <h6 className="player-info-details__title">Altura</h6>
            <div className="player-info-details__value">-</div>
          </div>
          <div className="player-info-details__item player-info-details__item--weight">
            <h6 className="player-info-details__title">Peso</h6>
            <div className="player-info-details__value">-</div>
          </div>
          <div className="player-info-details__item player-info-details__item--age">
            <h6 className="player-info-details__title">Idade</h6>
            <div className="player-info-details__value">
              {atleta.loading || atleta.error
                ? "-"
                : convertDateYear(atleta.payload.atleta.data_nascimento)}
            </div>
          </div>
          <div className="player-info-details__item player-info-details__item--college">
            <h6 className="player-info-details__title">Clube</h6>
            <div className="player-info-details__value">
              {atleta.loading || atleta.error ? (
                "-"
              ) : (
                <React.Fragment>
                  {atleta.payload.atleta
                    ? atleta.payload.atleta.estabelecimento.descricao
                    : ""}
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="player-info-details__item player-info-details__item--born">
            <h6 className="player-info-details__title">Nacionalidade</h6>
            <div className="player-info-details__value">-</div>
          </div>
          <div className="player-info-details__item player-info-details__item--position">
            <h6 className="player-info-details__title">Posição</h6>
            <div className="player-info-details__value">-</div>
          </div>
          <div className="player-info-details__item player-info-details__item--foot">
            <h6 className="player-info-details__title">Lado Dom.</h6>
            <div className="player-info-details__value">
              {atleta.loading || atleta.error
                ? "-"
                : atleta.payload.lado_dominante}
            </div>
          </div>
        </div>

        <div className="player-info-stats pt-0">
          <div className="player-info-stats__item">
            <div className="player-info-details">
              <div className="player-info-details__item player-info-details__item--goals">
                <h6 className="player-info-details__title">T.Gols</h6>
                <div className="player-info-details__value">-</div>
              </div>
              <div className="player-info-details__item player-info-details__item--assists">
                <h6 className="player-info-details__title">T.Assist.</h6>
                <div className="player-info-details__value">-</div>
              </div>
              <div className="player-info-details__item player-info-details__item--games pb-0">
                <h6 className="player-info-details__title">Total partidas</h6>
                <div className="player-info-details__value">
                  {atletaPartida.loading || atletaPartida.error
                    ? "-"
                    : atletaPartida.payload._meta.totalCount}
                </div>
              </div>
            </div>
          </div>
          <div
            className="player-info-stats__item player-info-stats__item--top-padding"
            style={{ opacity: 0.3 }}
          >
            <div className="circular">
              <div className="circular__bar">
                <CircularProgressWithLabel
                  color="secondary"
                  value={88}
                  style={{ height: 62, width: 62 }}
                >
                  <span className="circular__percents">
                    1.21<small>avg</small>
                  </span>
                </CircularProgressWithLabel>
              </div>
              <span className="circular__label">
                <strong>Gols</strong>por jogo
              </span>
            </div>
          </div>
          <div
            className="player-info-stats__item player-info-stats__item--top-padding"
            style={{ opacity: 0.3 }}
          >
            <div className="circular">
              <div className="circular__bar">
                <CircularProgressWithLabel
                  color="secondary"
                  value={74}
                  style={{ height: 62, width: 62 }}
                >
                  <span className="circular__percents">
                    0.74<small>avg</small>
                  </span>
                </CircularProgressWithLabel>
              </div>
              <span className="circular__label">
                <strong>Assist.</strong>por jogo
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const renderHeader3 = () => {
  //     return (
  //         <div className="player-info__item player-info__item--stats" style={{ opacity: 0.3 }}>
  //             <div className="player-info__item--stats-inner">

  //                 <div className="progress-stats progress-stats--top-labels">
  //                     <div className="progress__label">Shot Accuracy</div>
  //                     <div className="progress">
  //                         <div className="progress__bar progress__bar--success progress__bar-width-90" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
  //                     </div>
  //                     <div className="progress__number">96%</div>
  //                 </div>

  //                 <div className="progress-stats progress-stats--top-labels">
  //                     <div className="progress__label">Pass Accuracy</div>
  //                     <div className="progress">
  //                         <div className="progress__bar progress__bar--success progress__bar-width-70" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
  //                     </div>
  //                     <div className="progress__number">74%</div>
  //                 </div>

  //                 <div className="progress-stats progress-stats--top-labels">
  //                     <div className="progress__label">Perfomance</div>
  //                     <div className="progress">
  //                         <div className="progress__bar progress__bar--success progress__bar-width-80" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
  //                     </div>
  //                     <div className="progress__number">85%</div>
  //                 </div>

  //                 <div className="progress-stats progress-stats--top-labels">
  //                     <div className="progress__label">Dribble</div>
  //                     <div className="progress">
  //                         <div className="progress__bar progress__bar--success progress__bar-width-60" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
  //                     </div>
  //                     <div className="progress__number">62%</div>
  //                 </div>

  //                 <div className="progress-stats progress-stats--top-labels">
  //                     <div className="progress__label">Stamina</div>
  //                     <div className="progress">
  //                         <div className="progress__bar progress__bar--success progress__bar-width-80" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
  //                     </div>
  //                     <div className="progress__number">80%</div>
  //                 </div>

  //             </div>
  //         </div>
  //     )
  // }

  return (
    <div className="player-heading">
      <div className="container">
        <div className="player-info__title player-info__title--mobile">
          <div className="player-info__number">
            {atletaPartida.loading || atletaPartida.error
              ? ""
              : atletaPartida.payload.items[0].num_camisa}{" "}
          </div>
          <h1 className="player-info__name">
            <span className="player-info__first-name">
              {atleta.loading || atleta.error ? "" : atleta.payload.firstName}
            </span>
            <span className="player-info__last-name">
              {atleta.loading || atleta.error ? "" : atleta.payload.lastName}
            </span>
          </h1>
        </div>
        <div className="player-info slick-initialized slick-slider">
          {matchesLG ? (
            <React.Fragment>
              {renderHeader1()}
              {renderHeader2()}
              {/* {renderHeader3()} */}
            </React.Fragment>
          ) : (
            <Slider {...settings}>
              {renderHeader1()}
              {renderHeader2()}
              {/* {renderHeader3()} */}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
}
