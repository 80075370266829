import React from "react";

const ThemeCBHB = React.lazy(() => import("../assets/themes/cbhb"));
const ThemeCBFS = React.lazy(() => import("../assets/themes/cbfutsal"));
const ThemeFED = React.lazy(() => import("../assets/themes/fedCBHb"));
const ThemeLigaole = React.lazy(() => import("../assets/themes/ligaole"));
const ThemeCBBC = React.lazy(() => import("../assets/themes/cbbc"));
const ThemeFABR = React.lazy(() => import("../assets/themes/fabr"));
const ThemeFPHAND = React.lazy(() => import("../assets/themes/fphand"));

const ThemeSelector = (props) => (
  <React.Fragment>
    <React.Suspense fallback={() => null}>
      {process.env.REACT_APP_TEMPLATE === "CBHB" ? <ThemeCBHB /> : null}
      {process.env.REACT_APP_TEMPLATE === "CBFutsal" ? <ThemeCBFS /> : null}
      {process.env.REACT_APP_TEMPLATE === "FEDCBHb" ? <ThemeFED /> : null}
      {process.env.REACT_APP_TEMPLATE === "Ligaole" ? <ThemeLigaole /> : null}
      {process.env.REACT_APP_TEMPLATE === "CBBC" ? <ThemeCBBC /> : null}
      {process.env.REACT_APP_TEMPLATE === "FABR" ? <ThemeFABR /> : null}
      {process.env.REACT_APP_TEMPLATE === "FPHAND" ? <ThemeFPHAND /> : null}
    </React.Suspense>
    {props.children}
  </React.Fragment>
);

export default ThemeSelector;
