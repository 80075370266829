import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import PageHeader from "../../components/PageHeader";
import { ConfedApi, portalApi } from "../../services/api";
import Classificacao from "../home/sections/Classificacao";
import JogoHeader from "../home/sections/JogoHeader";
import CalendarioJogos from "./sections/CalendarioJogos";
import EventoInfo from "./sections/EventoInfo";
import moment from "moment";
import MetaContext from "../../context/MetaContext";
import AnexosEvento from "./sections/AnexosEvento";

export default function EventoPage(props) {
  const { idEvento, slug } = useParams();
  const { siteMeta } = useContext(MetaContext);
  const [partidas, setPartidas] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  const [partidasGrupo, setPartidasGrupo] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  const breadcrumb = [
    { label: "HOME", url: "/", active: false },
    { label: "CAMPEONATOS", url: "/eventos", active: false },
  ];

  useEffect(() => {
    setPartidasGrupo({
      loading: true,
      error: false,
      payload: [],
    });
    async function getEventoGrupo() {
      await ConfedApi.get("evento-grupo", {
        params: {
          forcejoin: "eventoGrupoClubes,eventoClassificacaos",
          forcewhere: `evento_grupo.id_evento:${idEvento}`,
          pageSize: "1000",

          expand:
            "eventoGrupoClubes,eventoGrupoClubes.estabelecimento,eventoClassificacaos,eventoClassificacaos.idEstabelecimento,eventoPartidas,eventoPartidas.casa,eventoPartidas.visitante,eventoPartidas.placarPenaltiVisitante,eventoPartidas.placarPenaltiCasa,eventoClassificacaos.eventoPunicaoPontos,equipe",
        },
      })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setPartidasGrupo({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setPartidasGrupo({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setPartidasGrupo({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getEventoGrupo();
  }, [idEvento]);

  useEffect(() => {
    setPartidas({
      loading: true,
      error: false,
      payload: [],
    });
    async function getEventoPartida() {
      await ConfedApi.get("evento-partida", {
        params: {
          id_evento: idEvento,
          isNullField: "id_evento_grupo",
          forcejoin: "idEvento,casa,visitante",
          expand: "idEvento,casa,visitante",
          sort: "-data",
        },
      })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setPartidas({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setPartidas({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setPartidas({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getEventoPartida();
  }, [idEvento]);

  const [evento, setEvento] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setEvento({
      loading: true,
      error: false,
      payload: [],
    });
    async function getEvento() {
      await ConfedApi.get("evento", {
        params: {
          id: idEvento,
        },
      })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setEvento({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setEvento({
              loading: false,
              error: false,
              payload: response.data.items[0],
            });
          }
        })
        .catch((err) => {
          setEvento({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getEvento();
  }, [idEvento]);

  const [partidasDestaque, setPartidasDestaque] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  const [refreshPartidaDestaque, setRefreshpartidaDestaque] = useState(false);

  useEffect(() => {
    setPartidasDestaque({
      loading: true,
      error: false,
      payload: [],
    });

    async function getPartidasDestaque() {
      await ConfedApi.get("evento-partida", {
        params: {
          // flag_del: 0,
          id_evento: idEvento,
          forcewhere: `idEvento.id_estabelecimento:${process.env.REACT_APP_ID_ESTABELECIMENTO}`,
          forcejoin: "idEvento,casa,visitante",
          expand: "idEvento,casa,visitante",
          sort: "-data",
          lessField: "data",
          lessValue: `${moment(new Date()).format().substr(0, 10)} 23:59:59`,
          moreField: "data",
          moreValue: `${moment(new Date()).format().substr(0, 10)} 00:00:00`,
          pageSize: 10,
        },
      })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setPartidasDestaque({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setPartidasDestaque({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setPartidasDestaque({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getPartidasDestaque();

    if (refreshPartidaDestaque) {
      const interval = setInterval(getPartidasDestaque, 35000);
      return () => clearInterval(interval);
    }
  }, [idEvento, refreshPartidaDestaque]);

  useEffect(() => {
    if (partidasDestaque.payload.length) {
      setRefreshpartidaDestaque(true);
    }
  }, [partidasDestaque.payload]);

  const [artilheiros, setArtilheiros] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    if (idEvento) {
      setArtilheiros({
        loading: true,
        error: false,
        payload: [],
      });
      async function getArtilheiros() {
        await ConfedApi.get("evento/estatisticas-atleta-destaque", {
          params: {
            // flag_del: 0,
            id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
            idEvento: idEvento,
            sort: "colocacao",
            pageSize: 10,
          },
        })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setArtilheiros({
                loading: false,
                error: true,
                payload: [],
              });
            } else {
              setArtilheiros({
                loading: false,
                error: false,
                payload: response.data.items,
              });
            }
          })
          .catch((err) => {
            setArtilheiros({
              loading: false,
              error: true,
              payload: [],
            });
            console.log(err);
          });
      }
      getArtilheiros();
    }
  }, [idEvento]);

  const [relatorioEvento, setRelatorioEvento] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  useEffect(() => {
    if (idEvento) {
      setRelatorioEvento({ loading: true, error: false, payload: [] });
      async function getRelatorios() {
        await portalApi
          .get("relatorio-evento", {
            params: {
              id_evento: idEvento,
              flag_del: 0,
              // arrayIn: 'id_tipo:1|2|4|5|6|7|9',
              sort: "created_at",
            },
          })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setRelatorioEvento({ loading: false, error: true, payload: [] });
            } else {
              setRelatorioEvento({
                loading: false,
                error: false,
                payload: response.data.items,
              });
            }
          });
      }
      getRelatorios();
    }
  }, [idEvento]);

  return (
    <React.Fragment>
      {partidasDestaque.error ? (
        <PageHeader
          title={
            evento.payload.descricao ? evento.payload.descricao : "CAMPEONATOS"
          }
          breadcrumb={breadcrumb}
        />
      ) : (
        <JogoHeader partidas={partidasDestaque} />
      )}
      {evento.payload.descricao && (
        <Helmet>
          <title>
            {evento.payload.descricao} - {siteMeta.siteTitle}
          </title>
        </Helmet>
      )}
      <div className="site-content">
        <div className="container">
          <div className="row">
            <div className="content col-md-8">
              <CalendarioJogos
                partidas={partidas}
                grupos={partidasGrupo}
                idEvento={idEvento}
                slugEvento={slug}
              />
            </div>
            <div className="sidebar col-md-4">
              <EventoInfo
                evento={evento.payload}
                loading={evento.loading}
                partidasGrupoData={partidasGrupo.payload}
              />
              <AnexosEvento anexos={relatorioEvento} />
              <Classificacao
                tipoCard="grupos"
                groupClassificacao={partidasGrupo}
                artilheiros={artilheiros}
                idEvento={idEvento}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
