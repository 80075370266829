import React from "react";
import { Link } from "react-router-dom";
import { convertDate } from "../../../util/convertDate";
import { createSlug } from "../../../util/createSlug";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { MobileStepper } from "@mui/material";
import moment from "moment";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function JogoHeader(props) {
  const { partidas, pagePartida } = props;

  const testWinner = (pts1, pts2) => {
    if (pts1 > pts2) {
      return "alc-event-team--winner";
    } else {
      return "";
    }
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = partidas.payload.length;

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(maxSteps - 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const headerItem = (item) => {
    const isBefore = moment().isBefore(moment(item.data));

    return (
      <React.Fragment>
        <div className="alc-event-header__top">
          <div className="container">
            <Link
              to={`/evento/${item.idEvento.id}/${createSlug(
                item.idEvento.descricao
              )}`}
            >
              <h6 className="title">{item.idEvento.descricao}</h6>
            </Link>
            <span className="subtitle">{convertDate(item.data)}</span>
          </div>
        </div>
        <div className="alc-event-header__content">
          <div className="container">
            <div
              className="alc-event-header__content-inner"
              style={{ color: "#66686b" }}
            >
              <div
                className={
                  item.placar_final_visitante || item.placar_final_casa
                    ? "alc-event-competitors align-items-start align-items-sm-center"
                    : "alc-event-competitors"
                }
              >
                <div
                  className={`alc-event-competitors__item alc-event-team ${testWinner(
                    item.placar_final_casa,
                    item.placar_final_visitante
                  )}`}
                >
                  <div className="alc-event-team__logo-alt">
                    <div className="alc-event-team__logo-alt-inner">
                      <img
                        src={item.casa.urlLogo}
                        alt={item.casa.sigla_evento}
                        className="alc-event-team__logo-alt-img"
                      />
                    </div>
                  </div>
                  <div className="alc-event-team__details">
                    <Link
                      to={`/clube/${item.casa.id}/${createSlug(
                        item.casa.descricao
                      )}`}
                    >
                      <h4 className="alc-event-team__name">
                        {item.casa.sigla_evento || item.casa.sigla}
                      </h4>
                    </Link>
                    <div className="alc-event-team__info">{item.casa.uf}</div>
                  </div>
                  <figure
                    className={`alc-event-team__logo ${
                      process.env.REACT_APP_DOMAIN === "CBFutsal"
                        ? "d-lg-none"
                        : ""
                    }`}
                  >
                    <img src={item.casa.urlLogo} alt={item.casa.sigla_evento} />
                  </figure>
                  {isBefore ? null : (
                    <div className="alc-event-team__score-wrap">
                      <div className="alc-event-team__score">
                        {item.placar_final_casa ? item.placar_final_casa : 0}
                        {item.placarPenaltiCasa ? (
                          <small>
                            {item.placarPenaltiCasa !== "0"
                              ? `(${item.placarPenaltiCasa}`
                              : ""}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className={`alc-event-competitors__item alc-event-team ${testWinner(
                    item.placar_final_visitante,
                    item.placar_final_casa
                  )}`}
                >
                  <div className="alc-event-team__logo-alt alc-event-team__logo-alt--color-alt">
                    <div className="alc-event-team__logo-alt-inner">
                      <img
                        src={item.visitante.urlLogo}
                        alt={item.visitante.sigla_evento}
                        className="alc-event-team__logo-alt-img"
                      />
                    </div>
                  </div>
                  <div className="alc-event-team__details">
                    <Link
                      to={`/clube/${item.visitante.id}/${createSlug(
                        item.visitante.descricao
                      )}`}
                    >
                      <h4 className="alc-event-team__name">
                        {item.visitante.sigla_evento || item.visitante.sigla}
                      </h4>
                    </Link>
                    <div className="alc-event-team__info">
                      {item.visitante.uf}
                    </div>
                  </div>
                  <figure
                    className={`alc-event-team__logo ${
                      process.env.REACT_APP_DOMAIN === "CBFutsal"
                        ? "d-lg-none"
                        : ""
                    }`}
                  >
                    <img
                      src={item.visitante.urlLogo}
                      alt={item.visitante.sigla_evento}
                    />
                  </figure>

                  {isBefore ? null : (
                    <div className="alc-event-team__score-wrap">
                      <div className="alc-event-team__score">
                        {item.placarPenaltiVisitante ? (
                          <small>
                            {item.placarPenaltiVisitante !== "0"
                              ? `${item.placarPenaltiVisitante})`
                              : ""}
                          </small>
                        ) : (
                          ""
                        )}
                        {item.placar_final_visitante
                          ? item.placar_final_visitante
                          : 0}
                      </div>
                    </div>
                  )}
                </div>
                {!isBefore ? (
                  <React.Fragment>
                    {item.placarPenaltiVisitante !== "0" ? (
                      <React.Fragment>
                        <div
                          className="alc-event-competitors__divider d-md-none"
                          style={{
                            fontSize: "1rem",
                            alignItems: "center",
                            marginBottom: "4rem",
                            textAlign: "center",
                          }}
                        >
                          <div>x</div>
                        </div>
                        <div className="alc-event-competitors__divider d-none d-md-flex"></div>
                      </React.Fragment>
                    ) : (
                      <div className="alc-event-competitors__divider"></div>
                    )}
                  </React.Fragment>
                ) : (
                  <div className="alc-event-competitors__divider match-preview__vs">
                    <div className="match-preview__conj">VS</div>
                    <div className="match-preview__match-info">
                      <time
                        className="match-preview__match-time"
                        dateTime={item.data.substr(10, 10)}
                      >
                        {/* {item.data.substr(10, 6)} */}
                      </time>
                      {/* <div className="match-preview__match-place">
                        {item.local}
                      </div> */}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`match-preview__match-info d-flex ${
                  process.env.REACT_APP_DOMAIN === "CBFutsal" ? "" : "d-md-none"
                } justify-content-center mt-3`}
              >
                <div className=" text-center match-preview__match-place">
                  {item.local}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="alc-event-header__bottom" style={{ marginBottom: 1 }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6">
                <div
                  className="alc-event-header-performances"
                  style={{ fontSize: ".8rem" }}
                >
                  {moment(item.data).diff(moment(), "hours") < 0 &&
                  moment(item.data).diff(moment(), "days") > 0 ? (
                    <b
                      style={{
                        fontSize: "1.2rem",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {item.cronometro} - {item.tempo}
                    </b>
                  ) : (
                    <span className="text-center w-100">
                      {moment(item.data).diff(moment(), "days") < 0 ? (
                        <b>Partida finalizada</b>
                      ) : (
                        <b>Hoje {moment(item.data).format("HH:mm")} </b>
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="alc-event-header alc-event-header--layout-2">
        {partidas.loading && partidas.payload.length ? null : (
          <AutoPlaySwipeableViews
            axis="x"
            interval={29000}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {partidas.payload.map((item, key) => (
              <React.Fragment key={key}>
                {pagePartida ? (
                  headerItem(item)
                ) : (
                  <Link
                    to={`/evento/${item.idEvento.id}/${createSlug(
                      item.idEvento.descricao
                    )}/partida/${item.id}/`}
                  >
                    {headerItem(item)}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </AutoPlaySwipeableViews>
        )}

        {maxSteps === undefined || maxSteps === 1 ? (
          ""
        ) : (
          <div className="alc-event-header__bottom">
            <div className="container">
              <div className="stepper-wrapper">
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  nextButton={
                    <button
                      className="btn btn-xs btn-arrow btn-primary-inverse ml-2"
                      onClick={handleNext}
                      aria-label="Next"
                      type="button"
                      style={{
                        fontSize: ".9rem",
                        padding: "0.2rem 0.75em",
                        lineHeight: 1,
                      }}
                    >
                      <i className="fas fa-angle-right"></i>
                    </button>
                  }
                  backButton={
                    <button
                      className="btn btn-xs btn-arrow btn-primary-inverse mr-2"
                      onClick={handleBack}
                      aria-label="Previous"
                      type="button"
                      style={{
                        fontSize: ".9rem",
                        padding: "0.2rem 0.75em",
                        lineHeight: 1,
                      }}
                    >
                      <i className="fas fa-angle-left"></i>
                    </button>
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
