import cleber from "../../../assets/images/CBBC/governanca/cleber-dos-santos.webp";
import joseFernando from "../../../assets/images/CBBC/governanca/jose-fernando.webp";
import bruno from "../../../assets/images/CBBC/governanca/bruno-matzke2.webp";

export const conselhoFiscal = {
  descricao: "CONSELHO FISCAL - TITULARES",
  pessoas: [
    {
      nome: "JOSÉ FERNANDO",
      cargo: "Presidente do Conselho Fiscal",
      foto: joseFernando,
    },
    {
      nome: "CLÉBER DOS SANTOS",
      cargo: "Conselheiro Fiscal",
      foto: cleber,
    },
    {
      nome: "BRUNO D´ELIA MATZKE",
      cargo: "Conselheiro Fiscal",
      foto: bruno,
    },
  ],
};
