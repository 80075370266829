//  import TwitterIcon from '@material-ui/icons/Twitter';
// import WhatsAppIcon from '@material-ui/icons/WhatsApp';
// import PrintIcon from '@material-ui/icons/Print';
// import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import FacebookIcon from '@material-ui/icons/Facebook';

import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton
} from "react-share";
import { IconButton } from '@mui/material';
import React from "react";

function ShareLinks(props) {

    const shareUrl = props.shareUrl;

    return (
        <React.Fragment>
            <IconButton size="small" component={FacebookShareButton} url={shareUrl} aria-label="Facebook" style={{ color: '#3B5998', outline: 'none', padding: 5, fontSize: '1.25rem' }}>
                <i className="fab fa-facebook"></i>
            </IconButton>
            <IconButton size="small" component={TwitterShareButton} url={shareUrl} aria-label="Twitter" style={{ color: '#08a0e9', outline: 'none', padding: 5, fontSize: '1.25rem' }}>
                <i className="fab fa-twitter"></i>
            </IconButton>
            <IconButton size="small" component={WhatsappShareButton} url={shareUrl} aria-label="WhatsApp" style={{ color: '#25D366', outline: 'none', padding: 5, fontSize: '1.25rem' }}>
                <i className="fab fa-whatsapp"></i>
            </IconButton>
            <IconButton size="small" component={EmailShareButton} url={shareUrl} aria-label="Email" style={{ color: '#F39C12', outline: 'none', padding: 5, fontSize: '1.25rem' }}>
                <i className="far fa-envelope"></i>
            </IconButton>
        </React.Fragment>
    )
}

export default ShareLinks;