import _ from 'lodash';
import React from 'react'
import { Link } from 'react-router-dom';
import { convertDateTime } from '../../../util/convertDate';
import { createSlug } from '../../../util/createSlug';

export default function HistoricoJogos(props) {
    const { resultados, clubeId } = props

    const testCasaVisitante = (partida) => {
        let times = [partida.casa, partida.visitante]
        let timeAdversario = _.filter(times, function (o) { return o.id !== Number(clubeId) });
        let adversario = timeAdversario[0]

        let siglaResult = 'V'

        let ptVencedor = 0;
        let ptPerdedor = 0;

        if (partida.placar_final_casa > partida.placar_final_visitante) {
            if (adversario.id === partida.id_estabelecimento_casa) {
                siglaResult = 'D'
            }
            ptVencedor = partida.placar_final_casa
            ptPerdedor = partida.placar_final_visitante
        } else {
            ptVencedor = partida.placar_final_visitante
            ptPerdedor = partida.placar_final_casa
        }

        if (partida.placar_final_casa === partida.placar_final_visitante) {
            siglaResult = 'E'
        }

        return (
            <React.Fragment>
                <td className="game-player-result__vs">
                    <Link to={`/clube/${adversario.id}/${createSlug(adversario.descricao)}`}>
                        <div className="team-meta">
                            <figure className="team-meta__logo">
                                <img src={adversario.urlLogo} alt={adversario.sigla_evento} />
                            </figure>
                            <div className="team-meta__info">
                                <h6 className="team-meta__name">{adversario.sigla_evento}</h6>
                                <span className="team-meta__place" style={{ whiteSpace: 'normal' }}>{adversario.descricao}</span>
                            </div>
                        </div>
                    </Link>
                </td>
                <td className="game-player-result__score">
                    <span className="game-player-result__game">{siglaResult}</span> {ptVencedor}-{ptPerdedor}
                </td>
            </React.Fragment>

        )
    }

    return (
        <div className="widget card card--has-table">
            <div className="card__header card__header--has-btn">
                <h4>Histórico de jogos</h4>
                {/* <a href="_soccer_player-stats.html" className="btn btn-default btn-outline btn-xs card-header__button">See complete games log</a> */}
            </div>
            <div className="card__content">
                <div className="table-responsive">
                    <table className="table table-hover game-player-result">
                        <thead>
                            <tr>
                                <th className="game-player-result__date">Data</th>
                                <th className="game-player-result__vs">Adversário</th>
                                <th className="game-player-result__score">Resultado</th>

                            </tr>
                        </thead>
                        <tbody>
                            {resultados.loading || resultados.error ? '' :
                                resultados.payload.map((item, key) => (
                                    <tr key={key}>
                                        <td className="game-player-result__date">
                                            {convertDateTime(item.data)}
                                        </td>
                                        {testCasaVisitante(item)}

                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
