import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { governancaApi } from '../../services/api';
import ReactHtmlParser from 'react-html-parser';
import MetaContext from '../../context/MetaContext';

export default function EventosOrgao(props) {
    const { siteMeta } = useContext(MetaContext)

    
    const { id_menu } = useParams();

    const [menuOrgao, setMenuOrgao] = useState({
        loading: true,
        error: false,
        payload: []
    });

    const breadcrumb = [
        { label: 'HOME', url: '/', active: false },
        { label: 'Eventos', url: '/eventos', active: false },
    ]

    useEffect(() => {
        if (id_menu) {
            setMenuOrgao({
                loading: true,
                error: false,
                payload: []
            })
            async function getMenuOrgao() {
                await governancaApi.get('menu-governanca', {
                    params: {
                        id: id_menu,
                        expand: 'filho,filho.orgao',
                        sort:'created_at'
                    },
                }).then((response) => {
                    if (response.data._meta.totalCount === 0) {
                        setMenuOrgao({
                            loading: false,
                            error: true,
                            payload: []
                        })
                    } else {
                        setMenuOrgao({
                            loading: false,
                            error: false,
                            payload: response.data.items[0]
                        })
                    }
                });
            }
            getMenuOrgao();
        }
    }, [id_menu])

    return (
        <div style={{ minHeight: '100vh' }}>
            <Helmet>
                <title>{menuOrgao.loading || menuOrgao.error ? 'Eventos' : menuOrgao.payload.titulo} - {siteMeta.siteTitle}</title>
            </Helmet>

            <PageHeader title={menuOrgao.loading || menuOrgao.error ? 'Eventos' : menuOrgao.payload.titulo} breadcrumb={breadcrumb} />

            <div className="site-content" >
                <div className="container">
                    {menuOrgao.loading || menuOrgao.error || !menuOrgao.payload.filho ?
                        ''
                        :
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div>
                                    {menuOrgao.payload.filho[0] ? ReactHtmlParser(menuOrgao.payload.filho[0].orgao.corpo) : ''}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}
