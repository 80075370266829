import React from "react";
import { Link } from "react-router-dom";
import { convertDate, convertISODateString } from "../../../util/convertDate";
import { createSlug } from "../../../util/createSlug";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Timer from "./Timer";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function ProximaPartida(props) {
  const { partidas } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = partidas.payload.length;

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(maxSteps - 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <aside className="widget widget--sidebar card widget-preview">
      <div className="widget__title card__header">
        <h4>Próximas partidas</h4>
      </div>
      <div className="widget__content card__content">
        {partidas.loading ||
        partidas.error ||
        !partidas.payload.length ? null : (
          <div className="match-preview match-preview--alt">
            <AutoPlaySwipeableViews
              axis="x"
              interval={9000}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {partidas.payload.map((item, key) => (
                <section className="match-preview__body p-1" key={key}>
                  <header className="match-preview__header">
                    <Link
                      to={`/evento/${item.idEvento.id}/${createSlug(
                        item.idEvento.descricao
                      )}`}
                    >
                      <h3 className="match-preview__title">
                        {item.idEvento.descricao}
                      </h3>
                    </Link>
                    <time
                      className="match-preview__date"
                      dateTime={convertISODateString(item.data)}
                    >
                      {item.fase} - {convertDate(item.data)}
                    </time>
                  </header>
                  <div className="match-preview__content">
                    <div className="match-preview__team match-preview__team--first">
                      <figure className="match-preview__team-logo">
                        <img
                          src={item.casa.urlLogo}
                          alt={item.casa.sigla_evento}
                        />
                      </figure>
                      <div className="match-prevew__team-details">
                        <Link
                          to={`/clube/${item.casa.id}/${createSlug(
                            item.casa.descricao
                          )}`}
                        >
                          <h5 className="match-preview__team-name">
                            {item.casa.descricao}
                          </h5>
                        </Link>
                        <div className="match-preview__team-info">
                          {item.casa.uf}
                        </div>
                      </div>
                    </div>
                    <div className="match-preview__vs">
                      <div className="match-preview__conj">VS</div>
                    </div>
                    <div className="match-preview__team match-preview__team--second">
                      <figure className="match-preview__team-logo">
                        <img
                          src={item.visitante.urlLogo}
                          alt={item.visitante.sigla_evento}
                        />
                      </figure>
                      <div className="match-prevew__team-details">
                        <Link
                          to={`/clube/${item.visitante.id}/${createSlug(
                            item.visitante.descricao
                          )}`}
                        >
                          <h5 className="match-preview__team-name">
                            {item.visitante.descricao}
                          </h5>
                        </Link>
                        <div className="match-preview__team-info">
                          {item.visitante.uf}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="match-preview__action">
                    <div className="match-preview__match-info">
                      <time
                        className="match-preview__match-time"
                        dateTime={item.data}
                      >
                        {item.data.substr(10, 6)}
                      </time>
                      <div className="match-preview__match-place">
                        {item.local}
                      </div>
                    </div>
                    {/* <div className="match-preview__action-btn">
                                            <a href="#" className="btn btn-default btn-block">Buy Tickets Now</a>
                                        </div> */}
                  </div>
                </section>
              ))}
            </AutoPlaySwipeableViews>
            <section className="match-preview__countdown countdown">
              <h4 className="countdown__title">Contagem regressiva</h4>
              <AutoPlaySwipeableViews
                axis="x"
                interval={9000}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {partidas.payload.map((item, key) => (
                  <Timer key={key} dateTime={item.data} />
                ))}
              </AutoPlaySwipeableViews>
            </section>
            {maxSteps === 1 ? (
              ""
            ) : (
              <div className="countdown-arrows d-flex justify-content-center mt-2">
                <button
                  className="slick-prev slick-arrow mr-2"
                  onClick={handleBack}
                  aria-label="Previous"
                  type="button"
                >
                  Previous
                </button>
                <button
                  className="slick-next slick-arrow ml-2"
                  onClick={handleNext}
                  aria-label="Next"
                  type="button"
                >
                  Next
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </aside>
  );
}
