import React, { useEffect, useState } from "react";
import postImg from "../assets/images/samples/post-img3-xs.jpg";
import {
  IconSoccerBallSVG,
  MailEnvelopeSVG,
} from "../assets/svg/TemplateIcons";
import { Link } from "react-router-dom";
import { governancaApi, portalApi } from "../services/api";
import { createSlug } from "../util/createSlug";
import { convertDateTime, convertISODateString } from "../util/convertDate";
import ouvidoriaFields from "../assets/data/ouvidoriaFields";
import geraProtocolo from "../util/geraProtocolo";
import ReCAPTCHA from "react-google-recaptcha";
import PatrocinadoresLPF from "./PatrocinadoresLPF";
import PatrocinadoresCBBC from "./PatrocinadoresCBBC";
import PatrocinadoresCBHB from "./PatrocinadoresCBHB";

const recaptchaRef = React.createRef();

export default function Footer(props) {
  const { siteMeta, siteMenu } = props;

  const [noticias, setNoticias] = useState({});

  useEffect(() => {
    setNoticias({
      loading: true,
      error: false,
      payload: [],
    });
    async function getNoticias() {
      await portalApi
        .get("noticia", {
          params: {
            flag_del: 0,
            flag_publicado: 1,
            id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
            expand: "idEsporte,user,idPessoa",
            notNullField: "foto_capa",
            sort: "-created_at",
            lessField: "created_at",
            lessValue: new Date().getTime().toString().substr(0, 10),
            pageSize: 3,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setNoticias({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setNoticias({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setNoticias({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getNoticias();
  }, []);

  const [noticiasDestaque, setNoticiasDestaque] = useState({});

  useEffect(() => {
    setNoticiasDestaque({
      loading: true,
      error: false,
      payload: [],
    });
    async function getNoticias() {
      await portalApi
        .get("noticia", {
          params: {
            flag_del: 0,
            flag_publicado: 1,
            id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
            expand: "idEsporte,user,idPessoa",
            notNullField: "foto_capa",
            sort: "-acessos",
            lessField: "created_at",
            lessValue: new Date().getTime().toString().substr(0, 10),
            pageSize: 3,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setNoticiasDestaque({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setNoticiasDestaque({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setNoticiasDestaque({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getNoticias();
  }, []);

  const imgPostFallback = (e) => {
    e.target.src = postImg;
  };

  const ListNoticias = (_noticias) => {
    return (
      <ul className="posts posts--simple-list">
        {_noticias.loading || _noticias.error || !_noticias.payload ? (
          ""
        ) : (
          <React.Fragment>
            {_noticias.payload.map((item, key) => (
              <li
                className={`posts__item posts__item--category-${item.id_esporte}`}
                key={key}
              >
                <figure className="posts__thumb posts__thumb--hover">
                  <Link
                    to={`/noticias/leitura/${item.id}/${createSlug(
                      item.titulo
                    )}`}
                  >
                    {_noticias.loading || _noticias.error ? (
                      <img src={postImg} alt="" />
                    ) : (
                      <img
                        src={
                          item.foto_capa
                            ? `${process.env.REACT_APP_SGE_URL}_uploads/${process.env.REACT_APP_ID_ESTABELECIMENTO}/noticias/${item.foto_capa}`
                            : ""
                        }
                        onError={(e) => imgPostFallback(e)}
                        alt=""
                      />
                    )}
                  </Link>
                </figure>
                <div className="posts__inner">
                  <div className="posts__cat">
                    <span className="label posts__cat-label">
                      {item.idEsporte ? item.idEsporte.descricao : ""}
                    </span>
                  </div>
                  <h6 className="posts__title posts__title--color-hover">
                    <Link
                      to={`/noticias/leitura/${item.id}/${createSlug(
                        item.titulo
                      )}`}
                    >
                      {_noticias.loading ? "" : item.titulo}
                    </Link>
                  </h6>
                  <time
                    dateTime={convertISODateString(item.created_at * 1000)}
                    className="posts__date"
                  >
                    {convertDateTime(item.created_at * 1000)}
                  </time>
                </div>
              </li>
            ))}
          </React.Fragment>
        )}
      </ul>
    );
  };

  // contato form
  const [formValues, setFormValues] = useState(ouvidoriaFields);

  const [protocolo, setProtocolo] = useState("");
  const [storedCaptchaToken, setStoredCaptchaToken] = useState("");

  const handleChangeValues = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const onSubmitWithReCAPTCHA = async (event) => {
    event.preventDefault();
    let token = await recaptchaRef.current.executeAsync();

    if (token) {
      if (token === storedCaptchaToken) {
        alert("Robô detectado");
      } else {
        var protocolo = geraProtocolo();
        var datenow = Math.round(new Date() / 1000);

        setFormValues({
          ...formValues,
          nome_solicitante: "anônimo",
          telefone: "",
          protocolo: protocolo,
          updated_at: datenow,
          created_at: datenow,
        });

        setProtocolo(protocolo);
        setStoredCaptchaToken(token);
      }
    } else {
      alert("Valide o captcha para enviar a mensagem!");
    }
  };

  useEffect(() => {
    if (formValues.created_at !== "" && storedCaptchaToken !== "") {
      async function postFornecedor() {
        await governancaApi
          .post("solicitacao-ouvidoria", formValues)
          .then((response) => {
            setFormValues({
              ...formValues,
              nome_solicitante: "",
              telefone: "",
              email: "",
              motivo: "",
              mensagem: "",
              created_at: "",
              updated_at: "",
              created_by: "",
              updated_by: "",
            });
            alert(`Mensagem enviada com sucesso!\nprotocolo:${protocolo}`);
          })
          .catch((err) => {
            console.error(err);
            setFormValues({
              ...formValues,
              created_at: "",
              updated_at: "",
            });
            setProtocolo("");
          });
      }
      postFornecedor();
    }
  }, [formValues.created_at, formValues, protocolo, storedCaptchaToken]);

  return (
    <footer id="footer" className="footer">
      <div className="footer-widgets">
        {process.env.REACT_APP_ESTABELECIMENTO === "CBBC" ? (
          <PatrocinadoresCBBC />
        ) : (
          ""
        )}

        <div className="footer-widgets__inner">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                <div className="footer-col-inner">
                  <div className="footer-logo footer-logo--has-txt">
                    <Link to="/">
                      <img
                        src={siteMeta.logoTexto}
                        alt={siteMeta.descricao_estabelecimento}
                        className="footer-logo__img"
                      />
                      {process.env.REACT_APP_DOMAIN === "CBFutsal" ? (
                        ""
                      ) : (
                        <div className="footer-logo__heading">
                          <h5 className="footer-logo__txt">
                            {siteMeta.siteTitle}
                          </h5>
                        </div>
                      )}
                    </Link>
                  </div>

                  <div className="widget widget--footer widget-contact-info">
                    <div className="widget__content">
                      <div className="widget-contact-info__desc">
                        <p>{siteMeta.siteEndereco}</p>
                        <p>{siteMeta.siteTelefones}</p>
                        <p>{siteMeta.siteFuncionamento}</p>
                      </div>
                      <div className="widget-contact-info__body info-block">
                        <div className="info-block__item">
                          <Link to="/contato">
                            {" "}
                            <MailEnvelopeSVG className="df-icon df-icon--mail" />
                          </Link>
                          <h6 className="info-block__heading">
                            <Link to="/contato">Contato</Link>
                          </h6>
                          <a
                            className="info-block__link"
                            href={`mailto:${siteMeta.emailContato}`}
                          >
                            {siteMeta.emailContato}
                          </a>
                        </div>
                        {process.env.REACT_APP_ESTABELECIMENTO === "CBBC" ? (
                          ""
                        ) : (
                          <div className="info-block__item">
                            <IconSoccerBallSVG className="df-icon df-icon--soccerball" />
                            <h6 className="info-block__heading">
                              ENCONTRE UM TIME!
                            </h6>
                          </div>
                        )}
                        <div className="info-block__item info-block__item--nopadding">
                          <ul className="social-links">
                            {siteMeta.redesSociais.map((item, key) => (
                              <li className="social-links__item" key={key}>
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="social-links__link"
                                >
                                  <i className={`fab fa-${item.name}`}></i>{" "}
                                  <span>{item.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="footer-col-inner">
                  <div className="widget widget--footer widget-featured-posts">
                    <h4 className="widget__title">Notícias mais visitadas</h4>
                    <div className="widget__content">
                      {ListNoticias(noticiasDestaque)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix visible-sm"></div>
              <div className="col-sm-6 col-lg-3">
                <div className="footer-col-inner">
                  <div className="widget widget--footer widget-popular-posts">
                    <h4 className="widget__title">Últimas notícias</h4>
                    <div className="widget__content">
                      {ListNoticias(noticias)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                <div className="footer-col-inner">
                  <div className="widget widget--footer widget-contact">
                    <h4 className="widget__title">FORMULÁRIO DE CONTATO</h4>
                    <div className="widget__content">
                      {process.env.REACT_APP_ESTABELECIMENTO === "CBBC" ? (
                        ""
                      ) : (
                        <p>
                          Utilize nosso formulário de contato e entre em contato
                          com a {siteMeta.nomeFooter}!
                        </p>
                      )}
                      <form
                        onSubmit={onSubmitWithReCAPTCHA}
                        className="contact-form"
                      >
                        <ReCAPTCHA
                          hl="pt"
                          ref={recaptchaRef}
                          sitekey={
                            process.env.REACT_APP_INVISIBLE_RECAPTCHA_SITE_KEY
                          }
                          size="invisible"
                        />
                        <div className="form-group form-group--xs">
                          <input
                            value={formValues.email}
                            required
                            type="email"
                            name="email"
                            onChange={handleChangeValues}
                            className="form-control input-sm"
                            id="contact-form-email"
                            placeholder="Email"
                          />
                        </div>
                        <div className="form-group form-group--xs">
                          <textarea
                            className="form-control input-sm"
                            value={formValues.mensagem}
                            required
                            name="mensagem"
                            onChange={handleChangeValues}
                            rows="4"
                            placeholder="Sua mensagem..."
                          ></textarea>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary-inverse btn-sm btn-block"
                        >
                          Enviar mensagem
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          {process.env.REACT_APP_ESTABELECIMENTO === "CBFutsal" ? (
            <PatrocinadoresLPF />
          ) : (
            ""
          )}
          {process.env.REACT_APP_ESTABELECIMENTO === "CBHB" ? (
            <PatrocinadoresCBHB />
          ) : (
            ""
          )}
          <div className="sponsors">
            <ul className="sponsors-logos">
              <li className="sponsors__item" style={{ width: "100%" }}>
                <a href="https://bigmidia.com" target="_blank" rel="noreferrer">
                  <img
                    src={siteMeta.logoBigmidia}
                    alt=""
                    style={{
                      height: "40px !important",
                      width: "140px !important",
                      maxWidth: "unset",
                    }}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-secondary">
        <div className="container">
          <div className="footer-secondary__inner">
            <div className="row">
              <div className="col-md-4">
                <div className="footer-copyright">
                  <a
                    href={process.env.REACT_APP_SGE_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    SGE{" "}
                  </a>{" "}
                  2021 &nbsp; | &nbsp; TODOS OS DIREITOS RESERVADOS
                </div>
              </div>
              <div className="col-md-8">
                <ul className="footer-nav footer-nav--right footer-nav--condensed footer-nav--sm">
                  <li className="footer-nav__item">
                    <Link to="/">Home</Link>
                  </li>
                  {siteMenu.map((item, key) => (
                    <li key={key} className="footer-nav__item">
                      <Link to={item.url}>{item.label}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
