import React, { useContext, useMemo } from "react";
import { formatDatePeriod } from "../../../util/formatDatePeriod";
import ReactHtmlParser from "react-html-parser";
import { convertDateTime } from "../../../util/convertDate";
import { Avatar, Skeleton } from "@mui/material";
import MetaContext from "../../../context/MetaContext";
import { fetchEventoPartidaArbitro } from "../../../hooks/getEventoPartidaArbitro";
import { useQuery } from "@tanstack/react-query";

export default function EventoInfo(props) {
  const { evento, loading, tipoPartida, partida, partidasGrupoData } = props;

  const infosEvento = useMemo(() => {
    if (partidasGrupoData && partidasGrupoData.length) {
      // let pontos = partidasGrupoData[0].eventoClassificacaos.map(
      //   (item) => item.num_pontos
      // );

      // let totalPontos = reduce(
      //   pontos,
      //   function (sum, n) {
      //     return sum + n;
      //   },
      //   0
      // );

      return {
        // totalPontos,
        totalClubes: partidasGrupoData[0].eventoGrupoClubes.length,
        totalPartidas: partidasGrupoData[0].eventoPartidas.length,
      };
    }
  }, [partidasGrupoData]);

  const {
    data: arbitroData,
    isSuccess: isSuccessPartidaArbitro,
    isLoading: isLoadingPartidaArbitro,
  } = useQuery(["eventoPartidaArbitro"], () =>
    fetchEventoPartidaArbitro({ idPartida: partida.id })
  );

  const { siteMeta } = useContext(MetaContext);
  const imgEventoFallback = (e) => {
    e.target.src = siteMeta.logo;
    e.target.className = "disabled";
  };

  const renderTableArbitragem = (arbitragem) => {
    return arbitragem.map((item, key) => (
      <tr key={key}>
        <td className="alc-align-start">
          <div className="alc-player-info">
            <Avatar
              style={{ height: 26, width: 26, marginRight: 14 }}
              src={`${process.env.REACT_APP_SGE_URL}_uploads/fotoPessoa/${item.tb_pessoa.foto}`}
            />
            <div className="alc-player-info__name text-uppercase">
              {item.tb_pessoa.nome_completo}
            </div>
          </div>
        </td>
        <td className="alc-number">
          {item.funcao.descricao ? item.funcao.descricao : ""}
        </td>
      </tr>
    ));
  };
  return (
    <aside className="widget card widget--sidebar widget-player widget-player--alt">
      <div className="widget__title card__header">
        <h4>
          {loading ? (
            <Skeleton animation="wave" variant="text" />
          ) : (
            evento?.descricao
          )}
        </h4>
      </div>
      <ul className="alc-event-info list-unstyled">
        <div style={{ minHeight: 180 }}>
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={180}
              width="100%"
            />
          ) : (
            <figure className="text-center py-3">
              <img
                src={`${process.env.REACT_APP_SGE_URL}/_uploads/relatorioEvento/${evento?.arte_evento}`}
                alt={evento?.descricao}
                onError={(e) => imgEventoFallback(e)}
              />
            </figure>
          )}
        </div>
        <li className="alc-event-info__item">
          <span className="alc-event-info__icon">
            <i className="icon-svg icon-map-pin"></i>
          </span>
          <span className="alc-event-info__value" style={{ lineHeight: 2 }}>
            {loading ? (
              <Skeleton animation="wave" variant="text" />
            ) : (
              <React.Fragment>
                {tipoPartida ? partida.local : evento.local}
              </React.Fragment>
            )}
          </span>
        </li>
        <li className="alc-event-info__item">
          <span className="alc-event-info__icon">
            <i className="icon-svg icon-calendar"></i>
          </span>
          <span className="alc-event-info__value">
            {loading ? (
              <Skeleton animation="wave" variant="text" />
            ) : (
              <React.Fragment>
                {tipoPartida
                  ? convertDateTime(partida.data)
                  : formatDatePeriod(evento.data_inicio, evento.data_fim)}
              </React.Fragment>
            )}
          </span>
        </li>
        {isLoadingPartidaArbitro || !isSuccessPartidaArbitro ? (
          <React.Fragment></React.Fragment>
        ) : (
          <React.Fragment>
            <li className="alc-event-info__item alc-event-info__item--desc pb-0 ">
              <h6 className="mb-0">Árbitros/Oficiais</h6>
            </li>
            <li className="alc-event-info__item alc-event-info__item--desc px-2 pt-0 border-0">
              <div className="table-responsive">
                <table className="table alc-table-stats alc-table-stats--compact-sm">
                  <thead>
                    <tr>
                      <th style={{ minWidth: 200 }}>Oficial</th>
                      <th>Função</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableArbitragem(arbitroData)}</tbody>
                </table>
              </div>
            </li>
          </React.Fragment>
        )}

        <li className="alc-event-info__item alc-event-info__item--desc">
          <span className="alc-event-info__label">Informações adicionais:</span>
          <span className="alc-event-info__desc">
            {evento ? ReactHtmlParser(evento.texto) : null}
          </span>
        </li>
      </ul>
      <div className="widget__content-secondary">
        <div className="widget-player__details">
          {tipoPartida ? null : (
            <React.Fragment>
              <div className="widget-player__details__item">
                <div className="widget-player__details-desc-wrapper">
                  <span className="widget-player__details-holder">
                    <span className="widget-player__details-label">
                      {" "}
                      Nº Partidas
                    </span>
                    <span className="widget-player__details-desc">
                      Todas as fases
                    </span>
                  </span>
                  <span className="widget-player__details-value">
                    {infosEvento && infosEvento.totalPartidas
                      ? infosEvento.totalPartidas
                      : null}
                  </span>
                </div>
              </div>
              <div className="widget-player__details__item">
                <div className="widget-player__details-desc-wrapper">
                  <span className="widget-player__details-holder">
                    <span className="widget-player__details-label">
                      Nº Equipes
                    </span>
                    <span className="widget-player__details-desc">
                      Times participantes
                    </span>
                  </span>
                  <span className="widget-player__details-value">
                    {infosEvento && infosEvento.totalClubes
                      ? infosEvento.totalClubes
                      : null}
                  </span>
                </div>
              </div>
            </React.Fragment>
          )}
          <div className="widget-player__details__item">
            <div className="widget-player__details-desc-wrapper">
              <span className="widget-player__details-holder">
                <span className="widget-player__details-label">Nº Atletas</span>
                <span className="widget-player__details-desc">Em Súmulas</span>
              </span>
              <span className="widget-player__details-value">
                {loading ? <Skeleton animation="wave" variant="text" /> : "-"}
              </span>
            </div>
          </div>
          <div className="widget-player__details__item">
            <div className="widget-player__details-desc-wrapper">
              <span className="widget-player__details-holder">
                <span className="widget-player__details-label">
                  Total de Staff
                </span>
                <span className="widget-player__details-desc">Escalados</span>
              </span>
              <span className="widget-player__details-value"></span>
            </div>
          </div>
          {/* {tipoPartida ? null : (
            <div className="widget-player__details__item">
              <div className="widget-player__details-desc-wrapper">
                <span className="widget-player__details-holder">
                  <span className="widget-player__details-label">
                    Total de Pontos
                  </span>
                  <span className="widget-player__details-desc"></span>
                </span>
                <span className="widget-player__details-value">
                  {infosEvento && infosEvento.totalPontos
                    ? infosEvento.totalPontos
                    : null}
                </span>
              </div>
            </div>
          )} */}
        </div>
      </div>
      <div className="widget__content-tertiary widget__content--bottom-decor">
        <div className="widget__content-inner"></div>
      </div>
    </aside>
  );
}
