import { Pagination, PaginationItem } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CardNoticiaV1 from "../../../components/CardNoticiaV1";
import { portalApi } from "../../../services/api";

export default function PesquisaNoticias(props) {
  const { searchTerm } = props;
  const [page, setPage] = useState(1);
  const [noticias, setNoticias] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalRes, setTotalRes] = useState(0);

  const sectionTop = useRef(null);

  useEffect(() => {
    setNoticias({
      loading: true,
      error: false,
      payload: [],
    });
    setTotalPages(0);

    if (searchTerm) {
      async function getNoticia() {
        await portalApi
          .get("noticia", {
            params: {
              flag_del: 0,
              flag_publicado: 1,
              id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
              arrayLike: `titulo:${searchTerm},corpo:${searchTerm}`,
              expand: "idEsporte,user,idPessoa",
              sort: "-created_at",
              pageSize: 9,
              page: page,
              lessField: "created_at",
              lessValue: new Date().getTime().toString().substr(0, 10),
            },
          })
          .then((response) => {
            if (page > 1) {
              executeScroll();
            }
            if (response.data._meta.totalCount === 0) {
              setNoticias({
                loading: false,
                error: true,
                payload: [],
              });
              setTotalPages(0);
            } else {
              setNoticias({
                loading: false,
                error: false,
                payload: response.data.items,
              });
              setTotalPages(response.data._meta.pageCount);
              setTotalRes(response.data._meta.totalCount);
            }
          });
      }
      getNoticia();
    }
  }, [searchTerm, page]);

  const handleChange = (event, value) => {
    setPage(value);
    if (value > 1) {
      executeScroll();
    }
  };

  const executeScroll = () =>
    window.scrollTo({
      behavior: "smooth",
      top: sectionTop.current.offsetTop - 200,
    });

  return (
    <React.Fragment>
      <div ref={sectionTop}></div>
      {noticias.error ? (
        ""
      ) : (
        <React.Fragment>
          <div
            className={`alert ${
              noticias.error ? "alert-danger" : "alert-success"
            } `}
          >
            <strong>
              {totalRes}{" "}
              {totalRes > 1
                ? "Notícias foram encontradas"
                : " Notícia foi encontrada"}{" "}
              para "<span className="text-success">{searchTerm}</span>"
            </strong>
          </div>

          <div className="posts posts--cards post-grid post-grid--masonry row">
            {noticias.loading || noticias.error || !noticias.payload ? (
              ""
            ) : (
              <React.Fragment>
                {noticias.payload.map((item, key) => (
                  <div className="post-grid__item col-md-6 col-lg-4" key={key}>
                    <CardNoticiaV1 noticia={item} />
                  </div>
                ))}
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      )}
      {totalPages > 1 ? (
        <div>
          <Pagination
            className="pagination pagination--condensed pagination--lg justify-content-center"
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            page={page}
            count={totalPages}
            renderItem={(item) => {
              return <PaginationItem className="page-link" {...item} />;
            }}
          />
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
}
