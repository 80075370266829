import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import RedesSociaisAside from '../../components/RedesSociaisAside'
import { portalApi } from '../../services/api';
import CardNoticia from './sections/CardNoticia'
import CardNoticiasRecentes from './sections/CardNoticiasRecentes'

export default function NoticiaLeituraPage() {

    const { id } = useParams();

    const [noticia, setNoticia] = useState({
        loading: true,
        error: false,
        payload: []
    });

    useEffect(() => {
        if (id) {
            setNoticia({
                loading: true,
                error: false,
                payload: []
            })
            async function getNoticia() {
                await portalApi.get('noticia', {
                    params: {
                        id: id,
                        expand: 'idEsporte,user,idPessoa',
                    },
                }).then((response) => {
                    if (response.data._meta.totalCount === 0) {
                        setNoticia({
                            loading: false,
                            error: true,
                            payload: []
                        })
                    } else {
                        setNoticia({
                            loading: false,
                            error: false,
                            payload: response.data.items[0]
                        })
                    }
                });
            }
            getNoticia();
        }
    }, [id])

    const [noticiaRecentes, setNoticiasRecentes] = useState({
        loading: true,
        error: false,
        payload: []
    });

    useEffect(() => {
        setNoticiasRecentes({
            loading: true,
            error: false,
            payload: []
        })
        async function getNoticia() {
            await portalApi.get('noticia', {
                params: {
                    flag_del: 0,
                    flag_publicado: 1,
                    id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
                    expand: 'idEsporte',
                    notNullField: 'foto_capa',
                    sort: '-created_at',
                    lessField: 'created_at',
                    lessValue: (new Date()).getTime().toString().substr(0, 10),
                    pageSize: 4
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setNoticiasRecentes({
                        loading: false,
                        error: true,
                        payload: []
                    })
                } else {
                    setNoticiasRecentes({
                        loading: false,
                        error: false,
                        payload: response.data.items
                    })
                }
            });
        }
        getNoticia();
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${noticia.payload.titulo ? noticia.payload.titulo + ' - ' : ''} ${process.env.REACT_APP_DOMAIN}`}</title>
            </Helmet>
            <div className="site-content">
                <div className="container">
                    <div className="row">
                        <div className="content col-lg-8">
                            <CardNoticia noticia={noticia} />
                        </div>
                        <div className="sidebar col-lg-4">
                            <RedesSociaisAside horizontal />
                            <CardNoticiasRecentes noticias={noticiaRecentes} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
