import React, { useEffect, useState } from 'react'

export default function Timer({ dateTime }) {

    const calculateTimeLeft = () => {
        // let year = new Date().getFullYear();
        const difference = +new Date(dateTime) - +new Date();

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    const timerComponents = [];


    Object.keys(timeLeft).forEach((interval) => {
        // timerComponents.push(
        //     <span>
        //         {timeLeft[interval]} {interval}{" "}
        //     </span>
        // ); 

        timerComponents.push({
            interval: interval,
            value: timeLeft[interval]
        })
    });

    const getTranslatedInterval = (interval) => {
        switch (interval) {
            case 'days':
                return 'dias'; 
            case 'hours':
                return 'horas'; 
            case 'minutes':
                return 'min'; 
            case 'seconds':
                return 'seg'; 
            default:
                break;
        }
    }

    return (
        <div>
            <div className="countdown__content">
                {/* <><div className="countdown-counter" data-date="June 18, 2021 21:00:00">
                    <div className="countdown-counter__item countdown-counter__item--days">
                        {timerComponents[0] ? ('0' + timerComponents[0].props.children[0]).slice(-2) : '00'}
                        <span className="countdown-counter__label">dia
                            {/* {timerComponents[0] ? <React.Fragment>{Number(timerComponents[0].props.children[0]) > 1 ? 's' : ''}</React.Fragment> : ''}  
                        </span>
                    </div>
                    <div className="countdown-counter__item countdown-counter__item--hours">
                        {timerComponents[1] ? ('0' + timerComponents[1].props.children[0]).slice(-2) : '00'}
                        <span className="countdown-counter__label">horas</span>
                    </div>
                    <div className="countdown-counter__item countdown-counter__item--mins">
                        {timerComponents[2] ? ('0' + timerComponents[2].props.children[0]).slice(-2) : '00'}
                        <span className="countdown-counter__label">mins</span>
                    </div>
                    <div className="countdown-counter__item countdown-counter__item--secs">
                        {timerComponents[3] ? ('0' + timerComponents[3].props.children[0]).slice(-2) : '00'}
                        <span className="countdown-counter__label">segs</span>
                    </div>
                </div>
                    :
                    ''}  </> */}
                {timerComponents.length ?
                    timerComponents.map((item, key) => (
                        <div key={key} className="countdown-counter__item countdown-counter__item--hours">
                            {item ? ('0' + item.value).slice(-2) : '00'}
                            <span className="countdown-counter__label">
                                {key === 0 && item.value === 1 ? 'dia' : getTranslatedInterval(item.interval)}
                            </span>
                        </div>
                    )) : ''}
            </div>
        </div>
    )
}
