import React from 'react'
import { Link } from 'react-router-dom';

import postImg from '../../../assets/images/samples/post-img1.jpg'
import CardNoticiaV1 from '../../../components/CardNoticiaV1';
import { convertDateTime, convertISODateString } from '../../../util/convertDate';
import { createSlug } from '../../../util/createSlug';
import Spotify from './Spotify';

export default function NoticiasRecentes(props) {
    const { noticias } = props;


    const imgPostFallback = (e) => {
        e.target.src = postImg
    }

    const CardGroupNoticias = (_noticias) => {
        return (
            <div className="col-sm-6">

                <div className="card__content">
                    <ul className="posts posts--simple-list">
                        {_noticias.map((item, key) => (
                            <li className={`posts__item posts__item--category-${item.id_esporte}`} key={key}>
                                <figure className="posts__thumb">
                                    <Link to={`/noticias/leitura/${item.id}/${createSlug(item.titulo)}`}>
                                        <img src={item.foto_capa ? `${process.env.REACT_APP_SGE_URL}_uploads/${process.env.REACT_APP_ID_ESTABELECIMENTO}/noticias/${item.foto_capa}` : ''}
                                            onError={(e) => imgPostFallback(e)} alt="" />
                                    </Link>
                                </figure>
                                <div className="posts__inner">
                                    <div className="posts__cat">
                                        <span className="label posts__cat-label">{item.idEsporte ? item.idEsporte.descricao : ''}</span>
                                    </div>
                                    <h6 className="posts__title">
                                        <Link to={`/noticias/leitura/${item.id}/${createSlug(item.titulo)}`}>{item.titulo}</Link>
                                    </h6>
                                    <time dateTime={convertISODateString(item.created_at * 1000)} className="posts__date">
                                        {convertDateTime(item.created_at * 1000)}
                                    </time>
                                </div>
                                <div className="posts__excerpt posts__excerpt--space-sm">
                                    {item.resumo}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

            </div>
        )
    }


    return (
        <React.Fragment>
            <div className="widget widget-news card card--clean">
                <header className="card__header card__header--has-btn">
                    <h4>Notícias</h4>
                    <Link to="/noticias" className="btn btn-default btn-outline btn-xs card-header__button">ver todas</Link>
                </header>
                <div className="card__content">
                    <div className="posts posts--cards post-grid post-grid--2cols post-grid--fitRows row">
                        {noticias.loading || noticias.error || !noticias.payload ?
                            ''
                            :
                            <React.Fragment>
                                {noticias.payload.slice(4, 8).map((item, key) => (
                                    <div className="post-grid__item col-sm-6" key={key}>
                                        <CardNoticiaV1 noticia={item} />
                                    </div>
                                ))}
                            </React.Fragment>
                        }
                    </div>
                    <Spotify />
                    <div className="card bg-white">
                        {noticias.loading || noticias.error || !noticias.payload ?
                            ''
                            :
                            <div className="row">
                                {CardGroupNoticias(noticias.payload.slice(8, 11))}
                                {CardGroupNoticias(noticias.payload.slice(11, 14))}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

