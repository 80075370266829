import React, { useMemo } from "react";
import { createSlug } from "../../../../util/createSlug";
import { Link } from "react-router-dom";
import PunicaoPontos from "./PunicaoPontos";
import PropTypes from "prop-types";
import _, { flatten } from "lodash";
import Penalidades from "./PunicaoPontos";

const isFutsal = process.env.REACT_APP_REST_API_PREFIX === "cbfutsal";
const isCBHB = process.env.REACT_APP_REST_API_PREFIX === "cbhb";

const showGols = isFutsal || isCBHB;

function ClassificacaoAgrupada({ groupClassificacao }) {
  const equipes = useMemo(() => {
    if (process.env.REACT_APP_ESTABELECIMENTO === "FPHAND") {
      const _equipes = groupClassificacao.payload.map((item) =>
        item?.eventoGrupoClubes?.map((grupo) => grupo.equipe)
      );
      return flatten(_equipes);
    }
    return [];
  }, [groupClassificacao.payload]);

  const handleNomeEquipe = (classificacaoItem) => {
    if (equipes.length && classificacaoItem.id_equipe) {
      const _equipe = equipes.find(
        (equipe) => equipe.id === classificacaoItem.id_equipe
      );

      return _equipe.descricao;
    }
    return classificacaoItem.idEstabelecimento.sigla;
  };

  return (
    <div className="widget__content card__content">
      {groupClassificacao.loading ||
      groupClassificacao.error ||
      !groupClassificacao.payload
        ? null
        : groupClassificacao.payload.map((item, key) => (
            <section key={key} className="match-preview__countdown countdown">
              <h4 className="countdown__title">{item.descricao}</h4>
              <div className="table-responsive">
                <table className="table table-hover table-standings">
                  <thead>
                    <tr>
                      <th>Equipe</th>
                      <th>Pts</th>
                      {showGols ? (
                        <React.Fragment>
                          <th title="Gols Proprios">GA</th>
                          <th title="Gols Contra">GC</th>
                          <th title="Saldo de Gols">SG</th>
                        </React.Fragment>
                      ) : null}
                      <th>V</th>
                      <th>E</th>
                      <th>D</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.orderBy(
                      item.eventoClassificacaos,
                      ["num_classificacao"],
                      ["asc"]
                    ).map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div className="d-flex">
                            <div className="team-meta">
                              <figure className="team-meta__logo">
                                <img
                                  src={item.idEstabelecimento.urlLogo}
                                  alt=""
                                />
                              </figure>
                              <div className="team-meta__info">
                                <Link
                                  to={`/clube/${
                                    item.idEstabelecimento.id
                                  }/${createSlug(
                                    item.idEstabelecimento.descricao
                                  )}`}
                                >
                                  {process.env.REACT_APP_ESTABELECIMENTO ===
                                  "FPHAND" ? (
                                    <h6 className="team-meta__name">
                                      {handleNomeEquipe(item)}
                                    </h6>
                                  ) : (
                                    <h6 className="team-meta__name">
                                      {item.idEstabelecimento.sigla}
                                    </h6>
                                  )}
                                </Link>
                                <span className="team-meta__place">
                                  {item.idEstabelecimento.uf}
                                </span>
                              </div>
                            </div>
                            <div>
                              <Penalidades
                                punicaoPontos={item.eventoPunicaoPontos}
                              />
                            </div>
                          </div>
                        </td>
                        <td>{item.num_pontos}</td>
                        {showGols ? (
                          <React.Fragment>
                            <td>{item.num_ga}</td>
                            <td>{item.num_gc}</td>
                            <td>{item.num_sg}</td>
                          </React.Fragment>
                        ) : null}
                        <td>{item.num_vitorias}</td>
                        <td>{item.num_empates}</td>
                        <td>{item.num_derrotas}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          ))}
      {groupClassificacao.error ? (
        <div className="m-2 alert alert-info">
          <strong>No momento não existe classificação para este evento.</strong>
        </div>
      ) : null}
    </div>
  );
}

PunicaoPontos.propTypes = {
  groupClassificacao: PropTypes.array,
};

export default ClassificacaoAgrupada;
