import React from 'react'
import { Link } from 'react-router-dom'
import { createSlug } from '../../../util/createSlug'

export default function CampeonatosClube(props) {
    const { eventoClassificacao } = props

    return (
        <div>
            <aside className="widget card widget--sidebar widget-standings">
                <div className="widget__title card__header card__header--has-btn">
                    <h4>CAMPEONATOS</h4>
                    <Link to="/eventos" className="btn btn-default btn-outline btn-xs card-header__button">ver todos</Link>
                </div>
                <div className="widget__content card__content">

                    <div className="table-responsive">
                        <table className="table table-hover table-standings">
                            <thead>
                                <tr>
                                    <th>Evento</th>
                                    <th>Pts</th>
                                    <th>V</th>
                                    <th>E</th>
                                    <th>D</th>
                                </tr>
                            </thead>
                            {eventoClassificacao.loading || eventoClassificacao.error || !eventoClassificacao.payload ?
                                <tbody></tbody>
                                :
                                <tbody>
                                    {eventoClassificacao.payload.map((item, key) => (
                                        <tr key={key}>
                                            <td>
                                                <Link to={`/evento/${item.idEvento.id}/${createSlug(item.idEvento.descricao)}`}>
                                                    <div className="team-meta">
                                                        {/* <figure className="team-meta__logo">
                                                        <img src={item.idEstabelecimento.urlLogo} alt="" />
                                                    </figure> */}
                                                        <div className="team-meta__info">
                                                            <h6 className="team-meta__name">
                                                                {item.idEvento.descricao}</h6>
                                                            <span className="team-meta__place">{item.idEvento.local}</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </td>
                                            <td>{item.num_pontos}</td>
                                            <td>{item.num_vitorias}</td>
                                            <td>{item.num_empates}</td>
                                            <td>{item.num_derrotas}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            }
                            {eventoClassificacao.error ?
                                <tbody>
                                    <tr>
                                        <td colSpan="5">
                                            <div className="m-2 alert alert-info">
                                                <strong>No momento não existe classificação para este clube.</strong>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                : ''}
                        </table>
                    </div>
                </div>
            </aside>
        </div>
    )
}
