import { Box, IconButton, Popover, Typography } from "@mui/material";
import React from "react";

export default function Penalidades({ punicaoPontos }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (punicaoPontos && punicaoPontos.length > 0) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          title="Penalidades"
          variant="Text"
          size="small"
          sx={{
            height: "15px !important",
            width: "15px !important",
            borderRadius: "50%",
            minWidth: "unset",
            color: "red",
            verticalAlign: "center",
            outline: "none !important",
            display: "flex",
            alignItems: "center",
            fontSize: ".7rem",
          }}
          onClick={handleClick}
        >
          <i className="fas fa-asterisk"></i>
        </IconButton>
        <Popover
          elevation={1}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          disableScrollLock
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 1.2 }}>
            <Typography
              sx={{
                fontSize: ".85rem",
                fontWeight: 700,
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              PENALIDADES:
            </Typography>
            {punicaoPontos.map((item) => (
              <Typography
                key={item.id}
                sx={{
                  fontSize: ".8rem",
                  fontFamily: '"Montserrat", sans-serif',
                  mb: 0.5,
                  "& i": {
                    fontSize: ".4rem",
                    pr: 1,
                    verticalAlign: "middle",
                  },
                }}
              >
                <i className="fas fa-minus"></i>
                {item.descricao}
              </Typography>
            ))}
          </Box>
        </Popover>
      </Box>
    );
  }
  return null;
}
