import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../../components/PageHeader";
import RedesSociaisAside from "../../components/RedesSociaisAside";
import { portalApi } from "../../services/api";
import CardNoticiasRecentes from "../noticias-leitura/sections/CardNoticiasRecentes";
import NoticiasSection from "./sections/NoticiasSection";
// import NoticiasFilter from './sections/NoticiasFilter'
import { Link } from "react-router-dom";
import {
  ButtonBase,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { Route, useHistory } from "react-router";
import { temporadas } from "../../assets/data/temporadas";
import { esportesCBHB } from "../../assets/data/esportesCBHB";

export default function NoticiasPage(params) {
  const sectionTop = useRef(null);
  let history = useHistory();
  const page = params.page;
  const queryParam = params.queryParam;

  const [searchTerm, setSearchTerm] = useState(queryParam);
  const [temporada, setTemporada] = useState(temporadas[0].value);
  const [esporte, setEsporte] = useState({ id: "", descricao: "" });

  const [totalPages, setTotalPages] = useState(0);
  //select-menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedTemporada, setSelectedTemporada] = React.useState(0);
  const [selectedEsporte, setSelectedEsporte] = React.useState(-1);
  // const open = Boolean(anchorEl);
  const [openMenu, setOpenMenu] = useState(false);

  const breadcrumb = [
    { label: "HOME", url: "/", active: false },
    { label: "Notícias", url: "/notícias", active: true },
  ];

  const [noticia, setNoticia] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setNoticia({
      loading: true,
      error: false,
      payload: [],
    });
    setTotalPages(0);
    let params = {};

    if (process.env.REACT_APP_DOMAIN === "CBHB" && esporte.id) {
      params = {
        id_esporte: esporte.id,
      };
    }

    if (queryParam) {
      params = {
        ...params,
        flag_del: 0,
        flag_publicado: 1,
        id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
        expand: "idEsporte,user,idPessoa",
        arrayLike: `titulo:${queryParam},corpo:${queryParam}`,
        sort: "-created_at",
        temporada: temporada,
        lessField: "created_at",
        lessValue: new Date().getTime().toString().substr(0, 10),
        pageSize: 12,
        page: page,
      };
    } else {
      params = {
        ...params,
        flag_del: 0,
        flag_publicado: 1,
        id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
        expand: "idEsporte,user,idPessoa",
        sort: "-created_at",
        temporada: temporada,
        lessField: "created_at",
        lessValue: new Date().getTime().toString().substr(0, 10),
        pageSize: 12,
        page: page,
      };
    }

    async function getNoticia() {
      await portalApi
        .get("noticia", {
          params: params,
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setNoticia({
              loading: false,
              error: true,
              payload: [],
            });
            setTotalPages(0);
          } else {
            setNoticia({
              loading: false,
              error: false,
              payload: response.data.items,
            });
            setTotalPages(response.data._meta.pageCount);
          }
        });
    }
    if (page || queryParam) {
      executeScroll();
    }

    getNoticia();
  }, [page, queryParam, esporte, temporada]);

  const [noticiaRecentes, setNoticiasRecentes] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setNoticiasRecentes({
      loading: true,
      error: false,
      payload: [],
    });
    async function getNoticia() {
      await portalApi
        .get("noticia", {
          params: {
            flag_del: 0,
            flag_publicado: 1,
            id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
            expand: "idEsporte",
            sort: "-created_at",
            lessField: "created_at",
            lessValue: new Date().getTime().toString().substr(0, 10),
            pageSize: 4,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setNoticiasRecentes({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setNoticiasRecentes({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        });
    }
    getNoticia();
  }, []);

  const executeScroll = () =>
    window.scrollTo({
      behavior: "smooth",
      top: sectionTop.current.offsetTop - 0,
    });

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchTerm) {
        history.push(`/noticias?q=${searchTerm}`);
      } else {
        history.push("/noticias");
      }
    }
  };

  //selects
  const handleClickListItem = (event, index) => {
    setOpenMenu({ [index]: !openMenu[index] });
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedTemporada(index);
    setTemporada(temporadas[index].value);
    setAnchorEl(null);
    setOpenMenu(false);
    history.push("/noticias");
  };
  const handleMenuItemClickEsporte = (event, index) => {
    if (index === -1) {
      setSelectedEsporte(index);
      setEsporte({ id: "", descricao: "" });
    } else {
      setSelectedEsporte(index);
      setEsporte({
        id: esportesCBHB[index].value,
        descricao: esportesCBHB[index].label,
      });
    }

    setAnchorEl(null);
    setOpenMenu(false);
    history.push("/noticias");
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <PageHeader title="Notícias" breadcrumb={breadcrumb} />
      <div className="post-filter">
        <div className="container">
          <div className="post-filter__form clearfix">
            <div className="post-filter__select" style={{ minHeight: 80 }}>
              <label className="post-filter__label" for="input-default">
                Pesquisar
              </label>
              <div
                className="cs-select cs-skin-border"
                style={{ height: "100%", display: "flex" }}
              >
                <input
                  type="text"
                  className="form-control"
                  id="input-default"
                  placeholder="Pesquisar notícia..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleSearchKeyDown}
                  style={{ height: 30, margin: "auto 1.5rem 10px 1.5rem " }}
                />
              </div>
            </div>
            <div className="post-filter__select">
              <ButtonBase
                className="post-filter__select"
                aria-expanded={openMenu[0] ? "true" : undefined}
                onClick={(e) => handleClickListItem(e, 0)}
              >
                <label className="post-filter__label">Temporada</label>
                <div className="cs-select cs-skin-border" tabindex="0">
                  <span className="cs-placeholder">{temporada}</span>
                </div>
              </ButtonBase>
              <Menu
                elevation={1}
                className="cs-options"
                anchorEl={anchorEl}
                open={openMenu[0]}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "lock-button",
                  role: "listbox",
                }}
              >
                {temporadas.map((option, index) => (
                  <MenuItem
                    style={{ minWidth: 200 }}
                    key={index}
                    selected={index === selectedTemporada}
                    onClick={(event) => handleMenuItemClick(event, index, 0)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            {process.env.REACT_APP_DOMAIN === "CBHB" &&
            process.env.REACT_APP_ESTABELECIMENTO !== "CBBC" ? (
              <div className="post-filter__select">
                <ButtonBase
                  className="post-filter__select"
                  aria-expanded={openMenu[1] ? "true" : undefined}
                  onClick={(e) => handleClickListItem(e, 1)}
                >
                  <label className="post-filter__label">Esporte</label>
                  <div className="cs-select cs-skin-border" tabindex="0">
                    <span className="cs-placeholder">
                      {esporte.descricao ? esporte.descricao : "Selecione"}
                    </span>
                  </div>
                </ButtonBase>
                <Menu
                  elevation={1}
                  className="cs-options"
                  anchorEl={anchorEl}
                  open={openMenu[1]}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "lock-button",
                    role: "listbox",
                  }}
                >
                  <MenuItem
                    style={{ minWidth: 200 }}
                    selected={selectedEsporte === -1}
                    onClick={(event) => handleMenuItemClickEsporte(event, -1)}
                  >
                    <spam>Selecione</spam>
                  </MenuItem>
                  {esportesCBHB.map((option, index) => (
                    <MenuItem
                      style={{ minWidth: 200 }}
                      key={index}
                      selected={index === selectedEsporte}
                      onClick={(event) =>
                        handleMenuItemClickEsporte(event, index)
                      }
                    >
                      <spam>{option.label}</spam>
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div ref={sectionTop}></div>

      <div className="site-content">
        <div className="container">
          <div className="row">
            <div className="content col-lg-8">
              <NoticiasSection noticias={noticia} />

              {totalPages > 1 ? (
                <Route>
                  {({ location }) => {
                    const query = new URLSearchParams(location.search);
                    const page = parseInt(query.get("p") || "1", 10);

                    return (
                      <nav className="post__comments-pagination">
                        <Pagination
                          className="pagination justify-content-center"
                          variant="outlined"
                          shape="rounded"
                          count={totalPages}
                          page={page}
                          renderItem={(item) => {
                            if (queryParam) {
                              return (
                                <PaginationItem
                                  className="page-link"
                                  component={Link}
                                  to={`/noticias?${
                                    queryParam ? `q=${queryParam}&` : ""
                                  }${item.page === 1 ? "" : `p=${item.page}`}`}
                                  {...item}
                                />
                              );
                            } else {
                              return (
                                <PaginationItem
                                  className="page-link"
                                  component={Link}
                                  to={`/noticias${
                                    item.page === 1 ? "" : `?p=${item.page}`
                                  }`}
                                  {...item}
                                />
                              );
                            }
                          }}
                        />
                      </nav>
                    );
                  }}
                </Route>
              ) : (
                <></>
              )}
            </div>

            <div id="sidebar" className="sidebar col-lg-4">
              <RedesSociaisAside horizontal />
              <CardNoticiasRecentes noticias={noticiaRecentes} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
