import { Skeleton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import {
  convertDateTime,
  convertISODateString,
} from "../../../util/convertDate";
import { createSlug } from "../../../util/createSlug";

export default function UltimoResultado(props) {
  const { ultimaPartida } = props;

  return (
    <div className="widget card">
      <header className="card__header card__header--has-btn">
        <h4>Últimos resultados</h4>
        {ultimaPartida.loading ||
        ultimaPartida.error ||
        ultimaPartida.payload.length ? (
          ""
        ) : (
          <Link
            to={`/evento/${ultimaPartida.payload.id_evento}/${createSlug(
              ultimaPartida.payload.idEvento.descricao
            )}/partida/${ultimaPartida.payload.id}/`}
            className="btn btn-default btn-outline btn-xs card-header__button"
          >
            confira a partida
          </Link>
        )}
      </header>
      {ultimaPartida.error ? (
        <div className="alert alert-info">
          <strong>Nenhuma partida foi encontrada.</strong>
        </div>
      ) : (
        <div className="card__content">
          <div className="game-result">
            <section className="game-result__section pt-0">
              <header className="game-result__header game-result__header--alt">
                <span className="game-result__league">
                  {ultimaPartida.loading
                    ? ""
                    : ultimaPartida.payload.idEvento.descricao}
                </span>
                <h3 className="game-result__title">
                  {ultimaPartida.loading ? "" : ultimaPartida.payload.local}
                </h3>
                {ultimaPartida.loading ? (
                  ""
                ) : (
                  <time
                    className="game-result__date"
                    dateTime={convertISODateString(ultimaPartida.payload.data)}
                  >
                    {convertDateTime(ultimaPartida.payload.data)}
                  </time>
                )}
              </header>
              <div className="game-result__content">
                {ultimaPartida.loading ? (
                  <div className="game-result__team game-result__team--first">
                    <figure className="game-result__team-logo">
                      <Skeleton variant="circular" height={100} width={100} />
                    </figure>
                    <div className="game-result__team-info d-flex flex-column">
                      <h5 className="game-result__team-name">
                        <Skeleton variant="text" />
                      </h5>
                      <div className="game-result__team-desc">
                        <Skeleton />
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link
                    to={`/clube/${
                      ultimaPartida.payload.vencedor.id
                    }/${createSlug(ultimaPartida.payload.vencedor.descricao)}`}
                  >
                    <div className="game-result__team game-result__team--first">
                      <figure className="game-result__team-logo">
                        <img
                          style={{ maxHeight: "100%" }}
                          src={
                            ultimaPartida.loading
                              ? ""
                              : ultimaPartida.payload.vencedor.urlLogo
                          }
                          alt={
                            ultimaPartida.loading
                              ? ""
                              : ultimaPartida.payload.vencedor.sigla_evento
                          }
                        />
                      </figure>
                      <div className="game-result__team-info">
                        <h5 className="game-result__team-name">
                          {ultimaPartida.loading
                            ? ""
                            : ultimaPartida.payload.vencedor.sigla_evento}
                        </h5>
                        <div className="game-result__team-desc">
                          {ultimaPartida.loading
                            ? ""
                            : ultimaPartida.payload.vencedor.descricao}
                        </div>
                      </div>
                    </div>
                  </Link>
                )}

                <div className="game-result__score-wrap">
                  {ultimaPartida.loading || ultimaPartida.error ? (
                    <div>
                      <div className="match-preview__conj">VS</div>
                    </div>
                  ) : (
                    <div className="game-result__score game-result__score--lg">
                      <span className="game-result__score-result game-result__score-result--winner">
                        {ultimaPartida.payload.vencedor.pts}
                      </span>{" "}
                      <span className="game-result__score-dash">-</span>{" "}
                      <span className="game-result__score-result game-result__score-result--loser">
                        {ultimaPartida.payload.perdedor.pts}
                      </span>
                    </div>
                  )}
                  <div className="game-result__score-label">Placar Final</div>
                </div>

                {ultimaPartida.loading ? (
                  <div className="game-result__team  game-result__team--second">
                    <figure className="game-result__team-logo">
                      <Skeleton variant="circular" height={100} width={100} />
                    </figure>
                    <div className="game-result__team-info d-flex flex-column">
                      <h5 className="game-result__team-name">
                        <Skeleton />
                      </h5>
                      <div className="game-result__team-desc">
                        <Skeleton />
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link
                    to={`/clube/${
                      ultimaPartida.payload.perdedor.id
                    }/${createSlug(ultimaPartida.payload.perdedor.descricao)}`}
                  >
                    <div className="game-result__team game-result__team--second">
                      <figure className="game-result__team-logo">
                        <img
                          style={{ maxHeight: "100%" }}
                          src={
                            ultimaPartida.loading
                              ? ""
                              : ultimaPartida.payload.perdedor.urlLogo
                          }
                          alt={
                            ultimaPartida.loading
                              ? ""
                              : ultimaPartida.payload.perdedor.sigla_evento
                          }
                        />
                      </figure>
                      <div className="game-result__team-info">
                        <h5 className="game-result__team-name">
                          {ultimaPartida.loading
                            ? ""
                            : ultimaPartida.payload.perdedor.sigla_evento}
                        </h5>
                        <div className="game-result__team-desc">
                          {ultimaPartida.loading
                            ? ""
                            : ultimaPartida.payload.perdedor.descricao}
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </section>
            <div className="spacer"></div>
            <section className="game-result__section">
              <header className="game-result__header game-result__header--alt">
                {ultimaPartida.loading ||
                ultimaPartida.error ||
                !ultimaPartida.payload.eventoGrupo
                  ? ""
                  : ultimaPartida.payload.eventoGrupo.descricao}
              </header>
            </section>
          </div>
        </div>
      )}
    </div>
  );
}
