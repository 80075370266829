import { ConfedApi } from "../services/api";

export async function fetchEstatisticasAtletaDestaque({ idEvento }) {
  const res = await ConfedApi.get("evento/estatisticas-atleta-destaque", {
    params: {
      id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
      idEvento: idEvento,
      sort: "colocacao",
    },
  });
  const data = res.data.items;
  return data;
}
