import { filter } from "lodash";

export function ScoutsHandler(
  partidaScoutsData,
  eventoPartidaData,
  tiposScouts
) {
  if (partidaScoutsData && eventoPartidaData && tiposScouts) {
    const scouts = [];
    partidaScoutsData.forEach((element) => {
      let scout = filter(tiposScouts, (o) => o.id === element.id_scout);
      scouts.push({ ...element, scout: scout[0] });
    });

    if (!scouts.length) {
      return {
        loading: false,
        error: true,
        payload: {
          casa: eventoPartidaData.casa,
          visitante: eventoPartidaData.visitante,
          scouts: scouts,
        },
      };
    }

    return {
      loading: false,
      error: false,
      payload: {
        casa: eventoPartidaData.casa,
        visitante: eventoPartidaData.visitante,
        scouts: scouts,
      },
    };
  }
}
