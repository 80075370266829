import marioBelo from '../../../assets/images/CBBC/governanca/mario-belo.webp'
import silvioRibeiro from '../../../assets/images/CBBC/governanca/silvio-ribeiro.webp'
import alexandreLourenco from '../../../assets/images/CBBC/governanca/alexandre-lourenco.webp'
import franswillame from '../../../assets/images/CBBC/governanca/franswillame-silva.webp'

export const executiva = {
    descricao: "DIRETORIA EXECUTIVA",
    pessoas: [
        {
            nome: "MARIO BELO",
            cargo: "Presidente",
            foto: marioBelo
        },
        {
            nome: "SILVIO RIBEIRO",
            cargo: "Vice Presidente",
            foto: silvioRibeiro,
        },
        {
            nome: "ALEXANDRE LOURENÇO",
            cargo: "1° Tesoureiro",
            foto: alexandreLourenco
        },
        {
            nome: "FRANSWILLAME SILVA",
            cargo: "2º Tesoureiro",
            foto: franswillame
        },
    ]
}