import { createTheme } from "@mui/material";

export const themeFutsal = createTheme({
  palette: {
    primary: {
      light: "#eb4755",
      main: "#e6202f",
      dark: "#CA1625",
    },
    secondary: {
      light: "#FFC95C",
      main: "#FFBB33",
      dark: "#ffa800",
    },
  },
});

export const themeCBHB = createTheme({
  palette: {
    primary: {
      light: "#4873EA",
      main: "#1b50e3",
      dark: "#1847c9",
    },
    secondary: {
      light: "#C3FF1F",
      main: "#BEFF0A",
      dark: "#87B800",
    },
  },
});

export const themeLigaole = createTheme({
  palette: {
    primary: {
      light: "#E67537",
      main: "#df5f1b",
      dark: "#cd4c15",
    },
    secondary: {
      light: "#20416F",
      main: "#1a3459",
      dark: "#172E4F",
    },
  },
});

export const themeCBBC = createTheme({
  palette: {
    primary: {
      light: "#DD8F40",
      main: "#db862f",
      dark: "#D07A25",
    },
    secondary: {
      light: "#3266AE",
      main: "#2c5a99",
      dark: "#29538E",
    },
  },
});

export const themeFPHAND = createTheme({
  palette: {
    primary: {
      light: "#DE6868",
      main: "#d13030",
      dark: "#A72525",
    },
    secondary: {
      light: "#F1D57E",
      main: "#ECC44A",
      dark: "#E8B621",
    },
  },
});
