import React from "react";
import { Link } from "react-router-dom";
import { createSlug } from "../../../../util/createSlug";
import { filter, flatten, orderBy } from "lodash";
import Penalidades from "./PunicaoPontos";
import { useMemo } from "react";

const isFutsal = process.env.REACT_APP_REST_API_PREFIX === "cbfutsal";
const isCBHB = process.env.REACT_APP_REST_API_PREFIX === "cbhb";
const showGols = isFutsal || isCBHB;

function ClassificacaoPorEvento({
  idEvento,
  handleChangeIdEvento,
  eventos,
  eventoClassificacao,
}) {
  const equipes = useMemo(() => {
    if (process.env.REACT_APP_ESTABELECIMENTO === "FPHAND") {
      const _equipes = eventoClassificacao.payload.map((item) =>
        item?.eventoGrupoClubes?.map((grupo) => grupo.equipe)
      );
      return flatten(_equipes);
    }
    return [];
  }, [eventoClassificacao.payload]);

  const handleNomeEquipe = (classificacaoItem) => {
    if (equipes.length && classificacaoItem.id_equipe) {
      const _equipe = equipes.find(
        (equipe) => equipe.id === classificacaoItem.id_equipe
      );

      return _equipe.descricao;
    }
    return classificacaoItem.idEstabelecimento.sigla;
  };

  return (
    <div className="widget__content card__content">
      <select
        value={idEvento}
        onChange={handleChangeIdEvento}
        label="Selecione o evento"
        className="form-control"
      >
        {eventos.loading || eventos.error || !eventos.payload ? null : (
          <React.Fragment>
            {eventos.payload.map((item, key) => (
              <option value={item.id} key={key}>
                {item.descricao}
              </option>
            ))}
          </React.Fragment>
        )}
      </select>
      <div className="table-responsive">
        {eventoClassificacao.loading ||
        eventoClassificacao.error ||
        !eventoClassificacao.payload
          ? null
          : eventoClassificacao.payload.map((item, key) => (
              <section key={key} className="match-preview__countdown countdown">
                <h4 className="countdown__title">{item.descricao}</h4>
                <div className="table-responsive">
                  <table className="table table-hover table-standings">
                    <thead>
                      <tr>
                        <th>Equipe</th>
                        <th>Pts</th>
                        {showGols ? (
                          <React.Fragment>
                            <th title="Gols Proprios">GA</th>
                            <th title="Gols Contra">GC</th>
                            <th title="Saldo de Gols">SG</th>
                          </React.Fragment>
                        ) : null}
                        <th>V</th>
                        <th>E</th>
                        <th>D</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderBy(
                        item.eventoClassificacaos,
                        ["num_classificacao"],
                        ["asc"]
                      ).map((item, key) => (
                        <tr key={key}>
                          <td>
                            <div className="d-flex">
                              <div className="team-meta">
                                <figure className="team-meta__logo">
                                  <img
                                    src={item.idEstabelecimento.urlLogo}
                                    alt=""
                                  />
                                </figure>
                                <div className="team-meta__info">
                                  <Link
                                    to={`/clube/${
                                      item.idEstabelecimento.id
                                    }/${createSlug(
                                      item.idEstabelecimento.descricao
                                    )}`}
                                  >
                                    {process.env.REACT_APP_ESTABELECIMENTO ===
                                    "FPHAND" ? (
                                      <h6 className="team-meta__name">
                                        {handleNomeEquipe(item)}
                                      </h6>
                                    ) : (
                                      <h6 className="team-meta__name">
                                        {item.idEstabelecimento.sigla}
                                      </h6>
                                    )}
                                  </Link>
                                  <span className="team-meta__place">
                                    {item.idEstabelecimento.uf}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <Penalidades
                                  punicaoPontos={item.eventoPunicaoPontos}
                                />
                              </div>
                            </div>
                          </td>
                          <td>{item.num_pontos}</td>
                          {showGols ? (
                            <React.Fragment>
                              <td>{item.num_ga}</td>
                              <td>{item.num_gc}</td>
                              <td>{item.num_sg}</td>
                            </React.Fragment>
                          ) : null}
                          <td>{item.num_vitorias}</td>
                          <td>{item.num_empates}</td>
                          <td>{item.num_derrotas}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </section>
            ))}

        {eventos.loading ||
        eventos.error ||
        !eventos.payload.length ||
        !idEvento ? null : (
          <div className="p-2 text-right">
            <Link
              className="btn btn-primary-inverse btn-xs"
              to={`/evento/${idEvento}/${createSlug(
                filter(eventos.payload, function (o) {
                  return o.id === Number(idEvento);
                })[0].descricao
              )}`}
            >
              ver evento
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClassificacaoPorEvento;
