import _ from "lodash";

function calculaPts(scout) {
  const ArrayPontos = scout.map((item) => item.pontos);
  const total = ArrayPontos.reduce(
    (prevValue, currentValue) => prevValue + currentValue,
    0
  );
  return total;
}

export function FormatResultadosClassificados({ scouts, isCBBC }) {
  const resultados = {
    pesoPontos: { 1: 1 },
    scouts,
  };
  // filtra lances livres convertidos
  if (isCBBC) {
    resultados.pesoPontos = {
      11: 1,
      12: 2,
      13: 3,
    };
    resultados.scouts = scouts;
  }
  // agrupa resultados
  resultados.scouts = resultados.scouts.map((item) => ({
    ...item,
    pontos: resultados.pesoPontos[item.id_scout],
  }));

  let groupPartidas = _.groupBy(resultados.scouts, "id_partida");
  let arrPartidas = _.toArray(groupPartidas);

  let partidasAtleta = arrPartidas.map((item) => ({
    gols: calculaPts(item),
    partidaScout: item[0],
  }));

  return partidasAtleta;
}
