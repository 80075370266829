import React from "react";

function RowFimPartida({ resultado, scoutTimeline }) {
  return (
    <tr>
      <td className="alc-commentary-subheader" colSpan="3">
        <div className="alc-commentary-subheader__icon">
          <i className="icon-svg icon-stopwatch"></i>
        </div>
        <div className="alc-commentary-subheader__title">Final</div>
        <div className="alc-commentary-subheader__subtitle">
          Fim da partida.{" "}
          <strong>
            {resultado.empate
              ? "Empate"
              : `${resultado.vencedor.nome} venceu por ${resultado.vencedor.placar}-${resultado.perdedor.placar}`}
          </strong>
        </div>
        <div>Período: {scoutTimeline.quarto}</div>
      </td>
    </tr>
  );
}

export default RowFimPartida;
