import lincoln from '../../../assets/images/CBBC/governanca/lincoln-fiuza.webp'
import paulonegri from '../../../assets/images/CBBC/governanca/paulo-negri.webp'
import sonia from '../../../assets/images/CBBC/governanca/sonia_ribeiro.webp'
import cleuma from '../../../assets/images/CBBC/governanca/cleuma-gonzalez.webp'
import edilson from '../../../assets/images/CBBC/governanca/edilson-vieira.webp'

export const conselhoDeliberativo = {
  descricao: "CONSELHO DELIBERATIVO",
  pessoas: [
    {
      nome: "LINCOLN FIUZA",
      cargo: "Presidente do Conselho Deliberativo\n Representante dos Técnicos",
      foto: lincoln
    },
    {
      nome: "PAULO NEGRI",
      cargo: "Representante dos Árbitros",
      foto: paulonegri
    },
    {
      nome: "SONIA RIBEIRO",
      cargo: "Representante dos Classificadores Funcionais",
      foto: sonia
    },
    {
      nome: "CLEUMA GONZALEZ",
      cargo: "Representante dos Atletas",
      foto: cleuma,
    },
    {
      nome: "EDILSON VIEIRA",
      cargo: "Representante dos Atletas",
      foto: edilson
    },
  ]
}
