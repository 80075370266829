import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import RedesSociaisAside from "../../components/RedesSociaisAside";
import { ConfedApi } from "../../services/api";
// import EstatisticasJogador from './sections/EstatisticasJogador';
import Header from "./sections/header";
import HistoricoJogos from "./sections/HistoricoJogos";
import moment from "moment";
import { Helmet } from "react-helmet";
import MetaContext from "../../context/MetaContext";

export default function AtletaPage(props) {
  const { siteMeta } = useContext(MetaContext);
  const [atleta, setAtleta] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  const [atletaPartida, setAtletaPartida] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  const { id } = useParams();

  const [clubeId, setClubeId] = useState(null);

  useEffect(() => {
    setAtleta({
      loading: true,
      error: false,
      payload: [],
    });

    async function getAtleta() {
      await ConfedApi.get("atleta", {
        params: {
          id_atleta: id,
          expand: "atleta,atleta.estabelecimento",
        },
      }).then((response) => {
        if (response.data._meta.totalCount === 0) {
          setAtleta({
            loading: false,
            error: true,
            payload: [],
          });
        } else {
          let nome;
          let sobrenome;
          if (response.data.items[0].nome_evento) {
            nome = response.data.items[0].nome_evento.split(" ");
            sobrenome = response.data.items[0].nome_evento
              .split(" ")
              .slice(1)
              .join(" ");
            if (!sobrenome) {
              sobrenome = response.data.items[0].atleta.nome_completo
                .split(" ")
                .slice(-1)
                .join(" ");
            }
          } else {
            nome = response.data.items[0].atleta.nome_completo.split(" ");
            sobrenome = response.data.items[0].atleta.nome_completo
              .split(" ")
              .slice(-1)
              .join(" ");
          }

          setAtleta({
            loading: false,
            error: false,
            payload: {
              ...response.data.items[0],
              firstName: nome[0],
              lastName: sobrenome,
            },
          });
          if (response.data.items[0].atleta) {
            setClubeId(response.data.items[0].atleta.estabelecimento.id);
          }
        }
      });
    }
    getAtleta();

    setAtletaPartida({
      loading: true,
      error: false,
      payload: [],
    });
    async function getEventoPartidaAtleta() {
      await ConfedApi.get("evento-partida-atleta", {
        params: {
          id_atleta: id,
          sort: "created_at",
          pageSize: 1,
        },
      }).then((response) => {
        if (response.data._meta.totalCount === 0) {
          setAtletaPartida({
            loading: false,
            error: true,
            payload: [],
          });
        } else {
          setAtletaPartida({
            loading: false,
            error: false,
            payload: response.data,
          });
        }
      });
    }
    getEventoPartidaAtleta();
  }, [id]);

  const [resultados, setResultados] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    if (clubeId) {
      setResultados({
        loading: true,
        error: false,
        payload: [],
      });

      async function getEventoPartida() {
        await ConfedApi.get("evento-partida", {
          params: {
            forcewhere: `idEvento.id_estabelecimento:${process.env.REACT_APP_ID_ESTABELECIMENTO}`,
            arrayLike: `id_estabelecimento_casa:${clubeId},id_estabelecimento_visitante:${clubeId}`,
            forcejoin: "idEvento,casa,visitante",
            lessField: "data",
            lessValue: moment(new Date()).format(),
            expand: "idEvento,casa,visitante",
            sort: "-data",
            pageSize: 5,
          },
        })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setResultados({
                loading: false,
                error: true,
                payload: [],
              });
            } else {
              setResultados({
                loading: false,
                error: false,
                payload: response.data.items,
              });
            }
          })
          .catch((err) => {
            setResultados({
              loading: false,
              error: true,
              payload: [],
            });
            console.log(err);
          });
      }
      getEventoPartida();
    }
  }, [id, clubeId]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${
          atleta.payload.firstName
            ? atleta.payload.firstName + " " + atleta.payload.lastName + " - "
            : ""
        } - ${siteMeta.siteTitle}`}</title>
      </Helmet>
      <div style={{ minHeight: "100vh" }}>
        <Header atleta={atleta} atletaPartida={atletaPartida} />
        <div className="site-content">
          <div className="container">
            <div className="row">
              <div className="content col-lg-8">
                <HistoricoJogos resultados={resultados} clubeId={clubeId} />
              </div>
              <div className="sidebar col-lg-4">
                {atleta.loading || atleta.error ? (
                  ""
                ) : (
                  <RedesSociaisAside horizontal atleta={atleta} />
                )}
                {/* <EstatisticasJogador /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
