import { ConfedApi } from "../services/api";

export async function fetchEventoPartidaScout(props) {
  const { idPartida } = props;

  const res = await ConfedApi.get("evento-partida-scout", {
    params: {
      id_partida: idPartida,
      expand: "atleta",
      pageSize: 1000,
      sort: "data",
    },
  });

  const data = res.data.items;
  return data;
}
