import React from 'react'
import { Link } from 'react-router-dom'

export default function PageHeader(props) {
    const { title, breadcrumb } = props

    return (
        <div className="page-heading">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <h1 className="page-heading__title">{title}</h1>
                        {breadcrumb ?
                            <ol className="page-heading__breadcrumb breadcrumb">
                                {breadcrumb.map((item, key) => {
                                    if (item.active) {
                                        return (
                                            <li key={key} className="breadcrumb-item active" aria-current="page">{item.label}</li>
                                        )
                                    } else {
                                        return (
                                            <li key={key} className="breadcrumb-item"><Link to={item.url}>{item.label}</Link></li>
                                        )
                                    }
                                })}
                            </ol>
                            : ''}

                    </div>
                </div>
            </div>
        </div>
    )
}
