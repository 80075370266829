import moment from 'moment';

export const convertDate = (dateTime) => {
    return moment(dateTime).format("DD/MM/YYYY HH:mm")
}

export const convertDateDayMonth = (dateTime) => {
    // var date = new Date(dateTime)
    // var formatedDate = date.toLocaleDateString()
    var formatedDate = (new Date(dateTime)).toLocaleString('pt-br', { day: '2-digit', month: '2-digit' })
    return (formatedDate)
}

export const convertDateTime = (dateTime) => {
    var formatedDate = moment(dateTime).format("DD/MM/YYYY HH:mm")
    return (formatedDate)
}

export const convertDateMonthString = (dateTime) => {
    var date = new Date(dateTime)
    var formatedDate = date.toLocaleDateString('pt-br', { day: 'numeric', month: 'long', year: 'numeric' })
    return (formatedDate)
}

export const convertDateYearString = (dateTime) => {
    var today = new Date();
    var birthDate = new Date(dateTime);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age + ' Anos';
}

export const convertDateYear = (dateTime) => {
    var today = new Date();
    var birthDate = new Date(dateTime);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const convertISODateString = (dateTime) => {
    //return (new Date(dateTime).toISOString().substr(0, 10))
    return moment(dateTime).format().substr(0,10)
}

export const convertSecondsDate = (dateTime) => {
    var date = new Date(dateTime)
    var formatedDate = date.toLocaleDateString()
    return (formatedDate)
}

export const convertSecondsDateTime = (dateTime) => {
    var date = new Date(dateTime)
    var time = date.toTimeString().substr(0, 5)
    var formatedDate = date.toLocaleDateString()
    return (formatedDate + ' ' + time)
}

export const convertDateTimeAbrev = (dateTime) => {
    var date = new Date(dateTime)
    var time = date.toTimeString().substr(0, 5)
    var dia = date.toLocaleString().substr(0, 2)
    var mes = date.toLocaleDateString('pt-br', { month: 'short' }).replace('.', '')

    return (dia + ' ' + mes + ', ' + time)
}