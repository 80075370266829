import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from 'prop-types';

export const TemplateTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        height: 4,
    },
});

export const TemplateTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 700,
    // marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: 13,
    textTransform: 'uppercase',
    '&:hover': {
        opacity: 1,
    },
    '&.Mui-selected': {
        outline: 'none'
    },
}));

export const FilterContainer = styled(Tabs)({
    background: '#fff',
    borderBottom: '1px solid #e4e7ed',
    '& .MuiTabs-flexContainer': {
        display: 'flex',
        justifyContent: 'center',
    },
    '& .MuiTabs-indicator': {
        height: 4,
    },
});

export const FilterItem = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    minWidth: 0,
    borderLeft: '1px solid #e4e7ed',
    fontWeight: 700,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: 13,
    textTransform: 'uppercase',
    '&:hover': {
        opacity: 1,
    },
    '&.Mui-selected': {
        outline: 'none'
    },
    '&:last-child': {
        borderRight: '1px solid #e4e7ed',
    },
    [theme.breakpoints.up('sm')]: {
        margin: 0,
        paddingTop: 28,
        paddingBottom: 28,
        minHeight: 80,
        minWidth: 180,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};