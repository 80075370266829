import { Alert, Grow, Pagination, PaginationItem, Skeleton } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { portalApi } from '../../services/api';
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import MetaContext from '../../context/MetaContext';

const options = {
    settings: {
        autoplaySpeed: 4000,
        boxShadow: 'none',
    },
    buttons: {
        showDownloadButton: false,
    }
}


export default function GaleriaItem() {
    const { siteMeta } = useContext(MetaContext)
    const sectionTop = useRef(null);
    const { id } = useParams();
    const [galeriaFotos, setGaleriaFotos] = useState({
        loading: true,
        error: false,
        payload: []
    })

    const [galeria, setGaleria] = useState({
        loading: true,
        error: false,
        payload: []
    })
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1)

    useEffect(() => {
        setGaleria({
            loading: true,
            error: false,
            payload: []
        })
        async function getGaleria() {
            await portalApi.get('galeria', {
                params: {
                    id: id
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setGaleria({
                        loading: true,
                        error: false,
                        payload: []
                    })
                } else {
                    setGaleria({
                        loading: false,
                        error: false,
                        payload: response.data.items
                    });
                }
            })
        }
        getGaleria()
    }, [id])


    useEffect(() => {
        if (!galeria.error || !galeria.loading) {
            setGaleriaFotos({
                loading: true,
                error: false,
                payload: []
            })
            async function getGaleriaFotos() {
                await portalApi.get('galeria-fotos', {
                    params: {
                        id_galeria: id,
                        sort: '-created_at',
                        page: page,
                        pageSize: 21,
                    },
                }).then((response) => {
                    if (response.data._meta.totalCount === 0) {
                        setGaleriaFotos({
                            loading: true,
                            error: false,
                            payload: []
                        })
                    } else {
                        setGaleriaFotos({
                            loading: false,
                            error: false,
                            payload: response.data.items
                        });
                        setTotalPages(response.data._meta.pageCount)
                        if (page > 1) {
                            executeScroll()
                        }
                    }
                })
            }
            getGaleriaFotos()
        }

    }, [id, page, galeria])

    const handleChange = (event, value) => {
        setPage(value);
    };

    const executeScroll = () => window.scrollTo({ behavior: 'smooth', top: sectionTop.current.offsetTop - 350 });

    let pageTitle = process.env.REACT_APP_ESTABELECIMENTO === 'CBBC' ? 'Album de Fotos' : 'Galeria';

    const breadcrumb = [
        { label: 'HOME', url: '/', active: false },
        { label: 'Galeria', url: '/galeria', active: false },
        { label: galeria.payload[0] ? galeria.payload[0].descricao : '', url: '', active: true }
    ]

    return (
        <div>
            <Helmet>
                <title>{galeria.payload[0] ? galeria.payload[0].descricao : pageTitle} - {siteMeta.siteTitle}</title>
            </Helmet>
            <PageHeader title={galeria.payload[0] ? galeria.payload[0].descricao : 'Galeria'} breadcrumb={breadcrumb} />
            <div className="site-content">
                <div className="container" style={{ minHeight: '700px' }}>
                    <div ref={sectionTop}></div>
                    {galeriaFotos.error ?
                        <Alert severity="error">Nenhuma foto encontrada.</Alert>
                        :
                        <>
                            {galeriaFotos.loading ?
                                <div className="album-wrapper">
                                    <div className="album row">
                                        {Array.from(new Array(15)).map((item, key) => (
                                            <div key={key} className="album__item col-xs-12 col-sm-6 col-md-4">
                                                <Skeleton variant='rectangular' height={250} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                :
                                <div className="album-wrapper">
                                    <SimpleReactLightbox>
                                        <SRLWrapper options={options}>
                                            <div className="album row">
                                                {galeriaFotos.payload.map((item, key) => (
                                                    <div key={key} className="album__item col-xs-12 col-sm-6 col-md-4">
                                                        <div className="album__item-holder">
                                                            <Grow in={true} timeout={600}>
                                                                <a href={`${process.env.REACT_APP_SGE_URL}_uploads/galeria/fotos/${item.url}`} className="album__item-link mp_gallery">
                                                                    <figure className="album__thumb album__thumb-fixed">
                                                                        <img src={`${process.env.REACT_APP_SGE_URL}_uploads/galeria/fotos/${item.url}`}
                                                                            alt={item.descricao} />
                                                                    </figure>
                                                                    <div className="album__item-desc">
                                                                        <span style={{ position: 'relative' }}>
                                                                            <i style={{ position: 'absolute', left: 0, right: 0, fontSize: 30 }} className="fa fa-search"></i>
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            </Grow>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </SRLWrapper>
                                    </SimpleReactLightbox>
                                    <div>
                                        {totalPages > 1 ?
                                            <div>
                                                <Pagination className="pagination pagination--condensed pagination--lg justify-content-center"
                                                    variant="outlined"
                                                    shape="rounded"
                                                    onChange={handleChange}
                                                    page={page}
                                                    count={totalPages}
                                                    renderItem={(item) => {
                                                        return (
                                                            <PaginationItem
                                                                className="page-link"
                                                                {...item}
                                                            />
                                                        )
                                                    }}
                                                />
                                            </div>
                                            :
                                            <React.Fragment></React.Fragment>
                                        }
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
