import React from 'react'
// import { Link } from 'react-router-dom'
// import { convertDateTime } from '../../../util/convertDate'
// import { createSlug } from '../../../util/createSlug'
// import avatarImg from '../../../assets/images/samples/avatar-1.jpg'
// import postImg from '../../../assets/images/samples/post-img1.jpg'
// import { Grow, Skeleton } from '@mui/material'
// import moment from 'moment';
import CardNoticiaV1 from '../../../components/CardNoticiaV1'

export default function NoticiasSection(props) {
    const { noticias } = props

    // const imgAvatarFallback = (e) => {
    //     e.target.src = avatarImg
    // }

    // const imgPostFallback = (e) => {
    //     e.target.src = postImg
    // }

    return (
        <div className="posts posts--cards post-grid row">
            {noticias.error || !noticias.payload ?
                <div className="post-grid__item col-12">
                    <div className="alert alert-info">
                        <strong>Nenhuma notícia foi encontrada para os filtros selecionados.</strong>
                    </div>
                </div>
                :
                ''
            }
            {(noticias.loading ? Array.from(new Array(6)) : noticias.payload).map((item, key) => (
                <div className="post-grid__item col-sm-6" key={key}>
                    <CardNoticiaV1 noticia={item} loading={noticias.loading} />
                </div>
            ))}

            {/* { ||  ?
                ''
                :
                <React.Fragment>
                    {noticias.payload.map((item, key) => (
                        <div className="post-grid__item col-sm-6" key={key}>
                            <Grow in={true}>
                                <div className={`posts__item posts__item--card posts__item--category-${noticias.loading ? '1' : item.id_esporte} card`}>
                                    <figure className="posts__thumb">
                                        {noticias.loading ? '' :
                                            <div className="posts__cat">
                                                {item.idEsporte ?
                                                    <span className="label posts__cat-label">
                                                        {item.idEsporte.descricao}
                                                    </span> : ''
                                                }
                                            </div>
                                        }
                                        {noticias.loading ?
                                            <Skeleton variant="rectangular" height={180} /> :
                                            <Link to={`/noticias/leitura/${item.id}/${createSlug(item.titulo)}`}>
                                                <img src={item.foto_capa ? `${process.env.REACT_APP_SGE_URL}_uploads/${process.env.REACT_APP_ID_ESTABELECIMENTO}/noticias/${item.foto_capa}` : ''}
                                                    onError={(e) => imgPostFallback(e)}
                                                    alt="" />
                                            </Link>
                                        }
                                    </figure>
                                    <div className="posts__inner card__content">
                                        {noticias.loading ?
                                            <React.Fragment>
                                                <Skeleton variant="text" width="80%" />
                                                <Skeleton variant="text" width="60%" height="1.2rem" />
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <time dateTime={moment(new Date(item.created_at * 1000)).format().substr(0, 10)} className="posts__date">
                                                    {convertDateTime(item.created_at * 1000)}
                                                </time>
                                                <h6 className="posts__title">
                                                    <Link to={`/noticias/leitura/${item.id}/${createSlug(item.titulo)}`}>
                                                        {item.titulo}
                                                    </Link>
                                                </h6>
                                                <div className="posts__excerpt">
                                                    {item.resumo}
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                    <footer className="posts__footer card__footer">
                                        <div className="post-author">
                                            <figure className="post-author__avatar">
                                                {noticias.loading ?
                                                    <Skeleton variant="circular" height={24} width={24} /> :
                                                    <img src={item.user.idPessoa.foto ? item.user.idPessoa.foto : ''}
                                                        onError={(e) => imgAvatarFallback(e)}
                                                        alt="Post Author Avatar" />
                                                }
                                            </figure>
                                            <div className="post-author__info">
                                                <h4 className="post-author__name">{noticias.loading ? <Skeleton variant="text" width={60} height="1.2rem" /> : item.user.idPessoa.nome_completo}</h4>
                                            </div>
                                        </div>
                                        <ul className="post__meta meta">
                                            <li className="meta__item meta__item--views">{noticias.loading ? <Skeleton variant="text" /> : item.acessos}</li>
                                            <li className="meta__item meta__item--likes">
                                                <a href="#!"><i className="meta-like icon-heart"></i> -</a>
                                            </li>
                                            <li className="meta__item meta__item--comments">
                                                <a href="#!">-</a>
                                            </li>
                                        </ul>
                                    </footer>
                                </div>
                            </Grow>
                        </div>

                    ))}
                </React.Fragment>
            } */}
        </div>
    )
}
