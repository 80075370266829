const dataAtual = (new Date()).getFullYear()

export const temporadas = [
    {
        label: `${dataAtual}`,
        value: `${dataAtual}`
    },
    {
        label:`${dataAtual-1}`,
        value:`${dataAtual-1}`
    },
    {
        label:`${dataAtual-2}`,
        value:`${dataAtual-2}`
    },
    {
        label:`${dataAtual-3}`,
        value:`${dataAtual-3}`
    },
    {
        label:`${dataAtual-4}`,
        value:`${dataAtual-4}`
    },
]