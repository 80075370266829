import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import _ from "lodash";
import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchEventoPartidaAtleta } from "../../../hooks/getEventoPartidaAtleta";
import { fetchTipoScouts } from "../../../hooks/getTipoScouts";
import TableAtleta from "./TableAtleta";

function LoadingTable() {
  return (
    <Box>
      <Skeleton variant="text" style={{ height: 70 }} />
      <Skeleton variant="rect" style={{ height: 450 }} />
    </Box>
  );
}

function PartidaEscalacao({ evento, partida }) {
  const { data: tiposScouts, isSuccess: isSuccessScouts } = useQuery({
    queryKey: ["TipoScouts"],
    queryFn: () => fetchTipoScouts(),
  });

  return (
    <Box>
      {evento && isSuccessScouts ? (
        <HandleAtletas
          evento={evento}
          partida={partida}
          tiposScouts={tiposScouts}
        />
      ) : null}
    </Box>
  );
}

export default PartidaEscalacao;

function HandleAtletas({ evento, partida, tiposScouts, setInfoEscalacao }) {
  const {
    data: atletasCasa,
    isSuccess: isSuccessCasa,
    isLoading: isLoadingCasa,
  } = useQuery(
    ["EventoPartidaAtleta-casa"],
    ({
      pageParam = {
        evento,
        idPartida: partida.id,
        idEquipe: partida.id_equipe_casa,
      },
    }) => fetchEventoPartidaAtleta(pageParam)
  );

  const {
    data: atletasVisitante,
    isSuccess: isSuccessVisitante,
    isLoading: isLoadingVisitante,
  } = useQuery(
    ["EventoPartidaAtleta-visitante"],
    ({
      pageParam = {
        evento,
        idPartida: partida.id,
        idEquipe: partida.id_equipe_visitante,
      },
    }) => fetchEventoPartidaAtleta(pageParam)
  );

  const scoutsID = useMemo(() => {
    const scouts = [];
    if (atletasCasa && atletasCasa.length) {
      atletasCasa.forEach((element) => {
        if (element.scouts.length) {
          let teste = element.scouts.map((item) => item.id_scout);
          scouts.push(teste);
        }
      });
    }
    if (atletasVisitante && atletasVisitante.length) {
      atletasVisitante.forEach((element) => {
        if (element.scouts.length) {
          let teste = element.scouts.map((item) => item.id_scout);
          scouts.push(teste);
        }
      });
    }
    const scoutsIds = _.chain(scouts).flatten().uniq().value();
    return scoutsIds;
  }, [atletasCasa, atletasVisitante]);

  const scoutsPartida = useMemo(() => {
    if (scoutsID.length && tiposScouts.length) {
      let _scouts = scoutsID.map((item) => {
        let teste = _.filter(
          tiposScouts,
          (o) => o.id === item && o.icon !== ""
        );
        return teste;
      });

      const flat = _.flatten(_scouts);
      return _.orderBy(flat, ["id"], ["asc"]);
    }
    return [];
  }, [scoutsID, tiposScouts]);

  return (
    <div>
      {isLoadingCasa ? <LoadingTable /> : null}
      {isSuccessCasa ? (
        <TableAtleta
          tipo="casa"
          evento={evento}
          idPartida={partida.id}
          idEquipe={partida.id_equipe_casa}
          partida={partida}
          scoutsPartida={scoutsPartida}
          atletas={atletasCasa}
        />
      ) : null}

      {isLoadingVisitante ? <LoadingTable /> : null}
      {isSuccessVisitante ? (
        <TableAtleta
          tipo="visitante"
          evento={evento}
          idPartida={partida.id}
          idEquipe={partida.id_equipe_visitante}
          partida={partida}
          scoutsPartida={scoutsPartida}
          atletas={atletasVisitante}
        />
      ) : null}
    </div>
  );
}
