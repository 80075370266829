import React, { useContext, useEffect, useRef, useState } from "react";
import { portalApi } from "../../services/api";
import { Alert, Pagination, PaginationItem, Skeleton } from "@mui/material";
import { Grow } from "@mui/material";
import { Link } from "react-router-dom";
import { createSlug } from "../../util/createSlug";
import { convertSecondsDate } from "../../util/convertDate";
import { Helmet } from "react-helmet";
import PageHeader from "../../components/PageHeader";
import MetaContext from "../../context/MetaContext";

export default function PageGaleria() {
  const { siteMeta } = useContext(MetaContext);
  const sectionTop = useRef(null);
  const [galeria, setGaleria] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setGaleria({
      loading: true,
      error: false,
      payload: [],
    });
    setTotalPages(0);
    async function getOrgaoAnexos() {
      await portalApi
        .get("galeria", {
          params: {
            flag_del: 0,
            id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
            flg_publicado: 1,
            sort: "-data",
            expand: "galeriaFotoDestaque",
            page: page,
            pageSize: 12,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setGaleria({
              loading: false,
              error: true,
              payload: [],
            });
            setTotalPages(0);
          } else {
            setGaleria({
              loading: false,
              error: false,
              payload: response.data.items,
            });
            setTotalPages(response.data._meta.pageCount);
            if (page > 1) {
              executeScroll();
            }
          }
        });
    }
    getOrgaoAnexos();
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  let pageTitle =
    process.env.REACT_APP_ESTABELECIMENTO === "CBBC"
      ? "Álbum de Fotos"
      : "Galeria";
  let pageURL =
    process.env.REACT_APP_ESTABELECIMENTO === "CBBC"
      ? "album-de-fotos"
      : "galeria";

  const breadcrumb = [
    { label: "HOME", url: "/", active: false },
    { label: pageTitle, url: "/galeria", active: true },
  ];

  const executeScroll = () =>
    window.scrollTo({
      behavior: "smooth",
      top: sectionTop.current.offsetTop - 350,
    });

  return (
    <div>
      <Helmet>
        <title>
          {pageTitle} - {siteMeta.siteTitle}
        </title>
      </Helmet>
      <PageHeader title={pageTitle} breadcrumb={breadcrumb} />
      <div className="site-content">
        <div className="container">
          <div ref={sectionTop}></div>
          {galeria.error ? (
            <Alert severity="error">Nenhuma galeria encontrada.</Alert>
          ) : (
            <>
              {galeria.loading ? (
                <div className="album-wrapper">
                  <div className="album row">
                    {Array.from(new Array(15)).map((item, key) => (
                      <div
                        key={key}
                        className="album__item col-xs-12 col-sm-6 col-md-4"
                      >
                        <Skeleton variant="rectangular" height={250} />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="album-wrapper">
                  <div className="album row">
                    {galeria.payload.map((item, key) => (
                      <div
                        key={key}
                        className="album__item col-xs-12 col-sm-6 col-md-4"
                      >
                        <div className="album__item-holder">
                          <Grow in={true} timeout={600}>
                            <Link
                              to={`/${pageURL}/${item.id}/${createSlug(
                                item.descricao
                              )}`}
                              className="album__item-link mp_gallery"
                            >
                              <figure className="album__thumb album__thumb-fixed">
                                <img
                                  src={`${
                                    process.env.REACT_APP_SGE_URL
                                  }_uploads/galeria/fotos/${
                                    item.galeriaFotoDestaque[0]
                                      ? item.galeriaFotoDestaque[0].url
                                      : ""
                                  }`}
                                  alt={item.descricao}
                                />
                              </figure>
                              <div className="album__item-desc album__item-desc--bottom-left">
                                <span className="album__item-icon">
                                  <span className="icon-camera"></span>
                                </span>
                                <div className="album__item-desc-inner">
                                  <h4 className="album__item-title">
                                    {item.descricao}
                                  </h4>
                                  <time
                                    className="album__item-date"
                                    dateTime={convertSecondsDate(item.data)}
                                  >
                                    {convertSecondsDate(item.data)}
                                  </time>
                                </div>
                              </div>
                              <div className="d-block d-md-none">
                                <div className="card-body">
                                  {item.descricao}
                                  <br />
                                  {convertSecondsDate(item.data)}
                                </div>
                              </div>
                            </Link>
                          </Grow>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>
                    {totalPages > 1 ? (
                      <div>
                        <Pagination
                          className="pagination pagination--condensed pagination--lg justify-content-center"
                          variant="outlined"
                          shape="rounded"
                          onChange={handleChange}
                          page={page}
                          count={totalPages}
                          renderItem={(item) => {
                            return (
                              <PaginationItem className="page-link" {...item} />
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
