import React from 'react'
import { Helmet } from 'react-helmet'
import PageHeader from '../../components/PageHeader'
import PesquisaDocumentos from './sections/PesquisaDocumentos'
import PesquisaEventos from './sections/PesquisaEventos'
import PesquisaNoticias from './sections/PesquisaNoticias'

export default function PesquisaPage(params) {
    const { searchTerm } = params
    const breadcrumb = [
        { label: 'HOME', url: '/', active: false },
        { label: 'Pesquisa', url: '/Pesquisa', active: true },
    ]

    return (
        <div>
            <Helmet>
                <title>{`Pesquisa: ${searchTerm} - ${process.env.REACT_APP_DOMAIN}`}</title>
            </Helmet>
            <PageHeader title={<span>Pesquisa <span className="highlight">{searchTerm}</span></span>} breadcrumb={breadcrumb} />
            <div className="site-content">
                <div className="container">
                    <div className="row">
                        <div className="content col-lg-12">
                            <PesquisaNoticias searchTerm={searchTerm} />
                        </div> 
                        <div className="content col-lg-12">
                            <PesquisaEventos searchTerm={searchTerm} />
                        </div> 
                        <div className="content col-lg-12">
                            <PesquisaDocumentos searchTerm={searchTerm} />
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}
