import React, { useContext } from 'react'
import MetaContext from '../context/MetaContext'

export default function RedesSociaisAside(props) {
	const { siteMeta } = useContext(MetaContext)

	if (props.horizontal) {
		if (props.atleta) {
			return (
				<aside className="widget widget--sidebar widget-social">
					<a href={props.atleta.payload.atleta.perfil_facebook} rel="noreferrer" className={`btn-social-counter btn-social-counter--fb ${props.atleta.payload.atleta.perfil_facebook ? '' : 'disabled'}`} target="_blank">
						<div className="btn-social-counter__icon">
							<i className="fab fa-facebook">
							</i>
						</div>
						<h6 className="btn-social-counter__title">
							Seguir no Facebook</h6>
						<span className="btn-social-counter__count">
							<span className="btn-social-counter__count-num">
							</span>
						</span>
						<span className="btn-social-counter__add-icon">
						</span>
					</a>
					<a href={props.atleta.payload.atleta.perfil_twitter} rel="noreferrer" className={`btn-social-counter btn-social-counter--twitter ${props.atleta.payload.atleta.perfil_twitter ? '' : 'disabled'}`} target="_blank">
						<div className="btn-social-counter__icon">
							<i className="fab fa-twitter">
							</i>
						</div>
						<h6 className="btn-social-counter__title">
							Seguir no Twitter</h6>
						<span className="btn-social-counter__count">
							<span className="btn-social-counter__count-num">
							</span>
						</span>
						<span className="btn-social-counter__add-icon">
						</span>
					</a>
					<a href={props.atleta.payload.atleta.perfil_instagram} rel="noreferrer" className={`btn-social-counter btn-social-counter--instagram ${props.atleta.payload.atleta.perfil_instagram ? '' : 'disabled'}`} target="_blank">
						<div className="btn-social-counter__icon">
							<i className="fab fa-instagram"></i>
						</div>
						<h6 className="btn-social-counter__title">
							Seguir no Instagram</h6>
						<span className="btn-social-counter__count">
							<span className="btn-social-counter__count-num">
							</span>
						</span>
						<span className="btn-social-counter__add-icon">
						</span>
					</a>
				</aside>
			)
		}

		return (
			<aside className="widget widget--sidebar widget-social">
				{siteMeta.redesSociais.slice(0, 3).map((item, key) => (
					<a key={key} href={item.url} rel="noreferrer" className={`btn-social-counter ${item.btnClass}`} target="_blank">
						<div className="btn-social-counter__icon">
							<i className={`fab fa-${item.name}`}>
							</i>
						</div>
						<h6 className="btn-social-counter__title">
							{item.texto}</h6>
						<span className="btn-social-counter__count">
							<span className="btn-social-counter__count-num">
							</span>
							Seguir</span>
						<span className="btn-social-counter__add-icon">
						</span>
					</a>
				))}
			</aside>
		)

	}


	return (
		<aside className="widget widget--sidebar widget-social widget-social--condensed">
			{siteMeta.redesSociais.slice(0, 3).map((item, key) => (
				<a key={key} href={item.url} rel="noreferrer" className={`btn-social-counter ${item.btnClass}`} target="_blank">
					<div className="btn-social-counter__icon">
						<i className={`fab fa-${item.name}`}>
						</i>
					</div>
					<h6 className="btn-social-counter__title">
						{item.texto}</h6>
					<span className="btn-social-counter__count">
						<span className="btn-social-counter__count-num">
						</span>
						Seguir</span>
					<span className="btn-social-counter__add-icon">
					</span>
				</a>
			))}
		</aside>
	)

}
