const ouvidoriaFields = {
  id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
  protocolo: "",
  id_estado_solicitacao: "1",
  tipo_contato: "ouvidoria",
  nome_solicitante: "",
  email: "",
  telefone: "",
  motivo: "",
  mensagem: "",
  flag_del: 0,
  created_at: "",
  updated_at: "",
  created_by: "",
  updated_by: "",
};

export default ouvidoriaFields;
