import React, { useState, useEffect } from "react";
import YouTubeApi from "../../../services/youtube";
import _ from "lodash";
import Slider from "react-slick";
// import MetaContext from '../../../context/MetaContext';

export default function VideosYoutube() {
  const [loading, setLoading] = useState(true);
  // const [page, setPage] = useState(1);
  const [youtubeVideos, setYoutubeVideos] = useState([]);
  const [youtubeVideosChunk, setYoutubeVideosChunk] = useState([]);
  const [nomeCanal, setNomeCanal] = useState("Youtube");
  // const { siteMeta } = useContext(MetaContext)

  function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
      <div
        className="video-slick-arrow arrow-next"
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
      <div
        className="video-slick-arrow arrow-prev"
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  useEffect(() => {
    async function getYoutube() {
      await YouTubeApi.get("/playlistItems", {
        params: {
          playlistId: process.env.REACT_APP_YOUTUBE_UPLOADS_ID,
          sort: "publishedAt",
        },
      }).then((response) => {
        if (response.data.pageInfo.totalResults === 0) {
          console.log("nenhum video");
        } else {
          setYoutubeVideos(response.data.items);
          setLoading(false);
          setNomeCanal(response.data.items[0].snippet.channelTitle);
        }
      });
    }
    getYoutube();
  }, []);

  // const [linkYoutube, setLinkYoutube] = useState('')
  // useEffect(() => {
  //     if (siteMeta.redesSociais) {
  //         let ytObj = _.filter(siteMeta.redesSociais, function (o) { return o.name === 'youtube'; })
  //         setLinkYoutube(ytObj[0] ? ytObj[0].url : '')
  //     }
  // }, [siteMeta])

  useEffect(() => {
    var chunks = [];
    if (youtubeVideos) {
      chunks = _.chunk(youtubeVideos, 8);
      setYoutubeVideosChunk(chunks[0]);
    }
  }, [youtubeVideos]);

  const settings = {
    dots: false,
    autoplay: false,
    autoplaySpeed: 1000,
    infinite: true,
    speed: 500,
    swipeToSlide: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="widget widget-news card card--clean">
        <header className="card__header card__header--has-btn">
          <h4>Vídeos</h4>
          <a
            href={`https://www.youtube.com/channel/${process.env.REACT_APP_YOUTUBE_ID}`}
            target="_blank"
            rel="noreferrer"
            className="btn btn-primary btn-xs card-header__button"
          >
            {nomeCanal}
          </a>
        </header>
        <div className="card__content">
          {loading ? (
            ""
          ) : (
            <Slider {...settings}>
              {youtubeVideosChunk.map((item, key) => (
                <div key={key} className="px-2">
                  <iframe
                    title={`video-${key}`}
                    width="100%"
                    height="200"
                    src={`https://www.youtube.com/embed/${item.snippet.resourceId.videoId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
}
