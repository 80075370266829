export const menuFABR = [
  {
    label: "A SPFL",
    url: "#!",
    filho: [
      {
        label: "Sobre a LPF",
        url: "/lpf/20/sobre-a-lpf",
      },
      {
        label: "Comunicados",
        url: "/lpf/21/comunicados",
      },
      {
        label: "Todos os campeões",
        url: "/lpf/22/todos-os-campeoes",
      },

      {
        label: "TV SPFL",
        url: "https://www.youtube.com/@spflsaopaulofootballleague5954/videos",
      },
    ],
  },
  {
    label: "NOTÍCIAS",
    url: "/noticias",
    filho: null,
  },
  {
    label: "CAMPEONATOS",
    url: "/eventos",
    filho: null,
  },
];
