import { Box } from "@mui/material";
import React from "react";

function RowPonto({ scoutTimeline }) {
  const handleName = (scoutAtleta) => {
    if (scoutAtleta) {
      if (scoutAtleta.nome_evento !== "") {
        return scoutAtleta.nome_evento;
      }
      return scoutAtleta.atleta.nome_completo;
    }
    return "";
  };

  let pontoNaoConvertido =
    // process.env.REACT_APP_DOMAIN === "CBBC" &&
    scoutTimeline.scout.tipo_render === "ponto" &&
    scoutTimeline.convertido === 1
      ? {
          position: "relative",
          "& i::before": { filter: "grayscale(1)" },
          "& span": {
            position: "absolute",
            right: "25%",
            top: 8,
            fontSize: "1rem",
            fontWeight: 900,
          },
        }
      : null;

  return (
    <tr>
      <td className="alc-commentary-cell alc-commentary-cell--team1">
        <div className="alc-commentary-cell__inner">
          {!scoutTimeline.timeCasa ? (
            <div className="alc-commentary-cell__inner">
              <div>Período: {scoutTimeline.quarto}</div>
            </div>
          ) : (
            <div className="alc-commentary-cell__inner">
              <div className="alc-commentary-cell__text">
                {scoutTimeline.scout.descricao}
                <strong>
                  {scoutTimeline.atleta &&
                    ` - ${scoutTimeline.atleta.nome_evento}`}
                </strong>
              </div>
              <Box
                className="alc-commentary-cell__icon"
                sx={pontoNaoConvertido}
              >
                <i className={`icon-svg ${scoutTimeline.scout.icon}`}></i>
                {pontoNaoConvertido ? (
                  <span style={{ color: "red" }}>x</span>
                ) : null}
              </Box>
            </div>
          )}
        </div>
      </td>
      <td className="alc-time-stamp">{scoutTimeline.tempo / 100}'</td>
      <td className="alc-commentary-cell alc-commentary-cell--team2">
        {scoutTimeline.timeCasa ? (
          <div className="alc-commentary-cell__inner">
            <div>Período: {scoutTimeline.quarto}</div>
          </div>
        ) : (
          <div className="alc-commentary-cell__inner">
            <div className="alc-commentary-cell__text">
              {scoutTimeline.scout.descricao}
              <strong>{` - ${handleName(scoutTimeline.atleta)}`}</strong>
            </div>
            <Box className="alc-commentary-cell__icon" sx={pontoNaoConvertido}>
              <i className={`icon-svg ${scoutTimeline.scout.icon}`}></i>
              {pontoNaoConvertido ? (
                <span style={{ color: "red" }}>x</span>
              ) : null}
            </Box>
          </div>
        )}
      </td>
    </tr>
  );
}

export default RowPonto;
