import { Pagination, PaginationItem } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { gestaoApi } from "../../../services/api";
import TabelaEventos from "../../eventos/sections/TabelaEventos";

export default function PesquisaEventos(props) {
  const { searchTerm } = props;
  const [page, setPage] = useState(1);
  const [eventos, setEventos] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalRes, setTotalRes] = useState(0);

  const sectionTop = useRef(null);

  useEffect(() => {
    setEventos({
      loading: true,
      error: false,
      payload: [],
    });
    setTotalPages(0);

    if (searchTerm) {
      async function getNoticia() {
        await gestaoApi
          .get("evento", {
            params: {
              flag_del: 0,
              id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
              expand: "estabelecimento",
              sort: "-data_inicio",
              descricao: searchTerm,
              betweenField: "data_inicio",
              page: page,
            },
          })
          .then((response) => {
            if (page > 1) {
              executeScroll();
            }
            if (response.data._meta.totalCount === 0) {
              setEventos({
                loading: false,
                error: true,
                payload: [],
              });
              setTotalPages(0);
            } else {
              setEventos({
                loading: false,
                error: false,
                payload: response.data.items,
              });
              setTotalPages(response.data._meta.pageCount);
              setTotalRes(response.data._meta.totalCount);
            }
          });
      }
      getNoticia();
    }
  }, [searchTerm, page]);

  const handleChange = (event, value) => {
    setPage(value);
    if (value > 1) {
      executeScroll();
    }
  };

  const executeScroll = () =>
    window.scrollTo({
      behavior: "smooth",
      top: sectionTop.current.offsetTop - 200,
    });

  return (
    <React.Fragment>
      <div ref={sectionTop}></div>
      {eventos.error ? (
        ""
      ) : (
        <React.Fragment>
          <div
            className={`alert ${
              eventos.error ? "alert-danger" : "alert-success"
            } `}
          >
            <strong>
              {totalRes}{" "}
              {totalRes > 1
                ? "Eventos foram encontrados"
                : "Evento foi encontrado"}{" "}
              para "<span className="text-success">{searchTerm}</span>"
            </strong>
          </div>
          <div className="card card--has-table">
            <div className="card__content">
              {eventos.loading || eventos.error || !eventos.payload ? (
                ""
              ) : (
                <TabelaEventos eventos={eventos} />
              )}
            </div>
          </div>
        </React.Fragment>
      )}
      {totalPages > 1 ? (
        <div>
          <Pagination
            className="pagination pagination--condensed pagination--lg justify-content-center"
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            page={page}
            count={totalPages}
            renderItem={(item) => {
              return <PaginationItem className="page-link" {...item} />;
            }}
          />
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
}
