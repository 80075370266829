import React, { useContext, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import PageHeader from "../../components/PageHeader";
import Classificacao from "../home/sections/Classificacao";
import JogoHeader from "../home/sections/JogoHeader";
import PartidaEscalacao from "./sections/PartidaEscalacao";
import EventoInfo from "../evento/sections/EventoInfo";
import MetaContext from "../../context/MetaContext";
import { FilterContainer, FilterItem, TabPanel } from "../../components/Tabs";
import PlayByPlay from "./sections/PlaybyPlay/index";
import { Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchEvento } from "../../hooks/getEvento";
import { fetchEventoGrupo } from "../../hooks/getEventoGrupo";
import { fetchEventoPartidaPorId } from "../../hooks/getEventoPartida";
import { fetchEstatisticasAtletaDestaque } from "../../hooks/getEstatisticasAtletaDestaque";
import { fetchEventoPartidaScout } from "../../hooks/getEventoPartidaScout";
import PartidaTimeLine from "./sections/PartidaTimeLine";
import { fetchTipoScouts } from "../../hooks/getTipoScouts";
import { ScoutsHandler } from "./sections/ScoutsHandler/ScoutsHandler";
import moment from "moment";

export default function PartidaPage(props) {
  const { idPartida, idEvento } = useParams();
  // faz o fect da partida primeiro para tratar caso esteja ao vivo
  const {
    data: eventoPartidaData,
    isSuccess: isSuccessEventoPartida,
    isLoading: isLoadingEventoPartida,
    isError: isErrorEventoPartida,
  } = useQuery(["eventoPartida"], () => fetchEventoPartidaPorId({ idPartida }));

  const isLiveNow = useMemo(() => {
    if (isSuccessEventoPartida) {
      return moment().isBetween(
        eventoPartidaData.data,
        moment(eventoPartidaData.data).add(4, "hours")
      );
    }
    return false;
  }, [isSuccessEventoPartida, eventoPartidaData]);

  return (
    <PartidaPageComponent
      idPartida={idPartida}
      idEvento={idEvento}
      isLiveNow={isLiveNow}
      partidaStates={{
        eventoPartidaData,
        isSuccessEventoPartida,
        isLoadingEventoPartida,
        isErrorEventoPartida,
      }}
    />
  );
}

function PartidaPageComponent({
  idEvento,
  idPartida,
  partidaStates,
  isLiveNow,
}) {
  const {
    eventoPartidaData,
    isSuccessEventoPartida,
    isLoadingEventoPartida,
    isErrorEventoPartida,
  } = partidaStates;

  const { siteMeta } = useContext(MetaContext);
  const breadcrumb = [
    { label: "HOME", url: "/", active: false },
    { label: "Eventos", url: "/eventos", active: false },
  ];
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const { data: tiposScouts } = useQuery(["TipoScouts"], () =>
    fetchTipoScouts()
  );
  const { data: eventoData, isSuccess: isSuccessEvento } = useQuery(
    ["evento"],
    () => fetchEvento({ idEvento })
  );

  const {
    data: partidasGrupoData,
    isError: isErrorPartidasGrupo,
    isLoading: isLoadingPartidasGrupo,
  } = useQuery(["partidasGrupo"], () => fetchEventoGrupo({ idEvento }));

  const {
    data: artilheirosData,
    isError: isErrorArtilheiros,
    isLoading: isLoadingArtilheiros,
  } = useQuery(["artilheiros"], () =>
    fetchEstatisticasAtletaDestaque({ idEvento })
  );

  const { data: partidaScoutsData } = useQuery({
    queryKey: ["eventoPartidaScout"],
    queryFn: () => fetchEventoPartidaScout({ idPartida }),
    refetchInterval: isLiveNow ? 10000 : false,
  });

  const eventoPartidaScouts = useMemo(() => {
    return ScoutsHandler(partidaScoutsData, eventoPartidaData, tiposScouts);
  }, [partidaScoutsData, eventoPartidaData, tiposScouts]);

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {isSuccessEvento && eventoData.descricao
            ? `${eventoData.descricao} - ${siteMeta.siteTitle}`
            : `Evento - ${siteMeta.siteTitle}`}
        </title>
      </Helmet>
      {isSuccessEventoPartida && eventoPartidaData ? (
        <JogoHeader
          pagePartida
          partidas={{
            loading: false,
            error: false,
            payload: [eventoPartidaData],
          }}
        />
      ) : (
        <PageHeader
          title={
            isSuccessEvento && eventoData ? eventoData.descricao : "Partida"
          }
          breadcrumb={breadcrumb}
        />
      )}
      <FilterContainer color="primary" value={tabIndex} onChange={handleChange}>
        <FilterItem label="Informações" />
        <FilterItem label="Ao vivo" />
      </FilterContainer>
      <TabPanel value={tabIndex} index={0}>
        <div className="site-content">
          <div className="container">
            {isSuccessEventoPartida && eventoPartidaScouts ? (
              <PartidaTimeLine
                eventoPartida={eventoPartidaData}
                eventoPartidaScouts={eventoPartidaScouts}
              />
            ) : null}
            <div className="row">
              <div className="content col-md-8">
                {isLoadingEventoPartida ? (
                  <div className="widget card card--has-table ">
                    <div className="card__header">
                      <h4> </h4>
                    </div>
                    <div className="card__content">
                      <div className="table-responsive">
                        <table className="table table-hover alc-table-stats alc-table-stats--compact-sm">
                          <tbody>
                            {Array.from(new Array(10)).map((item, key) => (
                              <tr key={key}>
                                <td>
                                  <Skeleton />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : null}
                {isLoadingEventoPartida || isErrorEventoPartida ? null : (
                  <PartidaEscalacao
                    evento={eventoData}
                    partida={eventoPartidaData}
                  />
                )}
              </div>
              <div className="sidebar col-md-4">
                {isErrorEventoPartida || !eventoPartidaData ? null : (
                  <EventoInfo
                    tipoPartida
                    loading={isLoadingEventoPartida}
                    evento={eventoData}
                    partida={eventoPartidaData}
                    partidasGrupoData={partidasGrupoData}
                  />
                )}

                <Classificacao
                  tipoCard="partida"
                  eventos={eventoData}
                  idEvento={idEvento}
                  artilheiros={{
                    loading: isLoadingArtilheiros,
                    error: isErrorArtilheiros,
                    payload: artilheirosData,
                  }}
                  groupClassificacao={{
                    loading: isLoadingPartidasGrupo,
                    error: isErrorPartidasGrupo,
                    payload: partidasGrupoData,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <div className="site-content">
          <div className="container">
            {isLoadingEventoPartida ||
            isErrorEventoPartida ||
            !eventoPartidaData ? null : (
              <PlayByPlay
                eventoPartidaScouts={eventoPartidaScouts}
                partidasDestaque={eventoPartidaData}
              />
            )}
          </div>
        </div>
      </TabPanel>
    </React.Fragment>
  );
}
