import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import _ from "lodash";
import { governancaApi } from "../../services/api";
import PageHeader from "../../components/PageHeader";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Grow,
  Skeleton,
  Typography,
} from "@mui/material";
import { convertSecondsDateTime } from "../../util/convertDate";
import { Helmet } from "react-helmet";
import MetaContext from "../../context/MetaContext";
import { presidentes } from "../../assets/data/presidentesCBBC";
import { createSlug } from "../../util/createSlug";
import mural from "../../assets/images/CBBC/mural.png";

export default function OrgaoPage(props) {
  const { idMenu } = useParams();
  // const { menuGov } = props;
  const { siteMeta } = useContext(MetaContext);
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [menuFilhos, setMenuFilhos] = useState([]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    async function getMenuGovernanca() {
      await governancaApi
        .get("menu-governanca", {
          params: {
            id: idMenu,
            id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
            flag_del: 0,
            expand: "filhos,orgao,filhos.orgao.orgaoAnexos",
            sort: "-created_at",
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setError(true);
          }

          setMenu(response.data.items);
          if (response.data._meta.totalCount !== 0) {
            if (response.data.items[0].filhos) {
              let filteredOrgao = _.filter(response.data.items[0].filhos, function (o) {
                return o.orgao;
              });
              let filtered = _.filter(filteredOrgao, function (o) {
                return o.orgao.orgaoAnexos;
              });
              if (process.env.REACT_APP_ESTABELECIMENTO === "CBBC") {
                setMenuFilhos(_.orderBy(filtered, ["titulo"], ["asc"]));
              } else {
                setMenuFilhos(_.orderBy(filtered, ["ordem"], ["asc"]));
              }
            }
          }
          setLoading(false);
        });
    }
    getMenuGovernanca();
  }, [idMenu]);

  const [expanded, setExpanded] = React.useState("panel0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const breadcrumb = [
    { label: "HOME", url: "/", active: false },
    {
      label: menu[0] ? menu[0].titulo : process.env.REACT_APP_ESTABELECIMENTO,
      url: "/",
      active: true,
    },
  ];

  if (loading) {
    return (
      <>
        <PageHeader title="Governança" breadcrumb={breadcrumb} />
        <div className="site-content" style={{ minHeight: "30vh" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="content col-lg-8">
                <Skeleton height="50px" width="80%" />
                <Skeleton height="50px" width="100%" />
                <Skeleton variant="rectangular" height="250px" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (error) {
    return <div>error</div>;
  }
  return (
    <>
      <Helmet>
        <title>
          {loading || error ? "Eventos" : menu[0].titulo} - {siteMeta.siteTitle}
        </title>
      </Helmet>
      <PageHeader title={menu[0].titulo} breadcrumb={breadcrumb} />
      <div className="site-content" style={{ minHeight: "30vh" }}>
        <div className="container">
          {menuFilhos ? (
            <div className="row justify-content-center">
              <div className="content col-lg-8">
                {menu[0].orgao ? (
                  <div>
                    {menu[0].orgao.corpo ? (
                      <div className="card card--lg post post--single">
                        <div className="card__content">{ReactHtmlParser(menu[0].orgao.corpo)}</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
                {process.env.REACT_APP_ESTABELECIMENTO === "CBBC" && idMenu === "5" ? (
                  <div className="card-deck d-block d-md-flex">
                    <img src={mural} style={{ maxWidth: "100%", height: "auto", padding: "20" }} alt="" />
                    {presidentes.map((item, key) => (
                      <div className="card text-center mb-3 card-pessoa" key={key}>
                        <a href={`/cbbc/pessoa/${item.id}/${createSlug(item.nome)}`}>
                          <Avatar
                            src={item.foto}
                            alt={item.nome}
                            variant="circular"
                            style={{
                              margin: "auto",
                              width: 145,
                              height: 145,
                              marginTop: "1rem",
                            }}
                          />
                          <div className="card-body">
                            <h5 className="mb-1">{item.nome}</h5>
                            <h6 className="text-primary mb-0" style={{ fontSize: ".75rem", lineHeight: 1.3 }}>
                              {item.cargo}
                            </h6>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
                <div className="accordion accordion--space-between">
                  {menuFilhos.map((item, key) => (
                    <Accordion
                      elevation={0}
                      className="card"
                      square
                      expanded={expanded === `panel${key}`}
                      onChange={handleChange(`panel${key}`)}
                    >
                      <div className="card__header">
                        <AccordionSummary
                          aria-controls={`panel${key}d-content`}
                          id={`panel${key}d-header`}
                          className="p-0 m-0"
                        >
                          <Typography variant="h5" className="mb-0 w-100">
                            <button
                              className={`btn btn-link accordion__header-link ${
                                expanded === `panel${key}` ? "" : "collapsed"
                              }`}
                            >
                              {item.titulo}
                              <span className="accordion__header-link-icon"></span>
                            </button>
                          </Typography>
                        </AccordionSummary>
                      </div>
                      <AccordionDetails>
                        <div className="table-responsive">
                          <table className="table lineup-table">
                            <thead>
                              <tr>
                                <th>ARQUIVOS</th>
                                <th>DATA</th>
                                <th className="text-center">ANEXO</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.orgao.orgaoAnexos.length === 0 ? (
                                <tr>
                                  <td className="lineup__num" colspan={3}>
                                    <Alert severity="error">Nenhum anexo encontrado</Alert>
                                  </td>
                                </tr>
                              ) : (
                                <React.Fragment>
                                  {item.orgao.orgaoAnexos.map((item, key) => (
                                    <Grow in={true} key={key} timeout={600}>
                                      <tr>
                                        <td component="th">
                                          <span
                                            style={{
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            {item.titulo}
                                          </span>
                                        </td>
                                        <td>{convertSecondsDateTime(item.created_at * 1000)}</td>
                                        <td align="center">
                                          <a
                                            className="btn btn-primary btn-xs"
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`${process.env.REACT_APP_SGE_URL}_uploads/orgaoAnexo/${item.nome_arquivo}`}
                                          >
                                            download
                                          </a>
                                        </td>
                                      </tr>
                                    </Grow>
                                  ))}
                                </React.Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
              {/* {singlepostTemplate ?
                                <div className="sidebar col-lg-4">

                                </div> : ""} */}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
