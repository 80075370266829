import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { governancaApi } from "../../services/api";
import PageHeader from "../../components/PageHeader";
import { Helmet } from "react-helmet";
import MetaContext from "../../context/MetaContext";
import { convertSecondsDateTime } from "../../util/convertDate";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Grow,
  Skeleton,
  Typography,
} from "@mui/material";

export default function OrgaoContentPage(props) {
  const { idOrgao } = useParams();

  const { siteMeta } = useContext(MetaContext);
  const [orgao, setOrgao] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  const [anexos, setAnexos] = useState([]);

  useEffect(() => {
    setOrgao({
      loading: true,
      error: false,
      payload: [],
    });
    async function getOrgao() {
      await governancaApi
        .get("orgao", {
          params: {
            id: idOrgao,
            id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
            expand: "orgaoAnexos",
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setOrgao({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setOrgao({
              loading: false,
              error: false,
              payload: response.data.items[0],
            });
            setAnexos(response.data.items[0].orgaoAnexos);
          }
        });
    }
    getOrgao();
  }, [idOrgao]);

  const [expanded, setExpanded] = React.useState("panel0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const breadcrumb = [
    { label: "HOME", url: "/", active: false },
    {
      label: !orgao.loading || !orgao.error ? orgao.payload.titulo : "page",
      url: "/",
      active: true,
    },
  ];

  if (orgao.loading) {
    return (
      <>
        <PageHeader title=" " breadcrumb={breadcrumb} />
        <div className="site-content" style={{ minHeight: "30vh" }}>
          <div className="container">
            <Skeleton height={50} />
            <Skeleton height={200} />
          </div>
        </div>
      </>
    );
  }
  if (orgao.error) {
    return <div>error</div>;
  }
  return (
    <>
      <Helmet>
        <title>
          {orgao.payload.titulo} - {siteMeta.siteTitle}
        </title>
      </Helmet>
      <PageHeader title={orgao.payload.titulo} breadcrumb={breadcrumb} />
      <div className="site-content" style={{ minHeight: "30vh" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="content col-lg-8">
              <div>
                {orgao.payload.corpo ? (
                  <div className="card card--lg post post--single">
                    <div className="card__content">
                      {ReactHtmlParser(orgao.payload.corpo)}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {orgao.payload.orgaoAnexos ? (
                <div className="accordion accordion--space-between">
                  {orgao.payload.orgaoAnexos.map((item, key) => (
                    <Accordion
                      elevation={0}
                      className="card"
                      square
                      expanded={expanded === `panel${key}`}
                      onChange={handleChange(`panel${key}`)}
                    >
                      <div className="card__header">
                        <AccordionSummary
                          aria-controls={`panel${key}d-content`}
                          id={`panel${key}d-header`}
                          className="p-0 m-0"
                        >
                          <Typography variant="h5" className="mb-0 w-100">
                            <button
                              className={`btn btn-link accordion__header-link ${
                                expanded === `panel${key}` ? "" : "collapsed"
                              }`}
                            >
                              Anexos
                              <span className="accordion__header-link-icon"></span>
                            </button>
                          </Typography>
                        </AccordionSummary>
                      </div>
                      <AccordionDetails>
                        <div className="table-responsive">
                          <table className="table lineup-table">
                            <thead>
                              <tr>
                                <th>ARQUIVO</th>
                                <th>DATA</th>
                                <th className="text-center">ANEXO</th>
                              </tr>
                            </thead>
                            <tbody>
                              {anexos.length === 0 ? (
                                <tr>
                                  <td className="lineup__num" colspan={3}>
                                    <Alert severity="error">
                                      Nenhum anexo encontrado
                                    </Alert>
                                  </td>
                                </tr>
                              ) : (
                                <React.Fragment>
                                  {anexos.map((item, key) => (
                                    <Grow in={true} key={key} timeout={600}>
                                      <tr>
                                        <td component="th">
                                          <span
                                            style={{
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            {item.titulo}
                                          </span>
                                        </td>
                                        <td>
                                          {convertSecondsDateTime(
                                            item.created_at * 1000
                                          )}
                                        </td>
                                        <td align="center">
                                          <a
                                            className="btn btn-primary btn-xs"
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`${process.env.REACT_APP_SGE_URL}_uploads/orgaoAnexo/${item.nome_arquivo}`}
                                          >
                                            download
                                          </a>
                                        </td>
                                      </tr>
                                    </Grow>
                                  ))}
                                </React.Fragment>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
