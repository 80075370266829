import { useQuery } from "@tanstack/react-query";
import React from "react";
import { fetchEventoStaff } from "../../../hooks/getEventoStaff";
import { Avatar, Skeleton } from "@mui/material";

function TableStaff({ idPartida, idEquipe, scoutsPartida }) {
  const { data, isSuccess, isLoading } = useQuery(
    [`EventoStaffPessoa-${idEquipe}`],
    ({ pageParam = { idPartida, idEquipe } }) => fetchEventoStaff(pageParam)
  );

  return (
    <React.Fragment>
      <thead>
        <tr>
          <th
            colSpan={scoutsPartida && scoutsPartida.length + 2}
            className="alc-number alc-align-center text-center"
          >
            STAFF
          </th>
        </tr>
      </thead>
      <tbody>
        {isLoading
          ? Array.from(new Array(2)).map((item, key) => (
              <tr key={key}>
                <td colSpan={scoutsPartida && scoutsPartida.length + 2}>
                  <Skeleton />
                </td>
              </tr>
            ))
          : null}
        {isSuccess
          ? data.map((item, key) => (
              <tr key={key}>
                <td className="alc-number alc-align-start">{item.id}</td>
                <td
                  className="alc-align-start"
                  colSpan={scoutsPartida && scoutsPartida.length + 1}
                >
                  <div className="alc-player-info">
                    <Avatar
                      style={{ height: 26, width: 26, marginRight: 14 }}
                      src={`${process.env.REACT_APP_SGE_URL}/_uploads/fotoPessoa/${item.pessoa.foto}`}
                      alt={item.pessoa.nome_completo}
                    />
                    <div className="alc-player-info__name">
                      {item.pessoa.nome_completo}
                      <br />
                      {item.funcao ? item.funcao.descricao : "-"}
                    </div>
                  </div>
                </td>
              </tr>
            ))
          : null}
      </tbody>
    </React.Fragment>
  );
}

export default TableStaff;
