import { SvgIcon } from '@mui/material';

export function GhostIconSVG(props) {
    return (
        <SvgIcon width="250.124" height="308.937" viewBox="0 0 250.124 308.937" {...props}>
            <path id="Ghost" style={{ fill: '#e1e3e8', fillRule: 'evenodd' }} d="M634.566,818.886a9.386,9.386,0,0,1-3.46-.65,9.648,9.648,0,0,1-6.167-9V644.358c0-74.1,56.1-134.4,125.056-134.4s125.066,60.294,125.066,134.4V809.235a9.626,9.626,0,0,1-16.819,6.419l-35.5-40-28.749,39.295a9.627,9.627,0,0,1-7.765,3.939h0a9.616,9.616,0,0,1-7.766-3.949l-28.035-38.351L721.9,814.994a9.61,9.61,0,0,1-7.719,3.892h-0.066a9.6,9.6,0,0,1-7.728-3.987l-28.41-39.34L641.7,815.71A9.609,9.609,0,0,1,634.566,818.886Zm44.251-68.276c0.179,0,.349.009,0.527,0.019a9.582,9.582,0,0,1,7.267,3.967l27.678,38.334,28.486-38.353a9.6,9.6,0,0,1,7.719-3.892h0.037a9.616,9.616,0,0,1,7.728,3.949l27.978,38.276,28.016-38.3a9.65,9.65,0,0,1,7.314-3.931,9.475,9.475,0,0,1,7.643,3.224l26.6,29.973V644.358c0-63.461-47.468-115.093-105.812-115.093s-105.8,51.632-105.8,115.093V784.2l27.488-30.416A9.631,9.631,0,0,1,678.817,750.61Zm100.11-115.143a19.207,19.207,0,1,0,19.207-19.248A19.231,19.231,0,0,0,778.927,635.467Zm-96.27,0a19.207,19.207,0,1,0,19.207-19.248A19.232,19.232,0,0,0,682.657,635.467Z" transform="translate(-624.938 -509.969)" />
        </SvgIcon>
    )
}

export function IconWhistleSVG(props) {
    return (
        <SvgIcon viewBox="0 0 18 19" width="30" height="32" {...props}>
            <path fillRule="evenodd" fill="currentColor" d="M8.711,12.054 L3.384,16.992 C3.384,16.992 2.542,16.771 1.265,16.387 C-0.012,16.003 0.143,15.125 0.143,15.125 L6.559,4.040 L8.711,12.054 Z" />
            <path fillRule="evenodd" fill="currentColor" d="M6.024,4.964 C7.500,2.413 10.770,1.539 13.327,3.011 C15.884,4.484 16.760,7.746 15.284,10.297 C13.807,12.848 10.538,13.722 7.981,12.249 C5.424,10.777 4.548,7.515 6.024,4.964 Z" />
            <path fillRule="evenodd" fill="currentColor" d="M7.876,6.030 C8.762,4.500 10.723,3.975 12.258,4.859 C13.792,5.743 14.317,7.700 13.432,9.230 C12.546,10.761 10.584,11.285 9.050,10.402 C7.516,9.518 6.990,7.561 7.876,6.030 Z" />
            <path fillRule="evenodd" fill="currentColor" d="M8.430,0.807 C8.873,0.041 9.853,-0.221 10.621,0.221 C11.388,0.663 11.651,1.642 11.208,2.407 C10.765,3.172 9.784,3.434 9.017,2.992 C8.250,2.551 7.987,1.572 8.430,0.807 Z" />
        </SvgIcon>
    )
}

export function IconSoccerBallSVG(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" width="32" height="32" {...props}>

            <path fillRule="evenodd" fill="currentColor" d="M8.000,-0.001 C12.418,-0.001 16.000,3.581 16.000,7.999 C16.000,12.417 12.418,15.999 8.000,15.999 C3.581,15.999 -0.000,12.417 -0.000,7.999 C-0.000,3.581 3.581,-0.001 8.000,-0.001 Z" />
            <path fillRule="evenodd" fill="currentColor" d="M7.982,0.526 C12.099,0.526 15.436,3.864 15.436,7.981 C15.436,12.098 12.099,15.435 7.982,15.435 C3.865,15.435 0.527,12.098 0.527,7.981 C0.527,3.864 3.865,0.526 7.982,0.526 Z" />
            <path fillRule="evenodd" opacity="0.6" fill="currentColor" d="M13.687,11.643 L13.513,11.591 L14.567,8.136 L14.741,8.189 L13.687,11.643 ZM10.280,7.058 L13.244,6.076 L13.301,6.249 L10.337,7.231 L10.280,7.058 ZM10.240,1.893 L10.342,1.742 L13.196,3.669 L13.094,3.820 L10.240,1.893 ZM11.128,12.292 L9.237,9.892 L9.380,9.779 L11.271,12.179 L11.128,12.292 ZM7.909,2.526 L8.091,2.526 L8.091,6.635 L7.909,6.635 L7.909,2.526 ZM4.144,13.128 L6.453,9.910 L6.601,10.016 L4.292,13.234 L4.144,13.128 ZM2.772,6.358 L2.828,6.185 L5.937,7.185 L5.881,7.358 L2.772,6.358 ZM2.675,3.706 L5.602,1.670 L5.706,1.819 L2.779,3.855 L2.675,3.706 ZM1.185,8.132 L1.360,8.084 L2.379,11.885 L2.203,11.932 L1.185,8.132 ZM10.309,14.399 L6.127,14.399 L6.127,14.217 L10.309,14.217 L10.309,14.399 Z" />
            <path fillRule="evenodd" fill="currentColor" d="M7.982,5.281 L10.515,7.121 L9.547,10.099 L6.416,10.099 L5.448,7.121 L7.982,5.281 Z" />
            <path fillRule="evenodd" fill="currentColor" d="M7.991,2.817 L10.527,1.935 L10.218,0.508 L5.763,0.508 L5.455,1.935 L7.991,2.817 Z" />
            <path fillRule="evenodd" fill="currentColor" d="M12.904,6.306 L14.546,8.430 L15.801,7.684 L14.386,3.460 L12.935,3.621 L12.904,6.306 Z" />
            <path fillRule="evenodd" fill="currentColor" d="M2.982,6.296 L1.343,8.417 L0.091,7.672 L1.503,3.454 L2.951,3.614 L2.982,6.296 Z" />
            <path fillRule="evenodd" fill="currentColor" d="M4.807,12.191 L6.412,14.338 L5.359,15.345 L1.673,12.855 L2.215,11.502 L4.807,12.191 Z" />
            <path fillRule="evenodd" fill="currentColor" d="M11.121,12.144 L9.570,14.329 L10.651,15.306 L14.275,12.723 L13.696,11.388 L11.121,12.144 Z" />

        </SvgIcon>
    )
}

export function MailEnvelopeSVG(props) {
    return (
        <SvgIcon viewBox="0 0 512 512" fill="currentColor" width="512" height="512" {...props}>
            <path fill="currentColor" d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2.4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4.3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z"></path>
        </SvgIcon>
    )
}

export function CloseIconSVG(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" fill="currentColor" width="24" height="24" {...props}>
            <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
        </SvgIcon>
    )
}


export function DownloadOitlineSVG(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" fill="currentColor" width="24" height="24" {...props}>
           <path d="M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zm-1-4-1.41-1.41L13 12.17V4h-2v8.17L8.41 9.59 7 11l5 5 5-5z"></path>
        </SvgIcon>
    )
}