import _ from 'lodash';
import React from 'react'
import { Link } from 'react-router-dom';
import { convertDateTime } from '../../../util/convertDate';
import { createSlug } from '../../../util/createSlug';

export default function ProximasPatidas(props) {
    const { partidas, clubeId } = props;


    const testCasaVisitante = (partida) => {
        let times = [partida.casa, partida.visitante]
        let timeAdversario = _.filter(times, function (o) { return o.id !== Number(clubeId) });
        let adversario = timeAdversario[0]

        return (
            <Link to={`/clube/${adversario.id}/${createSlug(adversario.descricao)}`}>
                <div className="team-meta">
                    <figure className="team-meta__logo">
                        <img src={adversario.urlLogo} alt={adversario.sigla_evento} />
                    </figure>
                    <div className="team-meta__info">
                        <h6 className="team-meta__name">{adversario.sigla_evento}</h6>
                        <span className="team-meta__place" style={{ whiteSpace: 'normal' }}>{adversario.descricao}</span>
                    </div>
                </div>
            </Link>
        )
    }

    return (
        <div className="widget card card--has-table">
            <header className="card__header card__header--has-btn">
                <h4>Próximas partidas</h4>
            </header>
            <div className="card__content">
                <div className="table-responsive">
                    <table className="table table-hover team-schedule">
                        <thead>
                            <tr>
                                <th className="team-schedule__date">Data</th>
                                <th className="team-schedule__versus">Adversário</th>
                                <th className="team-schedule__time">Evento</th>
                                <th className="team-schedule__venue">Local</th>
                                <th className="team-schedule__tickets">Ingressos</th>
                            </tr>
                        </thead>
                        <tbody>
                            {partidas.loading || partidas.error || !partidas.payload.length ?
                                <tr>
                                    <td colSpan="5">
                                        <div className="alert alert-info">
                                            <strong>Nenhuma partida foi encontrada.</strong>
                                        </div>
                                    </td>
                                </tr>
                                :
                                <React.Fragment>
                                    {partidas.payload.map((item, key) => (
                                        <tr key={key}>
                                            <td className="team-schedule__date">
                                                {convertDateTime(item.data)}
                                            </td>
                                            <td className="team-schedule__versus">
                                                {testCasaVisitante(item)}
                                            </td>
                                            <td className="team-schedule__time">
                                                <Link to={`/evento/${item.idEvento.id}/${createSlug(item.idEvento.descricao)}`}>{item.idEvento.descricao}</Link>
                                            </td>
                                            <td className="team-schedule__venue">{item.local}</td>
                                            <td className="team-schedule__tickets">
                                                <button className="btn btn-xs btn-default btn-outline btn-block ">
                                                    comprar ingressos
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
