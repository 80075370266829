import React from "react";
import { convertDate, convertISODateString } from "../../../util/convertDate";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Link } from "react-router-dom";
import { createSlug } from "../../../util/createSlug";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function ResultadosAside(props) {
  const { resultados } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const testWinner = (pts1, pts2) => {
    if (pts1 > pts2) {
      return "widget-game-result__score-result--winner";
    } else {
      return "widget-game-result__score-result--loser";
    }
  };

  const nextStep = () => {
    if (activeStep < 9) {
      setActiveStep(activeStep + 1);
    }
  };
  const prevStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <aside className="widget card widget--sidebar widget-game-result">
      <div className="widget__title card__header card__header--has-btn">
        <h4>Resultados</h4>
        {/* <a href="#!" className="btn btn-default btn-outline btn-xs card-header__button">Expand Stats</a> */}
        <div style={{ display: "flex", position: "relative" }}>
          <button
            onClick={() => prevStep()}
            className="slick-prev slick-arrow"
            aria-label="Previous"
            type="button"
          >
            Previous
          </button>
          <button
            onClick={() => nextStep()}
            className="slick-next slick-arrow"
            aria-label="Next"
            type="button"
          >
            Next
          </button>
        </div>
      </div>

      {resultados.loading ||
      resultados.error ||
      !resultados.payload.length ? null : (
        <AutoPlaySwipeableViews
          axis="x"
          interval={9000}
          index={activeStep}
          onChangeIndex={handleStepChange}
        >
          {resultados.payload.length > 0
            ? resultados.payload.map((item, key) => (
                <div key={key} className="widget__content card__content">
                  <div className="widget-game-result__section">
                    <div className="widget-game-result__section-inner">
                      <div className="widget-game-result__main">
                        <div className="widget-game-result__team widget-game-result__team--first">
                          <figure className="widget-game-result__team-logo">
                            <Link
                              to={`/clube/${item.casa.id}/${createSlug(
                                item.casa.descricao
                              )}`}
                            >
                              <img
                                src={item.casa.urlLogo}
                                alt={item.casa.sigla_evento}
                              />
                            </Link>
                          </figure>
                          <div className="widget-game-result__team-info">
                            <Link
                              to={`/clube/${item.casa.id}/${createSlug(
                                item.casa.descricao
                              )}`}
                            >
                              <h5 className="widget-game-result__team-name">
                                {item.casa.sigla_evento}
                              </h5>
                            </Link>
                            <div className="widget-game-result__team-desc">
                              {item.casa.uf}
                            </div>
                          </div>
                          <span
                            className={`widget-game-result__score-result ${testWinner(
                              item.placar_final_casa,
                              item.placar_final_visitante
                            )}`}
                          >
                            {item.placar_final_casa}
                          </span>
                        </div>

                        {/* <div className="widget-game-result__score-wrap">
                                            <div className="widget-game-result__score">
                                                <span className={`widget-game-result__score-result ${testWinner(item.placar_final_casa, item.placar_final_visitante)}`}>{item.placar_final_casa}</span>
                                                <span className="widget-game-result__score-dash"> - </span>
                                                <span className={`widget-game-result__score-result ${testWinner(item.placar_final_visitante, item.placar_final_casa)}`}>{item.placar_final_visitante}</span>
                                            </div>
                                            <div className="widget-game-result__score-label">Placar Final</div>
                                        </div> */}
                        <div className="vs-divider">
                          <span>VS</span>
                        </div>
                        <div className="widget-game-result__team widget-game-result__team--second">
                          <figure className="widget-game-result__team-logo">
                            <Link
                              to={`/clube/${item.visitante.id}/${createSlug(
                                item.visitante.descricao
                              )}`}
                            >
                              <img
                                src={item.visitante.urlLogo}
                                alt={item.visitante.sigla_evento}
                              />
                            </Link>
                          </figure>
                          <div className="widget-game-result__team-info">
                            <Link
                              to={`/clube/${item.visitante.id}/${createSlug(
                                item.visitante.descricao
                              )}`}
                            >
                              <h5 className="widget-game-result__team-name">
                                {item.visitante.sigla_evento}
                              </h5>
                            </Link>
                            <div className="widget-game-result__team-desc">
                              {item.visitante.uf}
                            </div>
                          </div>
                          <span
                            className={`widget-game-result__score-result ${testWinner(
                              item.placar_final_visitante,
                              item.placar_final_casa
                            )}`}
                          >
                            {item.placar_final_visitante}
                          </span>
                        </div>
                        <header className="widget-game-result__header">
                          <Link
                            to={`/evento/${item.idEvento.id}/${createSlug(
                              item.idEvento.descricao
                            )}`}
                          >
                            <h3 className="widget-game-result__title">
                              {item.idEvento.descricao}
                            </h3>
                            /
                            <time
                              className="widget-game-result__date"
                              dateTime={convertISODateString(item.data)}
                            >
                              {convertDate(item.data)}
                            </time>
                          </Link>
                        </header>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </AutoPlaySwipeableViews>
      )}
    </aside>
  );
}
