import React from "react";

function RowTempo({ scoutTimeline, partida }) {
  return (
    <tr>
      <td className="alc-commentary-subheader" colspan="3">
        <div className="alc-commentary-subheader__icon">
          <i className="icon-svg icon-stopwatch"></i>
        </div>
        <div className="alc-commentary-subheader__title">
          {scoutTimeline.scout.descricao}
        </div>
        <div className="alc-commentary-subheader__subtitle">
          {scoutTimeline.tempo / 100}'
          {scoutTimeline.id_scout === 4 ? (
            <span>
              {scoutTimeline.timeCasa
                ? ` (${partida ? partida.casa.sigla_evento : ""})`
                : ` (${partida ? partida.visitante.sigla_evento : ""})`}
            </span>
          ) : (
            ""
          )}
        </div>
        <div>Quarto: {scoutTimeline.quarto}</div>
      </td>
    </tr>
  );
}

export default RowTempo;
