import { Avatar } from "@mui/material";
import React from "react";
import { createSlug } from "../../../util/createSlug";
import { Link } from "react-router-dom";
import { findIndex, groupBy } from "lodash";
import TableStaff from "./TableStaff";

function TableAtleta({
  atletas,
  partida,
  tipo,
  scoutsPartida,
  idEquipe,
  idPartida,
}) {
  const renderRowScouts = (scouts) => {
    const groupScouts = groupBy(scouts, "id_scout");
    const results = Object.entries(groupScouts);

    let teste;
    if (results) {
      let simpleResults = results.map((item) => ({
        id: item[0],
        qtd: item[1].length,
      }));
      teste = scoutsPartida.map((item) => {
        let itemIndex = findIndex(simpleResults, function (o) {
          return o.id === item.id.toString();
        });
        if (itemIndex !== -1) {
          return { qtd: simpleResults[itemIndex].qtd, obj: item };
        } else {
          return { qtd: 0, obj: {} };
        }
      });
    }

    return teste.map((item, key) => (
      <td key={key} style={{ minWidth: "6ch" }}>
        {item.qtd > 0 ? (
          <span>
            <i className={`icon-svg ${item.obj.icon}`}></i> {item.qtd}
          </span>
        ) : (
          item.qtd
        )}
      </td>
    ));
  };

  return (
    <div
      className={`widget card card--has-table ${
        tipo === "casa" ? "" : "card--alt-color"
      }`}
    >
      <div className="card__header">
        <h4>
          {tipo === "casa"
            ? partida.casa.sigla_evento
            : partida.visitante.sigla_evento}
        </h4>
      </div>
      <div className="card__content">
        <div className="table-responsive">
          <table className="table table-hover alc-table-stats alc-table-stats--compact-sm">
            <thead>
              <tr>
                <th
                  className="alc-number alc-align-start"
                  style={{ width: 90 }}
                >
                  Camisa
                </th>
                <th className="alc-player alc-align-start">Atleta</th>
                {scoutsPartida.length ? (
                  scoutsPartida.map((item, key) => (
                    <th className="alc-min" key={key} title={item.descricao}>
                      {item.sigla}
                    </th>
                  ))
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </tr>
            </thead>
            <tbody>
              {!atletas ? (
                <React.Fragment></React.Fragment>
              ) : (
                atletas.map((item, key) => (
                  <tr key={key}>
                    <td className="alc-number alc-align-start">
                      {item.num_camisa || item.numero}
                    </td>
                    <td className="alc-align-start">
                      <Link
                        to={`/atleta/${item.atleta.atleta.id}/${createSlug(
                          item.atleta.nome_evento
                            ? item.atleta.nome_evento
                            : item.atleta.atleta.nome_completo
                        )}`}
                      >
                        <div className="alc-player-info">
                          <Avatar
                            style={{ height: 26, width: 26, marginRight: 14 }}
                            src={`${process.env.REACT_APP_SGE_URL}_uploads/fotoAtleta/${item.atleta.atleta.foto}`}
                          />

                          <div className="alc-player-info__name">
                            {item.atleta.nome_evento
                              ? item.atleta.nome_evento
                              : item.atleta.atleta.nome_completo}
                          </div>
                        </div>
                      </Link>
                    </td>
                    {scoutsPartida.length ? (
                      renderRowScouts(item.scouts)
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </tr>
                ))
              )}
            </tbody>
            <TableStaff
              idEquipe={idEquipe}
              idPartida={idPartida}
              scoutsPartida={scoutsPartida}
            />
          </table>
        </div>
      </div>
    </div>
  );
}

export default TableAtleta;
