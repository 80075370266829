import React from "react";
import { Alert, Box } from "@mui/material";
// import forminscricao from '../../../assets/docs/formulario-inscrição.xlsx'

function BannerInscricao() {
  return (
    <Box sx={{ backgroundColor: "rgba(0,0,0,.3)" }}>
      <div className="container">
        <Alert
          icon={false}
          style={{
            minHeight: 100,
            padding: 20,
            margin: "0 8px",
            color: "#fff",
            backgroundColor: "transparent",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <label style={{ fontSize: ".8rem" }}>
            {`Formulário para Equipes interessadas a participar da LPF ${new Date().getFullYear()}`}
          </label>
          <br />
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSda1g3X2CmKz1QxoOtmfBy5ZjCMhDgNzjWtoKohssuqC_YfAQ/viewform"
            rel="noreferrer"
            variant="contained"
            size="small"
            className="btn btn-primary btn-xs"
            style={{ outline: "none", boxShadow: "none", fontWeight: "bold" }}
          >
            Clique Aqui
          </a>
        </Alert>
      </div>
    </Box>
  );
}

export default BannerInscricao;
