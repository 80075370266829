import { Avatar, Collapse, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createSlug } from "../../../../util/createSlug";
import _ from "lodash";
import RowPartida from "./RowPartida";
import { ConfedApi } from "../../../../services/api";
import { FormatResultadosClassificados } from "../../../../util/functions/Classificacao";

function ClassificacaoArtilheiros(props) {
  const { tipoCard, idEvento, handleChangeIdEvento, eventos, artilheiros } =
    props;
  const [idAtleta, setIdAtleta] = useState(0);
  const [partidas, setPartidas] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = (target, index, idAtleta) => {
    setExpanded({ [index]: !expanded[index] });
    setIdAtleta(idAtleta);
  };

  useEffect(() => {
    setPartidas({
      loading: true,
      error: false,
      payload: [],
    });
    if (idEvento && idAtleta) {
      const params = {
        id_scout: 1,
        "partida.id_evento": idEvento,
        "atleta.id_atleta": idAtleta,
        expand: "partida,partida.casa,partida.visitante,partida.idEvento",
        sort: "-data",
        pageSize: 200,
      };
      const isCBBC = process.env.REACT_APP_ESTABELECIMENTO === "CBBC";
      if (isCBBC) {
        delete params.id_scout;
        params.arrayIn = "id_scout:11|12|13";
        params.pageSize = 500;
      }

      async function getEventoPartidaScout() {
        await ConfedApi.get("evento-partida-scout", {
          params: params,
        })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setPartidas({
                loading: false,
                error: true,
                payload: [],
              });
            } else {
              const res = response.data.items;
              if (res.length) {
                return setPartidas({
                  loading: false,
                  error: false,
                  payload: FormatResultadosClassificados({
                    scouts: res,
                    isCBBC,
                  }),
                });
              }

              return setPartidas({
                loading: false,
                error: true,
                payload: [],
              });
            }
          })
          .catch((err) => {
            setPartidas({
              loading: false,
              error: true,
              payload: [],
            });
            console.log(err);
          });
      }
      getEventoPartidaScout();
    }
  }, [idEvento, idAtleta]);

  return (
    <div className="widget__content card__content">
      {tipoCard === "partida" || tipoCard === "grupos" ? null : (
        <select
          value={idEvento}
          onChange={handleChangeIdEvento}
          label="Selecione o evento"
          className="form-control"
        >
          {eventos.loading || eventos.error || !eventos.payload ? null : (
            <React.Fragment>
              {eventos.payload.map((item, key) => (
                <option value={item.id} key={key}>
                  {item.descricao}
                </option>
              ))}
            </React.Fragment>
          )}
        </select>
      )}

      <div className="table-responsive">
        <table className="table table-hover table-standings">
          <thead>
            <tr>
              <th>Atleta</th>
              <th>
                {process.env.REACT_APP_ESTABELECIMENTO === "CBFutsal"
                  ? "Gols"
                  : "PTS"}
              </th>
              <th></th>
            </tr>
          </thead>
          {artilheiros.loading || artilheiros.error || !artilheiros.payload ? (
            <tbody>
              <tr>
                <td colSpan="3">
                  <Skeleton />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {artilheiros.payload.map((item, key) => (
                <React.Fragment key={key}>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={(e) =>
                      handleExpandClick(
                        e.currentTarget,
                        key,
                        item.id_atleta_gestao
                      )
                    }
                  >
                    <td>
                      <div className="team-meta">
                        <Avatar
                          sx={{ mr: 1, height: 35, width: 35 }}
                          src={item.foto}
                          alt=""
                        />
                        <div className="team-meta__info">
                          <h6 className="team-meta__name">
                            {item.nome_atleta_evento}
                          </h6>
                          <span className="team-meta__place">
                            {item.sigla_clube}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>{item.soma}</td>
                    <td>
                      <i
                        style={{ fontSize: ".85rem", opacity: 0.7 }}
                        className={`fa ${
                          expanded[key] ? "fa-times" : "fa-plus"
                        }`}
                      ></i>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} style={{ padding: 0, border: "none" }}>
                      <Collapse in={expanded[key]} timeout="auto" unmountOnExit>
                        <RowPartida partidas={partidas} />
                      </Collapse>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          )}
          {artilheiros.error ||
          (!artilheiros.loading && !artilheiros.payload?.length) ? (
            <tbody>
              <tr>
                <td colSpan="5">
                  <div className="alert alert-info">
                    <strong>
                      Não foram encontrados artilheiros para esse evento.
                    </strong>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : null}
        </table>
      </div>
      {tipoCard === "partida" || tipoCard === "grupos" ? null : (
        <div>
          {eventos.loading ||
          eventos.error ||
          !eventos.payload.length ||
          !idEvento ? null : (
            <div className="p-2 text-right">
              <Link
                className="btn btn-primary-inverse btn-xs"
                to={`/evento/${idEvento}/${createSlug(
                  _.filter(eventos.payload, function (o) {
                    return o.id === Number(idEvento);
                  })[0].descricao
                )}`}
              >
                ver evento
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ClassificacaoArtilheiros;
