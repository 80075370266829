import { Skeleton } from "@mui/material";
import { chain, filter } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import RowHandler from "./TableRows/RowHandler";

export default function PlayByPlay(props) {
  const { eventoPartidaScouts, partidasDestaque } = props;

  const timeline = useMemo(() => {
    if (
      !eventoPartidaScouts.loading &&
      eventoPartidaScouts.payload.scouts &&
      eventoPartidaScouts.payload.scouts.length
    ) {
      const scouts = eventoPartidaScouts.payload.scouts;

      //remove scouts por id
      let filteredScouts = scouts;

      if (process.env.REACT_APP_ESTABELECIMENTO !== "CBBC") {
        filteredScouts = filter(scouts, function (o) {
          return o.scout.tipo_render;
        });
      }

      let timeline = filteredScouts.map((item) => {
        let timeCasa = Boolean(
          item.id_equipe === eventoPartidaScouts.payload.casa.id
        );
        return {
          ...item,
          timeCasa: timeCasa,
        };
      });

      const organizedTimeline = chain(timeline)
        .sortBy("tempo")
        .groupBy("quarto")
        .toArray()
        .flatten()
        .reverse()
        .value();

      let fullTimeline = {
        inicio: moment(organizedTimeline[0].data).format("HH:mm:ss"),
        fim: moment(organizedTimeline.pop().data).format("HH:mm:ss"),
        payload: organizedTimeline,
      };
      return fullTimeline;
    }
    return null;
  }, [eventoPartidaScouts]);

  const [resultado, setResultado] = useState({
    empate: false,
    vencedor: { nome: "", placar: "" },
    perdedor: { nome: "", placar: "" },
  });

  useEffect(() => {
    if (partidasDestaque) {
      if (
        partidasDestaque.placar_final_casa >
        partidasDestaque.placar_final_visitante
      ) {
        setResultado({
          empate: false,
          vencedor: {
            nome: partidasDestaque.casa.sigla_evento,
            placar: partidasDestaque.placar_final_casa,
          },
          perdedor: {
            nome: partidasDestaque.visitante.sigla_evento,
            placar: partidasDestaque.placar_final_visitante,
          },
        });
      } else {
        if (
          partidasDestaque.placar_final_casa ===
          partidasDestaque.placar_final_visitante
        ) {
          setResultado({
            empate: true,
            vencedor: { nome: "", placar: "" },
            perdedor: { nome: "", placar: "" },
          });
        } else {
          setResultado({
            empate: false,
            vencedor: {
              nome: partidasDestaque.visitante.sigla_evento,
              placar: partidasDestaque.placar_final_visitante,
            },
            perdedor: {
              nome: partidasDestaque.casa.sigla_evento,
              placar: partidasDestaque.placar_final_casa,
            },
          });
        }
      }
    }
  }, [partidasDestaque]);

  return (
    <div className="card card--has-table alc-match-commentary-wrapper">
      {eventoPartidaScouts.loading ? (
        <div className="card__header card__header--double">
          <h4>
            <Skeleton variant="text" width={100} />
          </h4>
          <h4>
            <Skeleton variant="text" width={100} />
          </h4>
        </div>
      ) : (
        <div className="card__header card__header--double">
          <h4>{partidasDestaque ? partidasDestaque.casa.sigla_evento : ""}</h4>
          <h4>
            {partidasDestaque ? partidasDestaque.visitante.sigla_evento : ""}
          </h4>
        </div>
      )}
      {!timeline || !timeline.payload ? (
        <div className="alert alert-info mb-0">Nenhum dado encontrado</div>
      ) : (
        <div className="card__content">
          <table className="table alc-match-commentary">
            {/* <thead>
              <tr>
                <th colSpan="3">Término - {timeline.fim || ""}</th>
              </tr>
            </thead> */}
            <tbody>
              {timeline.payload &&
                timeline.payload.map((item, key) => (
                  <RowHandler
                    key={key}
                    scoutTimeline={item}
                    resultado={resultado}
                    partida={partidasDestaque}
                  />
                ))}
            </tbody>
            <thead>
              <tr>
                <th colSpan="3">Início - {timeline.inicio || ""}</th>
              </tr>
            </thead>
          </table>
        </div>
      )}
    </div>
  );
}
