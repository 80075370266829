import { ConfedApi } from "../services/api";

export async function fetchTipoScouts(props) {
  const res = await ConfedApi.get("tipo-scout", {
    params: {
      pageSize: 100,
    },
  });
  const data = res.data.items;
  return data;
}
