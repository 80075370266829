export const menuFEDCBHb = [


    {
        label: 'CAMPEONATOS',
        url: '/eventos',
        filho: null,
    },


]

