import React from "react";
import { Link } from "react-router-dom";
import { TabPanel, TemplateTab, TemplateTabs } from "../../../components/Tabs";
import ClassificacaoAgrupada from "../sections/Classificacao/ClassificacaoAgrupada";
import ClassificacaoPorEvento from "./Classificacao/ClassificacaoPorEvento";
import ClassificacaoArtilheiros from "./Classificacao/ClassificacaoArtilheiros";

export default function Classificacao(props) {
  const {
    eventos,
    eventoClassificacao,
    idEvento,
    handleChangeIdEvento,
    groupClassificacao,
    artilheiros,
    tipoCard,
  } = props;

  const isFABR = Boolean(process.env.REACT_APP_ESTABELECIMENTO === "FABR");
  const artilheiroLabel =
    process.env.REACT_APP_ESTABELECIMENTO === "CBBC"
      ? "Cestinhas"
      : "Artilheiros";

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  if (tipoCard === "grupos") {
    return (
      <aside className="widget card widget--sidebar widget-standings">
        <div className="widget__title card__header card__header--has-btn">
          <h4>Classificação</h4>
        </div>
        <div className="widget__content card__content">
          <TemplateTabs
            color="primary"
            variant="fullWidth"
            value={tabIndex}
            onChange={handleChange}
          >
            <TemplateTab label="Times" />
            {isFABR ? null : <TemplateTab label={artilheiroLabel} />}
          </TemplateTabs>
          <TabPanel value={tabIndex} index={0}>
            <ClassificacaoAgrupada groupClassificacao={groupClassificacao} />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <ClassificacaoArtilheiros
              artilheiros={artilheiros}
              tipoCard={tipoCard}
              idEvento={idEvento}
              handleChangeIdEvento={handleChangeIdEvento}
              eventos={eventos}
            />
          </TabPanel>
        </div>
      </aside>
    );
  }

  if (tipoCard === "evento") {
    return (
      <aside className="widget card widget--sidebar widget-standings">
        <div className="widget__title card__header card__header--has-btn">
          <h4>Classificação</h4>
        </div>
        <div className="widget__content card__content">
          <TemplateTabs
            color="primary"
            variant="fullWidth"
            value={tabIndex}
            onChange={handleChange}
          >
            <TemplateTab label="Times" />
            {isFABR ? null : <TemplateTab label={artilheiroLabel} />}
          </TemplateTabs>
          <TabPanel value={tabIndex} index={0}>
            <ClassificacaoPorEvento
              idEvento={idEvento}
              handleChangeIdEvento={handleChangeIdEvento}
              eventos={eventos}
              eventoClassificacao={eventoClassificacao}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <ClassificacaoArtilheiros
              artilheiros={artilheiros}
              tipoCard={tipoCard}
              idEvento={idEvento}
              handleChangeIdEvento={handleChangeIdEvento}
              eventos={eventos}
            />
          </TabPanel>
        </div>
      </aside>
    );
  }

  if (tipoCard === "partida") {
    return (
      <aside className="widget card widget--sidebar widget-standings">
        <div className="widget__title card__header card__header--has-btn">
          <h4>Classificação</h4>
        </div>
        <div className="widget__content card__content">
          <TemplateTabs
            color="primary"
            variant="fullWidth"
            value={tabIndex}
            onChange={handleChange}
          >
            <TemplateTab label="Times" />
            {isFABR ? null : <TemplateTab label={artilheiroLabel} />}
          </TemplateTabs>
          <TabPanel value={tabIndex} index={0}>
            <ClassificacaoAgrupada groupClassificacao={groupClassificacao} />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <ClassificacaoArtilheiros
              artilheiros={artilheiros}
              tipoCard={tipoCard}
              idEvento={idEvento}
              handleChangeIdEvento={handleChangeIdEvento}
              eventos={eventos}
            />
          </TabPanel>
        </div>
      </aside>
    );
  }

  return (
    <aside className="widget card widget--sidebar widget-standings">
      <div className="widget__title card__header card__header--has-btn">
        <h4>Classificação por Campeonato</h4>
        <Link
          to="/eventos"
          className="btn btn-default btn-outline btn-xs card-header__button"
        >
          ver todos
        </Link>
      </div>

      <ClassificacaoPorEvento
        idEvento={idEvento}
        handleChangeIdEvento={handleChangeIdEvento}
        eventos={eventos}
        eventoClassificacao={eventoClassificacao}
      />
    </aside>
  );
}
