import { Alert, Snackbar } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import ReactInputMask from "react-input-mask";
import ouvidoriaFields from "../../assets/data/ouvidoriaFields";
import OuvidoriaCBBC from "../../components/CBBC/OuvidoriaCBBC";

import PageHeader from "../../components/PageHeader";
import MetaContext from "../../context/MetaContext";
import { governancaApi } from "../../services/api";
import geraProtocolo from "../../util/geraProtocolo";
import { contatoFaleConosco, contatoOuvidoria } from "./TiposContato";

export default function ContatoPage(props) {
  const { siteMeta } = useContext(MetaContext);
  const { tipoContato } = props;
  const breadcrumb = [
    { label: "HOME", url: "/", active: false },
    { label: "Contato", url: "/contato", active: true },
  ];
  const [formValues, setFormValues] = useState(ouvidoriaFields);

  const [protocolo, setProtocolo] = useState("");

  const [notRobot, setNotRobot] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [storedCaptchaToken, setStoredCaptchaToken] = useState("");
  const [mensagemAnonima, setMensagemAnonima] = useState(false);

  const [stateTipoContato, setStateTipoContato] = useState(contatoOuvidoria);

  useState(() => {
    switch (tipoContato) {
      case "ouvidoria":
        setStateTipoContato(contatoOuvidoria);
        break;
      case "fale-conosco":
        setStateTipoContato(contatoFaleConosco);
        break;
      default:
        setStateTipoContato({ ...contatoOuvidoria, titulo: "Contato" });
        break;
    }
  }, [tipoContato]);

  const onChangeCaptcha = (value) => {
    setNotRobot(true);
    setCaptchaToken(value);
  };

  function validateForm(event) {
    event.preventDefault();
    if (notRobot && captchaToken) {
      if (captchaToken === storedCaptchaToken) {
        alert("Robô detectado");
      } else {
        var protocolo = geraProtocolo();
        var datenow = Math.round(new Date() / 1000);
        if (mensagemAnonima) {
          setFormValues({
            ...formValues,
            nome_solicitante: "anônimo",
            telefone: "",
            protocolo: protocolo,
            updated_at: datenow,
            created_at: datenow,
            tipo_contato: stateTipoContato.motivo,
          });
        } else {
          setFormValues({
            ...formValues,
            protocolo: protocolo,
            updated_at: datenow,
            created_at: datenow,
            tipo_contato: stateTipoContato.motivo,
          });
        }
        setProtocolo(protocolo);
        setStoredCaptchaToken(captchaToken);
      }
    } else {
      setSnackState({
        severity: "error",
        text: "Valide o captcha para enviar a mensagem!",
        open: true,
      });
    }
  }

  const handleChangeValues = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const [snackState, setSnackState] = useState({
    severity: "",
    text: "",
    open: false,
  });

  const handleCloseSnack = () => {
    setSnackState({
      severity: "",
      text: "",
      open: false,
    });
  };

  useEffect(() => {
    if (formValues.created_at !== "") {
      async function postFornecedor() {
        await governancaApi
          .post("solicitacao-ouvidoria", formValues)
          .then((response) => {
            setFormValues({
              ...formValues,
              nome_solicitante: "",
              telefone: "",
              email: "",
              motivo: "",
              mensagem: "",
              created_at: "",
              updated_at: "",
              created_by: "",
              updated_by: "",
            });
            setSnackState({
              severity: "success",
              text: "Mensagem enviada com sucesso!",
              open: true,
            });
            setPhone("");
          })
          .catch((err) => {
            console.error(err);
            setFormValues({
              ...formValues,
              created_at: "",
              updated_at: "",
            });
            setSnackState({
              severity: "error",
              text: "Erro ao enviar mensagem. Verifique o preenchimento dos campos e tente novamente.",
              open: true,
            });
            setProtocolo("");
          });
      }
      postFornecedor();
    }
  }, [formValues.created_at, formValues, protocolo]);

  const [phone, setPhone] = useState("");

  const changePhone = (e) => {
    var _phone = e.target.value;
    setPhone(_phone);
    if (_phone) {
      setFormValues({
        ...formValues,
        telefone: _phone
          .replace(/ /g, "")
          .replace(/-/g, "")
          .replace(/\(|\)/g, ""),
      });
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Contato - ${siteMeta.siteTitle}`}</title>
      </Helmet>
      <div>
        <PageHeader title={stateTipoContato.titulo} breadcrumb={breadcrumb} />
        <div className="site-content">
          <div className="container">
            <div className="widget card">
              <header className="card__header">
                <h4> Formulário de contato</h4>
              </header>
              <div className="card__content">
                <div className="row">
                  <div className="col-lg-4">
                    {process.env.REACT_APP_ESTABELECIMENTO === "CBBC" ? (
                      <OuvidoriaCBBC />
                    ) : (
                      <React.Fragment>
                        <h5>Perguntas? Envie-nos uma mensagem!</h5>
                        <p>
                          A sua opinição é importante para a{" "}
                          {siteMeta.descricao_estabelecimento
                            ? siteMeta.descricao_estabelecimento
                            : ""}
                          . Utilize o formulário ao lado para nos contactar.
                        </p>
                        <div className="spacer-sm"></div>
                      </React.Fragment>
                    )}
                  </div>
                  <div className="col-lg-8">
                    <form onSubmit={validateForm} className="contact-form">
                      <Alert
                        severity="info"
                        style={
                          protocolo !== ""
                            ? { marginBottom: "1rem" }
                            : { display: "none" }
                        }
                      >
                        Seu protocolo é: {protocolo}
                      </Alert>

                      <div className="row">
                        {mensagemAnonima ? (
                          ""
                        ) : (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label for="contact-form-name">
                                Nome{" "}
                                {!mensagemAnonima ? (
                                  <span className="required">*</span>
                                ) : (
                                  ""
                                )}
                              </label>
                              <input
                                label="Nome"
                                value={formValues.nome_solicitante}
                                required={!mensagemAnonima}
                                type="name"
                                name="nome_solicitante"
                                onChange={handleChangeValues}
                                className="form-control"
                                placeholder="Seu nome completo..."
                              />
                            </div>
                          </div>
                        )}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="contact-form-email">
                              Email <span className="required">*</span>
                            </label>
                            <input
                              label="Email"
                              value={formValues.email}
                              required
                              type="email"
                              name="email"
                              onChange={handleChangeValues}
                              className="form-control"
                              placeholder="Seu email..."
                            />
                          </div>
                        </div>
                        {mensagemAnonima ? (
                          ""
                        ) : (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label for="contact-form-email">
                                Telefone <span className="required">*</span>
                              </label>
                              <ReactInputMask
                                className="form-control"
                                mask="(99) 99999-9999"
                                value={phone}
                                onChange={changePhone}
                                name="telefone"
                                placeholder="(99) 99999-9999"
                                maskChar=" "
                              />
                            </div>
                          </div>
                        )}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="contact-form-subject">Assunto</label>
                            <input
                              type="text"
                              label="Nome"
                              value={formValues.motivo}
                              required
                              name="motivo"
                              onChange={handleChangeValues}
                              className="form-control"
                              placeholder="Assunto da mensagem..."
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label for="contact-form-message">
                              Mensagem <span className="required">*</span>
                            </label>
                            <textarea
                              label="Mensagem"
                              value={formValues.mensagem}
                              required
                              name="mensagem"
                              onChange={handleChangeValues}
                              rows="5"
                              className="form-control"
                              placeholder="Escreva sua mensagem aqui..."
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="checkbox checkbox-inline mr-2">
                              <input
                                type="checkbox"
                                value={mensagemAnonima}
                                onChange={() =>
                                  setMensagemAnonima(!mensagemAnonima)
                                }
                              />{" "}
                              Deseja enviar de forma anônima?
                              <span className="checkbox-indicator"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <ReCAPTCHA
                              hl="pt"
                              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                              onChange={onChangeCaptcha}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group form-group--submit">
                        <button
                          type="submit"
                          className="btn btn-primary-inverse btn-lg btn-block"
                        >
                          Enviar mensagem
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          open={snackState}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
        >
          <Alert severity={snackState.severity} sx={{ width: "100%" }}>
            {snackState.text}
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
}
