const escopoOptions = [
    {
        label: "Estadual",
        value: "Estadual"
    },
    {
        label: "Nacional",
        value: "Nacional"
    },
    {
        label: "Internacional",
        value: "Internacional"
    },
]
export default escopoOptions;