import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { convertDateTime } from '../util/convertDate'
import { createSlug } from '../util/createSlug'
import moment from 'moment';

// import avatarImg from '../assets/images/samples/avatar-1.jpg'
import postImg from '../assets/images/samples/post-img1.jpg'
import ShareLinks from './ShareLinks'
import { ClickAwayListener, Paper, Popper, Skeleton } from '@mui/material'

export default function CardNoticiaV1(props) {
    // const imgAvatarFallback = (e) => {
    //     e.target.src = avatarImg
    // }
    const { noticia, loading } = props

    const imgPostFallback = (e) => {
        e.target.src = postImg
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);

    const handlePopoverOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu({ [index]: !openMenu[index] })
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setOpenMenu(false)
    };


    let linkNoticia = ''
    if (!loading) {
        linkNoticia = `https://${window.location.hostname}/noticias/leitura/${noticia.id}/${createSlug(noticia.titulo)}`
    }

    if (loading) {
        return (
            <div className="posts__item posts__item--card posts__item--category-1 card">
                <figure className="posts__thumb">
                    <Skeleton height={250} width={'100%'} variant="rectangular" />
                </figure>
                <div className="posts__inner card__content">
                    <h6 className="posts__title">
                        <Skeleton variant="text" />
                    </h6>
                    <Skeleton variant="text" style={{ width: 40 }} />
                    <div className="posts__excerpt">
                        <Skeleton variant="text" width={'80%'} />
                        <Skeleton variant="text" width={'60%'} />
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={`posts__item posts__item--card posts__item--category-${noticia.id_esporte} card`}>
            <figure className="posts__thumb">

                <Link to={`/noticias/leitura/${noticia.id}/${createSlug(noticia.titulo)}`}>
                    <img src={noticia.foto_capa ? `${process.env.REACT_APP_SGE_URL}_uploads/${process.env.REACT_APP_ID_ESTABELECIMENTO}/noticias/${noticia.foto_capa}` : ''}
                        onError={(e) => imgPostFallback(e)}
                        alt="" />
                </Link>
            </figure>
            <div className="posts__inner card__content">
                <h6 className="posts__title">
                    <Link to={`/noticias/leitura/${noticia.id}/${createSlug(noticia.titulo)}`}>
                        {noticia.titulo}
                    </Link>
                </h6>
                <div className="mt-2 pt-1 d-flex align-items-center">
                    <div className="posts__cat mb-0">
                        <span className="label posts__cat-label">
                            {noticia.idEsporte ? noticia.idEsporte.descricao : ''}
                        </span>
                    </div>
                    <time dateTime={moment(new Date(noticia.created_at * 1000)).format().substr(0, 10)} className="posts__date">
                        <i className="far fa-clock"></i>
                        {convertDateTime(noticia.created_at * 1000)}
                    </time>
                </div>
                <div className="posts__excerpt">
                    {noticia.resumo}
                </div>
            </div>
            <footer className="posts__footer card__footer">
                <Link to={`/noticias/leitura/${noticia.id}/${createSlug(noticia.titulo)}`} className="btn btn-default  btn-outline btn-sm">leia mais</Link>
                <button onClick={e => handlePopoverOpen(e, noticia.id)} className="btn btn-default  btn-outline btn-sm"><i className="fas fa-share-alt"></i></button>

                <Popper open={Boolean(openMenu[noticia.id])} anchorEl={anchorEl}>
                    <ClickAwayListener onClickAway={handlePopoverClose} >
                        <Paper sx={{ p: 0.5, display: 'flex', flexDirection: 'column' }}>
                            <ShareLinks shareUrl={linkNoticia} />
                        </Paper>
                    </ClickAwayListener>
                </Popper>

            </footer>
        </div>
    )
}
