import { ConfedApi } from "../services/api";

export async function fetchEventoPartida(props) {
  const { evento } = props;

  const res = await ConfedApi.get(`evento-partida`, {
    params: {
      id_evento: evento.id,
      pageSize: "100",
      expand: "casa,visitante",
      notField: "fase",
      notValue: "grupo",
    },
  });
  const data = res.data.items;
  return data;
}

export async function fetchEventoPartidaPorId(props) {
  const { idPartida } = props;

  const res = await ConfedApi.get(`evento-partida/${idPartida}`, {
    params: {
      expand: "casa,visitante,idEvento",
      notField: "fase",
      notValue: "grupo",
    },
  });
  const data = res.data;
  return data;
}
