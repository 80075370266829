import { Skeleton, Typography } from "@mui/material";
import React from "react";
import { createSlug } from "../../../../util/createSlug";
import { Link } from "react-router-dom";
import { convertDate } from "../../../../util/convertDate";

const isCBBC = process.env.REACT_APP_ESTABELECIMENTO === "CBBC";

const testWinner = (pts1, pts2) => {
  if (pts1 > pts2) {
    return "widget-results__score-winner";
  } else {
    return "widget-results__score-loser";
  }
};

function RowPartida(props) {
  const { partidas } = props;
  return (
    <table className="table" style={{ margin: 0 }}>
      <tbody>
       
        {partidas.loading || partidas.error
          ? Array.from(new Array(2)).map((item, key) => (
              <React.Fragment key={key}>
                <tr>
                  <td
                    colSpan="3"
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 150,
                      overflow: "hidden",
                    }}
                    className="d-table-cell widget-results__header justify-content-center bg-light"
                  >
                    <Typography
                      variant="body2"
                      color="primary"
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "100%",
                      }}
                    >
                      {isCBBC ? (
                        <i className="icon-svg icon-basketball"></i>
                      ) : (
                        <i className="icon-svg icon-soccer-ball"></i>
                      )}

                      <Skeleton width="80%" />
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td className="alc-position alc-align-start bg-white">
                    <div className="widget-results__team widget-results__team--first">
                      <div
                        className="widget-results__team-details"
                        style={{ width: "80%" }}
                      >
                        <h5 className="widget-results__team-name">
                          <Skeleton variant="text" width="100%" />
                        </h5>
                        <span className="widget-results__team-info">
                          <Skeleton variant="text" width="100%" />
                        </span>
                      </div>
                      <figure className="widget-results__team-logo">
                        <Skeleton variant="circular" width={35} height={35} />
                      </figure>
                    </div>
                  </td>
                  <td className="bg-white" style={{ width: 20 }}>
                    <div className="alc-event-scheduled__divider">vs</div>
                  </td>
                  <td className="bg-white">
                    <div className="widget-results__team widget-results__team--second">
                      <figure className="widget-results__team-logo">
                        <Skeleton variant="circular" width={35} height={35} />
                      </figure>
                      <div
                        className="widget-results__team-details"
                        style={{ width: "70%" }}
                      >
                        <h5 className="widget-results__team-name">
                          <Skeleton variant="text" width="100%" />
                        </h5>
                        <span className="widget-results__team-info">
                          <Skeleton variant="text" width="100%%" />
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            ))
          : partidas.payload.map((item, key) => {
              let linkpartida = "";
              if (item.partidaScout.partida) {
                linkpartida = `/evento/${
                  item.partidaScout.partida.id_evento
                }/${createSlug(
                  item.partidaScout.partida.idEvento.descricao
                )}/partida/${item.partidaScout.partida.id}`;
              }

              return (
                <React.Fragment key={key}>
                  <tr>
                    <td
                      colSpan="3"
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: 150,
                        overflow: "hidden",
                      }}
                      className="d-table-cell widget-results__header justify-content-center bg-light"
                    >
                      <Link to={linkpartida}>
                        <Typography
                          variant="body2"
                          color="primary"
                          sx={{
                            fontWeight: "bold",
                            fontFamily: "inherit",
                            fontSize: "inherit",
                            display: "inline",
                          }}
                        >
                          {item.gols}
                          <span style={{ textTransform: "lowercase" }}>
                            x
                          </span>{" "}
                          {isCBBC ? (
                            <i className="icon-svg icon-basketball"></i>
                          ) : (
                            <i className="icon-svg icon-soccer-ball"></i>
                          )}{" "}
                        </Typography>
                        <span style={{ color: "#9a9da2" }}>
                          <b>{convertDate(item.partidaScout.partida.data)}</b> -{" "}
                          {item.partidaScout.partida.fase}
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="alc-position alc-align-start bg-white"
                      style={{ width: "40%" }}
                    >
                      <Link
                        to={
                          item.partidaScout.partida.casa
                            ? `/clube/${
                                item.partidaScout.partida.casa.id
                              }/${createSlug(
                                item.partidaScout.partida.casa.descricao
                              )}`
                            : "/eventos"
                        }
                      >
                        <div className="widget-results__team widget-results__team--first">
                          <div className="widget-results__team-details">
                            <h5 className="widget-results__team-name">
                              {item.partidaScout.partida.casa.sigla_evento.replaceAll(
                                "/",
                                " / "
                              )}
                            </h5>
                            <span className="widget-results__team-info">
                              {item.partidaScout.partida.casa.uf}
                            </span>
                          </div>
                          <figure className="widget-results__team-logo">
                            <img
                              src={item.partidaScout.partida.casa.urlLogo}
                              alt={item.partidaScout.partida.casa.sigla_evento}
                            />
                          </figure>
                        </div>
                      </Link>
                    </td>
                    <td
                      className="bg-white"
                      style={{ verticalAlign: "middle" }}
                    >
                      <div className="widget-results__result">
                        <div className="widget-results__score">
                          <Link to={linkpartida} style={{ color: "#31404b" }}>
                            <span
                              className={testWinner(
                                item.partidaScout.partida.placar_final_casa,
                                item.partidaScout.partida.placar_final_visitante
                              )}
                            >
                              {item.partidaScout.partida.placar_final_casa}
                            </span>
                            -
                            <span
                              className={testWinner(
                                item.partidaScout.partida
                                  .placar_final_visitante,
                                item.partidaScout.partida.placar_final_casa
                              )}
                            >
                              {item.partidaScout.partida.placar_final_visitante}
                            </span>
                            <div className="widget-results__status"></div>
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="bg-white" style={{ width: "40%" }}>
                      <Link
                        to={
                          item.partidaScout.partida.visitante
                            ? `/clube/${
                                item.partidaScout.partida.visitante.id
                              }/${createSlug(
                                item.partidaScout.partida.visitante.descricao
                              )}`
                            : "/clubes"
                        }
                      >
                        <div className="widget-results__team widget-results__team--second">
                          <figure className="widget-results__team-logo">
                            <img
                              src={item.partidaScout.partida.visitante.urlLogo}
                              alt={
                                item.partidaScout.partida.visitante.sigla_evento
                              }
                            />
                          </figure>
                          <div className="widget-results__team-details">
                            <h5 className="widget-results__team-name">
                              {item.partidaScout.partida.visitante.sigla_evento.replaceAll(
                                "/",
                                " / "
                              )}
                            </h5>
                            <span className="widget-results__team-info">
                              {item.partidaScout.partida.visitante.uf}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="3"
                      className="bg-white pt-0 pb-1"
                      style={{ borderTop: "none" }}
                    >
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      {item.partidaScout.partida.local}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
      </tbody>
    </table>
  );
}

export default RowPartida;
