import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { ConfedApi, gestaoApi } from "../../services/api";
import CampeonatosClube from "./sections/CampeonatosClube";
import ProximasPatidas from "./sections/ProximasPatidas";
import UltimoResultado from "./sections/UltimoResultado";
import ResultadosAside from "../home/sections/ResultadosAside";
import { FilterContainer, FilterItem, TabPanel } from "../../components/Tabs";
import TabAtletas from "./sections/TabAtletas";
import moment from "moment";

// function SampleNextArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//       <div
//         className={className}
//         style={{ ...style, display: "block", background: "red" }}
//         onClick={onClick}
//       />
//     );
//   }

//   function SamplePrevArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//       <div
//         className={className}
//         style={{ ...style, display: "block", background: "green" }}
//         onClick={onClick}
//       />
//     );
//   }

export default function ClubePage(props) {
  const { id } = useParams();
  const { siteTitle } = props;

  const [estabelecimento, setEstabelecimento] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setEstabelecimento({
      loading: true,
      error: false,
      payload: [],
    });
    async function getEvento() {
      await gestaoApi
        .get("estabelecimento", {
          params: {
            id: id,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setEstabelecimento({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setEstabelecimento({
              loading: false,
              error: false,
              payload: response.data.items[0],
            });
          }
        })
        .catch((err) => {
          setEstabelecimento({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getEvento();
  }, [id]);

  const [eventoClassificacao, setEventoClassificacao] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    if (id) {
      setEventoClassificacao({
        loading: true,
        error: false,
        payload: [],
      });
      async function getEventoClassificacao() {
        await ConfedApi.get("evento-classificacao", {
          params: {
            id_estabelecimento: id,
            expand: "idEstabelecimento,idEvento",
            sort: "-num_pontos",
          },
        })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setEventoClassificacao({
                loading: false,
                error: true,
                payload: [],
              });
            } else {
              setEventoClassificacao({
                loading: false,
                error: false,
                payload: response.data.items,
              });
            }
          })
          .catch((err) => {
            setEventoClassificacao({
              loading: false,
              error: true,
              payload: [],
            });
            console.log(err);
          });
      }
      getEventoClassificacao();
    }
  }, [id]);

  const [ultimaPartida, setUltimaPartida] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    if (id) {
      setUltimaPartida({
        loading: true,
        error: false,
        payload: [],
      });
      async function getUltimaPartida() {
        await ConfedApi.get("evento-partida", {
          params: {
            forcewhere: `idEvento.id_estabelecimento:${process.env.REACT_APP_ID_ESTABELECIMENTO}`,
            arrayLike: `id_estabelecimento_casa:${id},id_estabelecimento_visitante:${id}`,
            forcejoin: "idEvento,casa,visitante",
            expand: "idEvento,casa,visitante,eventoGrupo",
            notNullField: "placar_final_casa",
            sort: "-data",
            pageSize: 1,
          },
        })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setUltimaPartida({
                loading: false,
                error: true,
                payload: [],
              });
            } else {
              let placarCasa = response.data.items[0].placar_final_casa;
              let placarVisitante =
                response.data.items[0].placar_final_visitante;
              let vencedor = {};
              let perdedor = {};
              if (placarCasa > placarVisitante) {
                vencedor = { ...response.data.items[0].casa, pts: placarCasa };
                perdedor = {
                  ...response.data.items[0].visitante,
                  pts: placarVisitante,
                };
              } else {
                vencedor = {
                  ...response.data.items[0].visitante,
                  pts: placarVisitante,
                };
                perdedor = { ...response.data.items[0].casa, pts: placarCasa };
              }

              setUltimaPartida({
                loading: false,
                error: false,
                payload: { ...response.data.items[0], vencedor, perdedor },
              });
            }
          })
          .catch((err) => {
            setUltimaPartida({
              loading: false,
              error: true,
              payload: [],
            });
            console.log(err);
          });
      }
      getUltimaPartida();
    }
  }, [id]);

  const [proximasPartidas, setProximasPartidas] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    if (id) {
      setProximasPartidas({
        loading: true,
        error: false,
        payload: [],
      });
      async function getProximasPartidas() {
        await ConfedApi.get("evento-partida", {
          params: {
            forcewhere: `idEvento.id_estabelecimento:${process.env.REACT_APP_ID_ESTABELECIMENTO}`,
            arrayLike: `id_estabelecimento_casa:${id},id_estabelecimento_visitante:${id}`,
            forcejoin: "idEvento,casa,visitante",
            expand: "idEvento,casa,visitante,eventoGrupo",
            moreField: "data",
            moreValue: moment(new Date()).format(),
            sort: "-data",
          },
        })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setProximasPartidas({
                loading: false,
                error: true,
                payload: [],
              });
            } else {
              setProximasPartidas({
                loading: false,
                error: false,
                payload: response.data.items,
              });
            }
          })
          .catch((err) => {
            setProximasPartidas({
              loading: false,
              error: true,
              payload: [],
            });
            console.log(err);
          });
      }
      getProximasPartidas();
    }
  }, [id]);

  const [resultados, setResultados] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setResultados({
      loading: true,
      error: false,
      payload: [],
    });
    async function getEventoPartida() {
      await ConfedApi.get("evento-partida", {
        params: {
          forcewhere: `idEvento.id_estabelecimento:${process.env.REACT_APP_ID_ESTABELECIMENTO}`,
          arrayLike: `id_estabelecimento_casa:${id},id_estabelecimento_visitante:${id}`,
          forcejoin: "idEvento,casa,visitante",
          lessField: "data",
          lessValue: moment(new Date()).format(),
          expand: "idEvento,casa,visitante",
          sort: "-data",
          pageSize: 10,
        },
      })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setResultados({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setResultados({
              loading: false,
              error: false,
              payload: response.data.items.slice(1, 10),
            });
          }
        })
        .catch((err) => {
          setResultados({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getEventoPartida();
  }, [id]);

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <React.Fragment>
      {estabelecimento.loading ? (
        ""
      ) : (
        <Helmet>
          <title>{`${estabelecimento.payload.descricao} - ${siteTitle}`}</title>
        </Helmet>
      )}
      <div className="root-clube">
        <div className="page-heading">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                {estabelecimento.loading || estabelecimento.error ? (
                  ""
                ) : (
                  <React.Fragment>
                    <img
                      className="img-clube"
                      src={estabelecimento.payload.urlLogo}
                      alt={estabelecimento.payload.descricao}
                    />
                    <h1 className="page-heading__title">
                      {estabelecimento.payload.descricao}
                    </h1>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>

        <FilterContainer
          color="primary"
          value={tabIndex}
          onChange={handleChange}
        >
          <FilterItem label="Clube" />
          <FilterItem label="Atletas" />
        </FilterContainer>
        <TabPanel value={tabIndex} index={0}>
          <div className="site-content">
            <div className="container">
              <div className="row">
                <div className="content col-lg-8">
                  <UltimoResultado ultimaPartida={ultimaPartida} />
                  <ProximasPatidas clubeId={id} partidas={proximasPartidas} />
                </div>
                <div className="sidebar col-lg-4">
                  <CampeonatosClube eventoClassificacao={eventoClassificacao} />
                  <ResultadosAside resultados={resultados} />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <TabAtletas clubeId={id} />
        </TabPanel>
      </div>
    </React.Fragment>
  );
}
