import { ConfedApi } from "../services/api";

export async function fetchEventoGrupo(props) {
  const { idEvento } = props;

  const res = await ConfedApi.get("evento-grupo", {
    params: {
      forcejoin: "eventoGrupoClubes,eventoClassificacaos",
      forcewhere: `evento_grupo.id_evento:${idEvento}`,
      pageSize: "100",
      expand:
        "eventoGrupoClubes,eventoGrupoClubes.estabelecimento,eventoClassificacaos,eventoClassificacaos.idEstabelecimento,eventoPartidas,eventoPartidas.casa,eventoPartidas.visitante,eventoClassificacaos.eventoPunicaoPontos,equipe",
    },
  });
  const data = res.data.items;
  return data;
}
