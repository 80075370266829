import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Grow, Typography } from '@mui/material'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import MetaContext from '../../context/MetaContext'
import { governancaApi } from '../../services/api'
import { convertSecondsDateTime } from '../../util/convertDate'
import { conselhoAtletas } from './data/conselhodeAtletas'
import { conselhoDeliberativo } from './data/deliberativo'
import { executiva } from './data/executiva'
import { conselhoFiscal } from './data/fiscal'
import ReactHtmlParser from 'react-html-parser';
// import { portalApi } from '../../services/api'



export default function GovernancaPage() {
    const { siteMeta } = useContext(MetaContext)

    const { idMenu } = useParams()
    const [menu, setMenu] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false)
    const [menuFilhos, setMenuFilhos] = useState([])

    useEffect(() => {
        setLoading(true);
        setError(false)
        async function getMenuGovernanca() {
            await governancaApi.get('menu-governanca', {
                params: {
                    id: idMenu,
                    id_estabelecimento: process.env.REACT_APP_ID_ESTABELECIMENTO,
                    flag_del: 0,
                    expand: 'filhos,orgao,filhos.orgao.orgaoAnexos'
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setError(true)
                }

                setMenu(response.data.items)
                if (response.data._meta.totalCount !== 0) {
                    if (response.data.items[0].filhos) {

                        let filteredOrgao = _.filter(response.data.items[0].filhos, function (o) {
                            return o.orgao
                        });
                        let filtered = _.filter(filteredOrgao, function (o) {
                            return o.orgao.orgaoAnexos
                        });

                        setMenuFilhos(_.orderBy(filtered, ['ordem'], ['asc']))
                    }
                }
                setLoading(false);
            });
        }
        getMenuGovernanca()
    }, [idMenu])

    const [expanded, setExpanded] = React.useState('panel0');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };


    const { tipoGov } = useParams()

    const [pageGov, setpageGov] = useState(executiva)

    useEffect(() => {
        switch (tipoGov) {
            case 'diretoria-executiva':
                setpageGov(executiva);
                break;
            case 'conselho-deliberativo':
                setpageGov(conselhoDeliberativo);
                break;
            case 'conselho-fiscal':
                setpageGov(conselhoFiscal);
                break;
            case 'conselho-de-atletas':
                setpageGov(conselhoAtletas);
                break;
            default:
                break;
        }
    }, [tipoGov])

    const breadcrumb = [
        { label: 'HOME', url: '/', active: false },
        { label: process.env.REACT_APP_ESTABELECIMENTO, url: '/', active: true },
    ]

    return (
        <>
            <Helmet>
                <title>{pageGov.descricao} - {siteMeta.siteTitle}</title>
            </Helmet>
            <PageHeader title={pageGov.descricao} breadcrumb={breadcrumb} />
            <div className="site-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="content col-lg-8">
                            <div className="card-deck d-block d-md-flex">
                                {pageGov.pessoas.map((item, key) => (
                                    <div className="card text-center mb-3 card-pessoa" key={key}>
                                        <Avatar src={item.foto} alt={item.nome} variant="circular" style={{ margin: 'auto', width: 100, height: 100, marginTop: '1rem' }} />
                                        <div className="card-body">
                                            <h5 className="mb-1">{item.nome}</h5>
                                            <h6 className='text-primary mb-0' style={{ fontSize: '.70rem', lineHeight: 1.3 }}>{item.cargo}</h6>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {loading || error ? ""
                    : <div className="container">
                        {menuFilhos ?
                            <div className="row justify-content-center">
                                <div className="content col-lg-8">
                                    {menu[0].orgao ?
                                        <div>
                                            {menu[0].orgao.corpo ?
                                                <div className="card card--lg post post--single">
                                                    <div className="card__content">
                                                        {ReactHtmlParser(menu[0].orgao.corpo)}
                                                    </div>
                                                </div>
                                                : ''
                                            }
                                        </div>
                                        :
                                        <div></div>
                                    }

                                    <div className="accordion accordion--space-between">
                                        {menuFilhos.map((item, key) => (
                                            <Accordion elevation={0} className="card" square expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                                                <div className="card__header">
                                                    <AccordionSummary aria-controls={`panel${key}d-content`} id={`panel${key}d-header`} className="p-0 m-0">
                                                        <Typography variant="h5" className="mb-0 w-100">
                                                            <button className={`btn btn-link accordion__header-link ${expanded === `panel${key}` ? '' : 'collapsed'}`}>
                                                                {/* {expanded === `panel${key}` ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>} */}
                                                                {item.orgao.titulo}
                                                                <span className="accordion__header-link-icon"></span>
                                                            </button>
                                                        </Typography>
                                                    </AccordionSummary>
                                                </div>
                                                <AccordionDetails>
                                                    <div className="table-responsive">
                                                        <table className="table lineup-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>ARQUIVO</th>
                                                                    <th>DATA</th>
                                                                    <th className="text-center">ANEXO</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {item.orgao.orgaoAnexos.length === 0 ?
                                                                    <tr>
                                                                        <td className="lineup__num" colspan={3}>
                                                                            <Alert severity="error">Nenhum anexo encontrado</Alert>
                                                                        </td>
                                                                    </tr>
                                                                    :
                                                                    <React.Fragment>
                                                                        {item.orgao.orgaoAnexos.map((item, key) => (
                                                                            <Grow in={true} key={key} timeout={600}>
                                                                                <tr>
                                                                                    <td component="th">
                                                                                        <span style={{ textTransform: 'uppercase' }}>{item.titulo}</span>
                                                                                    </td>
                                                                                    <td>{convertSecondsDateTime(item.created_at * 1000)}</td>
                                                                                    <td align="center">
                                                                                        <a className="btn btn-primary btn-xs" target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_SGE_URL}_uploads/orgaoAnexo/${item.nome_arquivo}`}>download</a>
                                                                                    </td>
                                                                                </tr>
                                                                            </Grow>
                                                                        ))}
                                                                    </React.Fragment>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion >
                                        ))}
                                    </div>
                                </div>
                            </div>
                            :
                            ''}
                    </div>
                }

            </div>
        </>
    )
}
