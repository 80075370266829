import { ConfedApi } from "../services/api";

export async function fetchEventoPartidaArbitro(props) {
  const { idPartida } = props;
  if (idPartida) {
    const res = await ConfedApi.get("evento-partida-arbitro", {
      params: {
        forcewhere: `id_partida:${idPartida}`,
        expand: "tb_pessoa,funcao",
      },
    });

    const data = res.data.items;
    return data;
  }
  return [];
}
