import React from 'react'
export const formatDatePeriod = (start, end, isNumeric) => {

    let startString = new Date(start).toLocaleString('pt-br', { month: '2-digit', day: '2-digit', year: 'numeric' })
    let endString = new Date(end).toLocaleString('pt-br', { month: '2-digit', day: '2-digit', year: 'numeric' })

    let startMonth = new Date(start).toLocaleString('pt-br', { month: '2-digit', year: 'numeric' })
    let endMonth = new Date(end).toLocaleString('pt-br', { month: '2-digit', year: 'numeric' })

    let startYear = new Date(start).toLocaleString('pt-br', { year: 'numeric' })
    let endYear = new Date(end).toLocaleString('pt-br', { year: 'numeric' })


    if (startString === endString) {
        return (
            <React.Fragment>
                {new Date(start).toLocaleString('pt-br', { day: '2-digit', month: isNumeric ? 'numeric' : 'long', year: 'numeric' })}
            </React.Fragment>
        )
    }

    if (startMonth === endMonth) {
        if (isNumeric) {
            return (
                <React.Fragment>
                    {new Date(start).toLocaleString('pt-br', { day: '2-digit', month: '2-digit', year: 'numeric' })}&nbsp;a&nbsp;
                    {new Date(end).toLocaleString('pt-br', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                {new Date(start).toLocaleString('pt-br', { day: '2-digit' })}&nbsp;a&nbsp;
                {new Date(end).toLocaleString('pt-br', { day: '2-digit' })}&nbsp;
                {new Date(start).toLocaleString('pt-br', { month: isNumeric ? 'numeric' : 'long', year: 'numeric' })}
            </React.Fragment>
        )
    }

    if (startYear === endYear) {
        if (isNumeric) {
            return (
                <React.Fragment>
                    {new Date(start).toLocaleString('pt-br', { day: '2-digit', month: '2-digit', year: 'numeric' })}&nbsp;a&nbsp;
                    {new Date(end).toLocaleString('pt-br', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                {new Date(start).toLocaleString('pt-br', { day: '2-digit', month: 'long' })}&nbsp;a&nbsp;
                {new Date(end).toLocaleString('pt-br', { day: '2-digit', month: 'long', year: 'numeric' })}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {new Date(start).toLocaleString('pt-br', { day: '2-digit', month: isNumeric ? 'numeric' : 'long', year: 'numeric' })}
            &nbsp;a&nbsp;
            {new Date(end).toLocaleString('pt-br', { day: '2-digit', month: isNumeric ? 'numeric' : 'long', year: 'numeric' })}
        </React.Fragment>
    )
}